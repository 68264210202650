import { useNavigate } from "react-router-dom";
import { handleItemClick } from "../ListLogic";
import classes from "./Grid.module.css";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import ViewImageField from "../../fields/viewFields/ImageField";

export default function GridItem(props: any) {
  // console.log('GridItem -props:', props)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const appMode = useAppSelector((state) => state.ui.appMode)
  const feature_definition = props.feature_definition;
  const grid_view = feature_definition.grid_view;
  const data_item = props.data_item;
  const tempheading = grid_view.heading.split(".");
  const itemcount = tempheading.length;

  const handleValue = (data_item: any, field_name: string) => {
    // console.log(field_name, data_item, grid_view)

    const split_field_name = field_name.split(".");
    const depth_of_chain = split_field_name.length;

    if (depth_of_chain === 1) return data_item[field_name];
    if (depth_of_chain === 2)
      return data_item[split_field_name[0]][split_field_name[1]];
  };
  // console.log(grid_view.layout)
  return (<>
    <div
      className={classes.listGridItemContainer}
      style={
        grid_view.image1 !== ""
          ? { cursor: "pointer", display: "grid" }
          : { cursor: "pointer" }
      }
      onClick={() => {
        handleItemClick(
          dispatch,
          navigate,
          props.module_definition,
          props.feature_definition,
          data_item.id,
          appMode
        );
      }}
    >
      {grid_view.image1 !== "" && (
        <div className={classes.listGridItemImage}>
          {ViewImageField(props, grid_view.image1)}
        </div>
      )}
      <div className={classes.listGridItemText}>
        <div className={classes.listGridItemHeading}>
          {handleValue(data_item, grid_view.heading)}
          {/* {data_item[grid_view.heading]} */}
        </div>
        {grid_view.line1 !== "" && (
          <div className={classes.listGridItemContents}>
            <div>{data_item[grid_view.line1]}</div>
            <div>{data_item[grid_view.line2]}</div>
          </div>
        )}
      </div>

    </div>
    {/* <div
      dangerouslySetInnerHTML={{ __html: grid_view.layout }}
    /> */}

  </>);
}
