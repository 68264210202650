const handleNumberFilter = (filterObject: any, dbValue: any) => {

  // console.log('handleNumberFilter - filterObject', filterObject, dbValue)
  // console.log('handleNumberFilter - filterObject.filterItems', filterObject.filterItems[0].logic, dbValue)
  if (filterObject.filterItems) {
    if (filterObject.filterItems[0].logic === 'includes' ||
      filterObject.filterItems[0].logic === undefined) {
      return dbValue.includes(filterObject.filterItems[0].value);
    }
    if (filterObject.filterItems[0].logic === 'eqaulTo') {
      return dbValue == filterObject.filterItems[0].value;
    }
    if (filterObject.filterItems[0].logic === 'lessThan') {
      return dbValue < filterObject.filterItems[0].value
    }
    if (filterObject.filterItems[0].logic === 'equalToLessThan') {
      return dbValue <= filterObject.filterItems[0].value
    }
    if (filterObject.filterItems[0].logic === 'notEqualTo') {
      return dbValue != filterObject.filterItems[0].value
    }
    if (filterObject.filterItems[0].logic === 'greaterThan') {
      if (filterObject.filterItems[1]) {
        if (filterObject.filterItems[1].logic === 'lessThan') {
          return dbValue > filterObject.filterItems[0].value && dbValue < filterObject.filterItems[1].value
        } else if (filterObject.filterItems[1].logic === 'equalToLessThan') {
          return dbValue > filterObject.filterItems[0].value && dbValue <= filterObject.filterItems[1].value
        } else {
          return dbValue > filterObject.filterItems[0].value
        }
      } else {
        return dbValue > filterObject.filterItems[0].value
      }
    }
    if (filterObject.filterItems[0].logic === 'equalToGreaterThan') {
      if (filterObject.filterItems[1]) {
        if (filterObject.filterItems[1].logic === 'equalToLessThan') {
          return dbValue >= filterObject.filterItems[0].value && dbValue <= filterObject.filterItems[1].value
        } else if (filterObject.filterItems[1].logic === 'lessThan') {
          return dbValue >= filterObject.filterItems[0].value && dbValue < filterObject.filterItems[1].value
        } else {
          return dbValue >= filterObject.filterItems[0].value
        }
      } else {
        return dbValue >= filterObject.filterItems[0].value
      }
    }
  }
}
export default handleNumberFilter