import { EditableOptionAddButton, errorMessage, label, getFieldAttributes, handleOnChange } from "../InputFieldLogic"
import BasicGetAPI from '../../../../api/BasicGetAPI'
import { useEffect, useState } from "react"
import { MdAdd } from "react-icons/md"
import useModal from "../../../../hooks/useModal"
import { checkforqueryparms, selectField, get_conditional_options } from './SelectLogic'
import { MdOutlineModeEditOutline, MdCheckCircle, MdCheck, MdDeleteForever, MdVisibility, MdCancel } from 'react-icons/md';
import { getRoles } from "@testing-library/react"

//  Multi select is used by PL Passage Crew
export default function MultiSelectField(props: any) {
  const field_config = props.field_config
  const field_name = field_config.field.field_name
  const field_values = props.field_values
  const static_options = field_config.options_source.split(",")
  const setFieldValues = props.setFieldValues
  const [dynamic_list, setDynamic_list] = useState([])
  const feature_view_mode = props.feature_state.item.view_action
  const [fixed_single_key_value_pair, setfixed_single_key_value_pair] = useState()

  useEffect(() => {
    if (field_config.options_type === 'dynamic_list') {
      BasicGetAPI(field_config.options_api_endpoint + checkforqueryparms(props, field_name))
        .then((response: any) => {
          if (response.APIResult === 'success') {
            // console.log('SelectField', response.APIData)
            setDynamic_list(response.APIData)
          }
        })
    }
  }, [])

  const onChange = (field_value: any) => {
    const touched = getFieldAttributes(field_name, field_values, 'touched')
    // if (props.field.fieldType === 'multiSelect') {

    // } else {
    // setFieldValues(handleOnChange(field_name, field_values, field_value, field_type, field_config, field_translation, touched))
    setFieldValues(handleOnChange({
      field_config,
      field_values,
      field_value,
      touched,
      action: 'append'
    }))
    // }
  }

  const deleteItem = (item: any) => {
    console.log('deleteItem', item)
    setFieldValues(handleOnChange({
      field_config,
      field_values,
      field_value: item,
      touched: true,
      action: 'delete'
    }))
  }

  const conditional_options = get_conditional_options(field_config, field_values, dynamic_list)

  const field_value = getFieldAttributes(field_name, field_values, 'field_value')

  const getSelectFieldLogic = selectField(
    field_name,
    field_config,
    field_values,
    onChange,
    field_value ? dynamic_list.filter((e: any) => !field_value.includes(e.id)) : dynamic_list,
    static_options,
    conditional_options,
    fixed_single_key_value_pair,
    feature_view_mode
  )

  // console.log('field_values', field_values, field_name, field_values.findIndex((e: any) => e.field_name === field_name))

  // console.log('field_value', field_value)

  // console.log('dynamic_list', field_value, dynamic_list,
  //   field_value && dynamic_list.filter((e: any) => !field_value.includes(e.id))
  // )

  interface listitem {
    id?: number;
    name?: string | null
  }

  const getItemName = (e1: number) => {
    const getObject = dynamic_list.find((e2: listitem) => e2.id === e1)
    if (getObject !== null && getObject !== undefined) {
      // console.log(getObject['name'])
      return getObject['name']
    }

  }

  return (<>
    {props.hide_field === false && (
      field_config.item_add_conditional_render_logic === 'linkedField' ? (
        conditional_options.length > 0 &&
        getSelectFieldLogic

      )
        : getSelectFieldLogic
    )}
    <p>

      {Array.isArray(field_value) ? field_value.map((item: any) => {
        console.log('item', item, item['role.name'])
        const result = getItemName(item)

        // original logic when an object with multiple items was being returned. 
        // const itemFromDB = typeof item === 'object' ? true : false
        // const result = itemFromDB ? item['role.name'] : getItemName(item)
        // console.log('result', result)
        return (
          <li key={result === undefined ? Math.random() : result}>{result}
            <span
              onClick={() => deleteItem(item)}
              title='Delete'>
              <i
                style={{ color: '#d9534f' }}
              ><MdDeleteForever /></i>
            </span>
          </li>
        )
      }

      ) : field_value}

    </p>

  </>)
}
