// View fields is used when displaying a form in view / read only mode. Input fields is used when in add/edit mode.
// Field values closely follows this but is used by lists
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/useRedux'
import { getFieldAttributes } from '../inputFields/InputFieldLogic'
import { checkboxValue, dateValue, keyValuePairValue, timeFieldValue } from './FieldValues'

export const ViewField = (props: any) => {
  // console.log('ViewField - field:', props.field_config.field.field_name, props)
  const userSettings = useAppSelector((state) => state.userSettings)
  // const media_server = process.env.REACT_APP_MEDIA_SERVER
  // const object_store = props.feature_definition.object_store
  const field_config = props.field_config
  const field_name = field_config.field.field_name
  const field_type = field_config.field.field_type.html_field_type
  const field_values = props.feature_state.item.data
  const field_translation = field_config.field.translation.en

  const l_d_c = field_config.item_layout_desktop_column
  const l_d_p = field_config.item_layout_desktop_position
  const l_m_c = field_config.item_layout_mobile_column
  const l_m_p = field_config.item_layout_mobile_position
  const appMode = useAppSelector((state) => state.ui.appMode)
  // console.log('ViewField', field.fieldName, props)

  const label = () => {
    // console.log('label:', field)
    return (
      <label>
        {field_translation}
      </label>
    )
  }
  // console.log('field vlaue:', props.feature_state)
  const checkboxField = (
    <div className='viewField' >
      <input
        // className='viewField'
        readOnly
        value={checkboxValue(field_values[field_name])}
      // value={checkboxValue(getFieldAttributes(field.fieldName, field_values, 'value'))}
      />
      {label()}
    </div>
  )

  const dateField = () => {
    // console.log('field_name', field_name, 'field_values', field_values)
    const value = field_values[field_name]

    return <div className='viewField' >
      <input
        // className='viewField'
        readOnly
        value={dateValue(value, userSettings)}
      />
      {label()}
    </div>
  }

  const default_image = field_config.default_image_name + '.' + field_config.default_image_type

  const imgSrc = () => {
    // console.log('imgSrc field_values[field_name]', field_values, field_name)
    try {
      const src = field_values[field_name]
      if (src) {
        return field_values.src
      } else {
        return '/static/images/default/' + default_image
      }
    }
    catch {
      return '/static/images/default/' + default_image
    }
  }

  const imgTitle = () => {
    try {
      const src = getFieldAttributes(field_name, field_values, 'field_value', 'title')
      return src
    }
    catch {
      return ''
    }
  }

  // This image field is used in item views
  const ImageField = (
    <div className='ImageFieldContainer' style={{ textAlign: 'center' }} >
      <img
        src={imgSrc()}
        title={imgTitle()}
        height='100px'
        alt="Image"
        className='ImageFieldImage'
        style={{ cursor: 'default' }}
      />
    </div>
  )

  const keyValuePairField = (field_config: any, field_values: any) => {
    // console.log('keyValuePairField', field_name, field_values)
    const key_value_pair = field_config.key_value_pair
    return (
      <div className='viewField' >
        {field_config.options_type === 'static_list' ?
          <input
            readOnly
            value={field_values[field_name]}
          // value={getFieldAttributes(field_name, field_values, 'field_value')}
          />
          :
          <input
            readOnly
            value={field_values[key_value_pair] && field_values[key_value_pair].name}
          // value={getFieldAttributes(field_name, field_values, 'field_value')}
          />
        }

        {label()}
      </div>

    )
  }

  const getFieldValue = (state: any, field_name: string) => {
    const data = props.feature_state.item.data
    // console.log(field_name, 'props.feature_state:', data,)
    // const field_value = data && data.find((e: any) => e[field_name] === field_name)
    // console.log(data[field_name])
    // console.log('field_value:', field_value)
    // if (field_value === undefined) {
    //   // console.log('getFieldAttributes - field - undefined')
    //   return ''
    // } else {
    return data[field_name]
    // }

  }

  const textField = (
    <div className='viewField' >
      <input
        // className='viewField'
        readOnly
        value={field_values[field_name]}
      />
      {label()}
    </div>
  )

  const textareaField = (
    <div className='viewField' >
      <textarea
        // className='viewField'
        style={{ resize: 'none' }}
        rows={3}
        readOnly
        value={field_values[field_name]}
      />
      {label()}
    </div>
  )

  const timeField = () => {
    // const value = getFieldAttributes(field.fieldName, field_values, 'value')
    // console.log('value', value)

    // const formattedValue = () => {
    //   if (userSettings.data.localisation_time_format === 'AM/PM') {
    //     const format = (H: number, M: number) => {
    //       return `${(H % 12 < 10 ? '0' : '') + H % 12}:${(M < 10 ? '0' : '') + M} ${H < 12 ? 'AM' : 'PM'}`;
    //     }
    //     return format(value.slice(0, 2), value.slice(3, 4))
    //   } else {
    //     return value
    //   }
    // }

    return <div className='viewField' >
      <input
        // className='viewField'
        readOnly
      // value={timeFieldValue(value, userSettings)}
      />
      {label()}
    </div>
  }


  const urlField = (
    <div className='viewField'  >
      <Link to={field_values[field_name]}>
        <input
          className='clickableText'
          readOnly
          value={field_values[field_name]}
        // value={getFieldValue(props.feature_state, field.field_name)}
        // value={getFieldAttributes(field.fieldName, field_values, 'value')}
        />
      </Link>
      {label()}
    </div>
  )

  const fieldSelector = (field_config: any) => {
    // console.log('viewField:', field_config, field_name, field_type)
    switch (field_type) {
      case 'autocomplete':
        return keyValuePairField(field_config, field_values)

      case 'boolean':
        return checkboxField

      case 'checkbox':
        return checkboxField

      case 'date':
        return dateField()

      case 'image':
        return ImageField

      case 'select':
        return keyValuePairField(field_config, field_values)

      case 'textarea':
        return textareaField

      case 'time':
        return timeField()

      case 'url':
        return urlField

      default:
        return textField
    }
  }

  const mobileStyle = {
    gridColumn:
      l_m_c === 'full' ? "1/5" :
        l_m_c === 'half' ?
          (l_m_p === 'left' ? "1/3" :
            l_m_p === 'right' ? "3/5" : "1/5") :
          l_m_c === 'quarter' ?
            (l_m_p === '1' ? "1/2" :
              l_m_p === '2' ? "2/3" :
                l_m_p === '3' ? "3/4" :
                  l_m_p === '4' ? "4/5" : "1/5") :
            "1/5",
    width: l_m_c === 'quarter' ? '110px' : '100%'
  };

  const desktopStyle = {
    gridColumn:
      l_d_c === 'full' ? "1/5" :
        l_d_c === 'half' ?
          (l_d_p === 'left' ? "1/3" :
            l_d_p === 'right' ? "3/5" : "1/5") :
          l_d_c === 'quarter' ?
            (l_d_p === '1' ? "1/2" :
              l_d_p === '2' ? "2/3" :
                l_d_p === '3' ? "3/4" :
                  l_d_p === '4' ? "4/5" : "1/5") :
            "1/5",
    width: l_d_c === 'quarter' ? '110px' : '100%'
  };

  // console.log('Hide field if values are blank', props.feature_state.definition.data.item_config.view_hide_blank_field, field_name, field_values[field_name], field_values)

  return (
    <div
      // className='fieldRowGroup'
      // style={{ gridTemplateColumns: 'auto auto auto' }}
      style={appMode === "mobile" ? mobileStyle : desktopStyle}
    >
      {/* Hide field if values are blank  */}
      {props.feature_state.definition.data.item_config.view_hide_blank_field ?
        ((field_values[field_name] !== '' && field_values[field_name] !== null && field_values[field_name] !== undefined &&
          field_values[field_name] !== 0)) && fieldSelector(field_config)
        : fieldSelector(field_config)
      }
    </div>
  )

}

export default ViewField