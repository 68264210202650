
import { MdOutlineModeEditOutline, MdCheckCircle, MdCheck, MdDeleteForever, MdVisibility, MdCancel } from 'react-icons/md';
import { handleDeleteItem, successDeleteItem } from '../../item/ItemLogic'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import { features_actions } from '../../../state/slices/FeatureSlice';
import { GeneralAPIs } from '../../../api/GeneralAPIs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TableRowOption = (props: any) => {
  return (
    <span
      onClick={props.onClick}
      title={props.title}>
      <i
        style={{ color: props.color }}
      >{props.icon}</i>
    </span>
  );
};

const TableRowOptions = (props: any) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const appMode = useAppSelector((state) => state.ui.appMode)
  // console.log('TableRowOptions -props:', props)
  const actions = props.actions
  const item_state = props.feature_state.item
  const [rowOptionAction, setRowOptionAction] = useState('')

  useEffect(() => {
    if (item_state.api_status === 'success' && rowOptionAction === 'delete') {
      successDeleteItem(dispatch, actions, props.setFeatureMessages, props.feature_state)
      setRowOptionAction('')
    }
  },
    [item_state.api_status, item_state.action])

  const handleClick =({view_action}:any) =>{
    if (appMode === 'mobile') {
      navigate('/'+ props.module_definition.frontend_link + '/' + props.feature_state.link + '/item:' +view_action+ '?id=' + props.data_item.id )
    }  else {
    dispatch(GeneralAPIs({
      method: 'get',
      end_point: props.feature_definition.api_route + '/' + props.data_item.id,
      actions: features_actions,
      feature_id: props.feature_state.feature_id,
      type: 'item'
    }))
    dispatch(features_actions.openItemModal({
      feature_id: props.feature_state.feature_id,
      item_id: props.data_item.id,
      view_action: view_action
    }))
  }
  }

  return (
    <td style={{ textAlign: 'center' }}>
      {props.rowOptions
        // .filter((item: any) => item.tableDefaults.showcolumn === true)
        // .sort((a: any, b: any) => a.tableDisplayOrder - b.tableDisplayOrder)
        .map((item: any) => (
          item === 'view' ?
            <TableRowOption
              key={item}
              title='View'
              color='#4169e1'
              icon={<MdVisibility />}
              onClick={() => { handleClick({view_action: 'view'})
              }}
            />
            :
            item === 'edit' ?
              <TableRowOption
                key={item}
                title='Edit'
                color='#4169e1'
                icon={<MdOutlineModeEditOutline />}
                onClick={() => {handleClick({view_action: 'edit'})
                  // dispatch(GeneralAPIs({
                  //   method: 'get',
                  //   end_point: props.feature_definition.api_route + '/' + props.data_item.id,
                  //   actions: features_actions,
                  //   feature_id: props.feature_state.feature_id,
                  //   type: 'item'
                  // }))
                  // dispatch(features_actions.openItemModal({
                  //   feature_id: props.feature_state.feature_id,
                  //   item_id: props.data_item.id,
                  //   view_action: 'edit'
                  // }))
                }}
              />
              :
              item === 'delete' &&
              <TableRowOption
                key={item}
                title='Delete'
                color='#d9534f'
                icon={<MdDeleteForever />}
                onClick={() => {
                  handleDeleteItem(dispatch, actions, props.feature_definition, props.data_item.id)
                  setRowOptionAction('delete')
                }}
              />
        ))}
    </td>
  )
}

export default TableRowOptions