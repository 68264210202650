import { GeneralAPIs } from '../../api/GeneralAPIs'

export const handleDeleteItem = (dispatch: any, actions: any, feature_definition: any, item_id: any) => {
  dispatch(GeneralAPIs({
    method: 'delete',
    end_point: feature_definition.api_route_params ? feature_definition.api_route : feature_definition.api_route,
    actions: actions,
    feature_id: feature_definition.id,
    type: 'item',
    item_id: item_id,
  }))
}

export const successDeleteItem = (dispatch: any, actions: any, setFeatureMessages: any, feature_state: any) => {

  setFeatureMessages([{ message: feature_state.definition.data.singular_translation.en + ' deleted', message_type: 'success' }])
  const index = feature_state.list.data.find((item: any) => item.id === feature_state.item.item_id)
  const sortedData = [...feature_state.list.data]
  sortedData.splice(index, 1) // delete old value
  const feature_id = feature_state.feature_id
  dispatch(actions.setListData({ sortedData, feature_id }))
}