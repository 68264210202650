import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { GeneralAPIs } from "../../api/GeneralAPIs";
import SpinnerMini from "../loaders/spinner/SpinnerMini";
import Spinner from "../loaders/spinner/SpinnerFullScreen";
import { displayMessage } from "../messages/UserMessages";
import ItemTabs from "./tabs/ItemTabs";
import { handleDeleteItem, successDeleteItem } from "./ItemLogic";
import FormViewSelector from "../forms/FormViewSelector";
import { setAddFormInitialFieldValues, setEditFormInitialFieldValues, validateAllFields, isFormValid, flatternFieldValuesToJSON, appendToFormData, getFieldAttributes, } from "../fields/inputFields/InputFieldLogic";
import { addItemAndSortValues, replaceItemAndSortValues, } from "../../hooks/handleSortData";
import { features_actions } from "../../state/slices/FeatureSlice";

export type MessageType = {
  message?: string;
  message_type?: string;
};

export type FormFieldInputTypes = {
  field_name: string;
  field_type: string;
  field_value: any;
  touched: boolean;
  has_error: boolean;
  error_messages: [];
};

export default function ItemView(props: any) {
  // console.log('Element 2. ItemView - props:', props)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const item_state = useAppSelector((state) => state.features[props.feature_id].item)

  const actions = props.actions;
  const feature_state = props.feature_state;
  const feature_definition = feature_state.definition.data;
  const item_state = feature_state.item;
  // console.log('Element 2. ItemView - feature_state:', feature_state)
  // console.log('Element 2. ItemView - item_state:', item_state)
  const item_config = feature_definition.item_config;
  const field_config = feature_definition.fields_config;
  const button_action = item_state.form_state.button_action;
  const buttons = item_config.item_buttons;

  const [itemMessages, setItemMessages] = useState<MessageType[]>([]);
  const [formMessages, setFormMessages] = useState<MessageType[]>([]);
  const [saveAttempted, setSaveAttempted] = useState(false); // For form error messages
  const [formSubmitted, setFormSubmitted] = useState(false); //For when data has been sent to DB

  // Field values is used for all fields other than Objects (documents and image)
  const [field_values, setFieldValues] = useState<FormFieldInputTypes[]>([]);
  // console.log('5. ItemView - field_values:', field_values)
  // File values are used for Objects (documents and image)
  // const [fileValues, setFileValues] = useState([]);

  // Handling Files/ Images
  // Not able to save binary to Json so must be kept in temp state. UseRef survives rerender cycles.
  const fileUploadRef = useRef<any>();
  // const file_state = feature_state.file
  const [file, setFile] = useState<any>(); //store an image or document that has been uploaded
  const [previewImageURL, setPreviewImageURL] = useState<any>(); // used to display image as soon as it's uploaded
  // const [fileUploaded, setFileUploaded] = useState(false)

  const newAddForm = (clearInputValue?: string) => {

    setFieldValues(setAddFormInitialFieldValues(
      field_config,
      feature_definition.primary_field,
      props.query_parms,
      clearInputValue ? '' : props.input_value)
    );
  }

  // Step 1. Set field values
  useEffect(() => {
    // On initial load, reset the screen
    // dispatch(actions.clearMessages({
    //   feature_id: feature_state.feature_id,
    //   type: 'item'
    // }))
    // console.log('ItemView screen triggered', item_state.view_action)
    if (item_state.view_action === "add") {
      // console.log('props.input_value', props.input_value)
      newAddForm()
    }
    if (item_state.view_action === "edit") {
      // console.log('ItemView - edit mode', item_state)
      setFieldValues(setEditFormInitialFieldValues(field_config, item_state));
    }
  }, [item_state.view_action]);

  // Immediately submit form, currently only used by EmailVerification
  useEffect(() => {
    if (props.instantly_submit_data) {
      handleSave();
    }
  }, [props.instantly_submit_data]);

  // Display any messages from previous modal screen, used by button action 'save_new'
  useEffect(() => {
    setItemMessages([
      {
        message: item_state.form_state.message,
        message_type: item_state.form_state.message_type,
      },
    ]);
  }, [item_state.form_state.message]);

  // Step 2. Handle Submit
  const submitHandler = (button_action: string) => {
    setItemMessages([]);
    // console.log('submitHandler', button_action)
    if (button_action === "delete") {
      handleDeleteItem(dispatch, actions, feature_definition, item_state.item_id);
      dispatch(features_actions.saveFormState({
        button_action: button_action,
        // field_values: field_values,
        feature_id: props.feature_id,
      })
      );
    } else {
      //save_return , save_next, save_new, save_close
      handleSave(button_action);
    }
  };

  const handleSave = (button_action?: string) => {
    setSaveAttempted(true);
    // console.log('field_config:', field_config, 'field_values:', field_values)
    //
    // console.log('handleSave', button_action)
    // Step 2.1A. Are the indiviudal fields valid?
    const result = validateAllFields(field_config, field_values);
    // console.log('field_values - before', field_values)
    // console.log('result', result)
    setFieldValues(result);
    // console.log('field_values - after ', field_values)
    // console.log('isFormValid', isFormValid(field_config, field_values))
    if (isFormValid(field_config, field_values)) {
      setFormMessages([]);
      dispatch(features_actions.saveFormState({
        button_action: button_action,
        // field_values: field_values,
        feature_id: props.feature_id,
      })
      );

      // Step 2.2A. Are there any custom form validation rules
      if (props.preSubmitActions) {
        // console.log('handleSave', props.preSubmitActions)
        // setAfterSubmitReturnToForm(true)

        // Step 2.3A. Any other checks required before submitting the data? (Use by password reset and update to check password is correct.)

        if (!props.preSubmitActions(field_values).result) {
          // Step 2.4A. If pre submit action fail
          setFormMessages([{
            message: props.preSubmitActions(field_values).message,
            message_type: "error",
          },
          ]);
        } else {
          // Step 2.4B. Prep data to be sent to the database
          sendDataToDB();
        }
      } else {
        // Step 2.2B. No custom form validation rules (skip 2.3)

        if (
          props.preSubmitActions &&
          !props.preSubmitActions(field_values).result
        ) {
          // Step 2.4A. If pre submit action fail
          setFormMessages([
            {
              message: props.preSubmitActions(field_values).message,
              message_type: "error",
            },
          ]);
        } else {
          // Step 2.4B. Prep data to be sent to the database
          sendDataToDB();
        }
      }
    } else {
      // Step 2.1B. If field not valid
      setFormMessages([
        {
          message: "Please check form for errors",
          message_type: "error",
        },
      ]);
      // console.log('error fields not valid')
    }
  };

  // After a save attempt, if form is not valid, revalid after field updates.
  useEffect(() => {
    if (saveAttempted) {
      // console.log("checking....");
      if (isFormValid(field_config, field_values)) {
        // console.log("passed....");
        if (props.preSubmitActions) {
          if (props.preSubmitActions(field_values).result) {
            setFormMessages([]);
          }
        }
        else {
          setFormMessages([]);
        }
      }
    }
  }, [field_values]);

  // Step 3. Send data to DB
  // console.log('feature_definition.api_route_params', feature_definition.api_route_params)
  const sendDataToDB = () => {
    if (file) {
      //If file then need to use formData to send binary file data
      console.log('send as form data')
      //browser API for handling binary (image) data
      const formData = appendToFormData(field_values);
      formData.append("image", file);
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      setFile(null);
      setPreviewImageURL(null);
      if (
        item_state.view_action === "edit" ||
        // This logic is used for updating data that is not returned to the user for example reset password.
        (item_state.view_action === "add" &&
          feature_definition.api_route_method === "patch")
      ) {
        dispatch(
          GeneralAPIs({
            method: "patch",
            end_point: feature_definition.api_route_params
              ? feature_definition.api_route + "/" + item_state.item_id
              : feature_definition.api_route,
            actions: actions,
            data_to_send: formData,
            feature_id: feature_definition.id,
            type: "item",
            header: { "content-type": "multipart/form-data" },
          })
        );
      } else {
        dispatch(
          GeneralAPIs({
            method: "post",
            end_point: feature_definition.api_route + "/add",
            actions: actions,
            data_to_send: formData,
            feature_id: feature_definition.id,
            type: "item",
            header: { "content-type": "multipart/form-data" },
          })
        );
      }
    } else {
      //if not use normal json
      // console.log('send as json data')
      const json_data = props.data_to_send
        ? { ...flatternFieldValuesToJSON(field_values), ...props.data_to_send }
        : flatternFieldValuesToJSON(field_values);
      // console.log('sendDataToDB- data', feature_definition)

      setFormSubmitted(true);
      // console.log("feature_definition.api_route_method",feature_definition.api_route_method);
      if (
        item_state.view_action === "edit" ||
        // This logic is used for updating data that is not returned to the user for example reset password.
        (item_state.view_action === "add" &&
          feature_definition.api_route_method === "patch")
      ) {
        dispatch(
          GeneralAPIs({
            method: "patch",
            end_point: feature_definition.api_route_params
              ? feature_definition.api_route + "/" + item_state.item_id
              : feature_definition.api_route,
            actions: actions,
            data_to_send: json_data,
            feature_id: feature_definition.id,
            type: "item",
          })
        );
      } else if (item_state.view_action === "add") {
        // api_route_params are used by features that allow view,add,edit,delete. User Auth modules do not use these.
        dispatch(
          GeneralAPIs({
            method: "post",
            end_point: feature_definition.api_route_params
              ? feature_definition.api_route + "/add"
              : feature_definition.api_route,
            actions: actions,
            data_to_send: json_data,
            feature_id: feature_definition.id,
            type: "item",
          })
        );
      } else {
        setFormMessages([
          {
            message: "In valid option has been selected, please contact customer support",
            message_type: "error",
          },
        ]);
      }
    }
  };

  // message: itemState.data.name === undefined ? feature_definition.name_translation.en + ' updated' : itemState.data.name + ' updated',
  // console.log('item_state',item_state)
  // console.log('feature_definition',feature_definition)

  // Step 4 Handle results from the database
  useEffect(() => {
    // console.log('Step 4 Handle results from the database - item_state', item_state)
    const messagePrefix = feature_definition.primary_field
      ? item_state.data[feature_definition.primary_field] !== undefined &&
      item_state.data[feature_definition.primary_field]
      : feature_definition.name_translation.en;

    if (item_state.api_status === "error") {
      // console.log('item_state.api_status === error')
      // If form gets re render during save then redisplay form values
      if (
        button_action !== "delete"
        // && item_state.form_state.field_values
      ) {
        // console.log('item_state.form_state.field_values', item_state.form_state.field_values)
        // setFieldValues([...item_state.form_state.field_values]) //Use a copy of the saved array.
      }
    }
    if (item_state.api_status === "success") {
      // console.log('item_state.api_status === success', item_state)
      // setFieldValues([...item_state.form_state.field_values])
      // setFieldValues(setEditFormInitialFieldValues(field_config, item_state))
      // if (file) (handleUploadedFile(item_state.data.id))

      // console.log('itemview step 4 - previewImageURL', previewImageURL)

      if (item_state.view_action === "add") {
        // console.log('postSubmitActions- item_state', item_state)

        // Run any postSubmitActions (Used by User Auth Views)
        props.postSubmitActions &&
          props.postSubmitActions({
            item_state: item_state,
            field_values: field_values,
          });

        // if (props.successMessage && props.successMessage === 'useAPIMessages') {
        // } else {
        //   console.log({ message: messagePrefix + ' added', message_type: 'success' })
        //   setItemMessages([{ message: messagePrefix + ' added', message_type: 'success' }])
        // }

        if (button_action === "save_new") {
          // console.log('save_new')
          // if (item_state.show_modal === false) {
          // setItemMessages([{ message: messagePrefix + ' added', message_type: 'success' }])
          // } else {

          // }
          // Push new item to list, sort not required yet.
          dispatch(
            actions.addItemToList({
              feature_id: feature_state.feature_id,
              item: item_state.data,
            })
          );
          // setItemMessages([{ message: item_state.form_state.message, message_type: item_state.form_state.message.message_type }])
          dispatch(
            actions.resetStateAddNewItem({
              feature_id: feature_state.feature_id,
              show_modal: item_state.show_modal,
              message: messagePrefix + " added",
              message_type: "success",
            })
          );
          newAddForm('clearInputValue')
          setItemMessages([
            { message: messagePrefix + " added", message_type: "success" },
          ]);
        }
      }

      if (item_state.view_action === "edit" && formSubmitted === true) {
        // console.log('postSubmitActions- item_state', item_state)
        props.postSubmitActions &&
          props.postSubmitActions({
            item_state: item_state,
            field_values: field_values,
          });
        // if (props.successMessage && props.successMessage === 'useAPIMessages') {
        // } else {
        //   setItemMessages([{ message: messagePrefix + ' updated', message_type: 'success' }])
        // }

        // // Push new data to list.
        // dispatch(actions.replaceItemInList({
        //   feature_id: feature_state.feature_id,
        //   item: item_state.data
        // }))
      }

      if (button_action === "save_close") {
        // console.log('save_close/exit')
        if (item_state.view_action === "edit") {
          props.setFeatureMessages([
            { message: messagePrefix + " updated", message_type: "success" },
          ]);
          replaceItemAndSortValues(
            dispatch,
            actions,
            item_state.data,
            feature_state.list.data,
            feature_state.list.sort_choices,
            feature_state.feature_id
          );
        } else if (item_state.view_action === "add") {
          props.setFeatureMessages([
            { message: messagePrefix + " added", message_type: "success" },
          ]);
          addItemAndSortValues(
            dispatch,
            actions,
            item_state.data,
            feature_state.list.data,
            feature_state.list.sort_choices,
            feature_state.feature_id
          );
        }
      }

      if (button_action === "delete") {
        successDeleteItem(
          dispatch,
          actions,
          props.setFeatureMessages,
          feature_state
        );
        // props.setFeatureMessages([{ message: feature_definition.singular_translation.en + ' deleted', message_type: 'success' }])
        // const index = feature_state.list.data.find((item: any) => item.id === item_state.item_id)
        // const sortedData = [...feature_state.list.data]
        // sortedData.splice(index, 1) // delete old value
        // const feature_id = feature_state.feature_id
        // dispatch(actions.setListData({ sortedData, feature_id }))
      }

      if (button_action === "save_return") {
        // Return to form with exisiting data
      }

      if (button_action === "save_next") {
        // console.log('save_next')
        const button_def = buttons.find((e: any) => e.button_action === 'save_next')
        // console.log('button_def', button_def)
        // do the next action.
        dispatch(actions.resetItem(feature_state.feature_id))
        // console.log('nav', ("/" + button_def.navigate_to + '?' + (button_def.query_parms && button_def.query_parms + '=' + item_state.data.id)))
        if (button_def.next_action === 'navigate_back') {
          navigate(parseInt(button_def.navigate_to))
          // parseInt(button_def.navigate_to)
        }

        if (button_def.next_action === 'navigate') {
          navigate("/" + button_def.navigate_to + '?' + (button_def.query_parms && button_def.query_parms + '=' + item_state.data.id));
        }

      }

      // Final action - close item.
      if (button_action === "save_close" || button_action === "delete") {
        dispatch(actions.resetItem(feature_state.feature_id))
        if (item_state.show_modal === true) {
          dispatch(actions.closeItemModal(feature_state.feature_id));
        } else if (props.hideModalModal) {
          props.setUpdateRequired(true)
          props.NewValueAdded(getFieldAttributes(feature_definition.primary_field, field_values, 'field_value'), item_state.data.id)
          // console.log('getFieldAttributes(feature_definition.primary_field, field_values', getFieldAttributes(feature_definition.primary_field, field_values, 'field_value'))
          // get the field value of the feature_definition.primary_field
          // console.log('Close module modal')
          props.hideModalModal()

        } else {
          navigate("/" + feature_state.link);
        }
      }
    }

    // Used by User Profile
    if (item_state.api_status === 'success' && formSubmitted === true) {
      props.postSubmitActions && props.postSubmitActions({ item_state: item_state, field_values: field_values })
      setFormSubmitted(false)
    }
  }, [item_state.api_status, item_state.view_action]);

  // Step 5 If an object (document or image) has been uploaded, processed after json data has returned an ID.
  // const handleUploadedFile = (id: number) => {
  // console.log('handleUploadedFile - id:', id)
  // const checkForObjects = field_values.find((item: any) => item.field_type === 'image' || item.field_type === 'document')
  // if (checkForObjects) { // Does this module have any image of file fields?
  //   console.log('Module has file fields', checkForObjects)
  //   if (file) { // Has a file being uploaded?
  //     const formData = new FormData(); //browser API for handling binary (image) data
  //     // file.location = feature_definition.object_store
  //     formData.append('image', file);
  //     formData.append('id', String(id));
  //     formData.append('id', String(id));
  //     // formData.append('location', 'feature_definition.object_store');
  //     console.log('formData', formData)
  //     dispatch(GeneralAPIs({
  //       method: 'post',
  //       end_point: feature_definition.api_route + '/image/add',
  //       actions: actions,
  //       data_to_send: formData,
  //       feature_id: feature_definition.id,
  //       type: 'file',
  //       header: { 'content-type': 'multipart/form-data' }
  //     }))
  //     setFileUploaded(true)
  //   }
  // }
  // }

  // useEffect(() => {
  //   console.log('useEffect -file_state ', file_state.api_status)
  //   if (file_state.api_status === 'error') {
  //     setItemMessages([{ message: file_state.api_messages[0].message, message_type: file_state.api_messages[0].message_type }])
  //     console.log('file_state.api_status === error')
  //   }
  //   if (file_state.api_status === 'success') {
  //     console.log('file_state.api_status === success', file_state)
  //     setItemMessages([{ message: 'Contact added ' + file_state.api_messages[0].message, message_type: 'success' }])
  //   }
  //   setFile('')
  //   setPreviewImageURL('')

  // }, [file_state.api_status])

  /// Components below this line

  const FormButtons = (props: any) => {
    // console.log('FormButtons- props:', props)
    // console.log('FormButtons - item_button:', item_state.view_action, item_config.item_button)
    return (
      <div style={{ textAlign: "center" }}>
        {props.customButtons && props.customButtons()}
        {buttons &&
          buttons
            .filter((button: any) =>
              item_state.view_action === "add"
                ? button.add_view === true
                : item_state.view_action === "edit"
                  ? button.edit_view === true
                  : item_state.view_action === "view" && button.view_view === true
            )
            .sort((a: any, b: any) =>
              item_state.view_action === "add"
                ? a.add_order - b.add_order
                : item_state.view_action === "edit"
                  ? a.edit_order - b.edit_order
                  : item_state.view_action === "view" &&
                  a.view_order - b.view_order
            )
            .map((button: any) => (
              <button
                key={button.id}
                className={`
          ${button.colour} 
          ${item_state.api_status === "loading" && "button-loading"} 
          `}
                disabled={item_state.api_status === "loading"}
                onClick={() => submitHandler(button.button_action)}
              >
                {item_state.api_status === "loading"
                  ? SpinnerMini()
                  : button.translation.en}
              </button>
            ))}
      </div>
    );
  };

  const ItemHeader = () => {
    // console.log('formSubmitted', formSubmitted, 'item_state.api_status', item_state.api_status)
    return (
      <>
        {props.header &&
          props.header({
            formSubmitted: formSubmitted,
            api_status: item_state.api_status,
            next_action: item_state.next_action,
          })}
      </>
    );
  };

  const ItemFooter = () => {
    return (
      <>
        {props.footer &&
          props.footer({
            formSubmitted: formSubmitted,
            api_status: item_state.api_status,
            next_action: item_state.next_action,
          })}
      </>
    );
  };

  return (
    <>
      {item_state.api_status === "loading" &&
        props.hideItemViewDuringLoading ? ( //Used by MFA when user has declined MFA
        <Spinner />
      ) : (
        <>
          {displayMessage(itemMessages)}
          <ItemHeader />

          {item_state.api_status === "loading" ? (
            <Spinner />
          ) : (
            (item_state.api_status === "not_initiated" ||
              (item_state.api_status === "error" &&
                !props.hideFormAfterError) ||
              (item_state.api_status === "success" &&
                !props.hideFormAfterSuccessfulSubmitted)) && (
              <>
                <FormViewSelector
                  {...props}
                  field_values={field_values}
                  setFieldValues={setFieldValues}
                  // files/ images
                  file={file}
                  setFile={setFile}
                  fileUploadRef={fileUploadRef}
                  previewImageURL={previewImageURL}
                  setPreviewImageURL={setPreviewImageURL}
                />
                {item_state.view_action !== "view" && (
                  <>
                    <div style={{ paddingTop: "5px" }}>
                      {displayMessage(formMessages)}
                    </div>
                    {/* {console.log('props.customButtons', props.customButtons)} */}
                    <FormButtons customButtons={props.customButtons} />
                  </>
                )}
              </>
            )
          )}
          <ItemFooter />
          {feature_definition.item_config.item_tabs.length > 0 &&
            item_state.view_action === "view" && <ItemTabs {...props} />}
        </>
      )}
    </>
  );
}
