// Reference: 
//  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date 
//  https://www.w3schools.com/jsref/jsref_tolocalestring.asp

export const padLeadingZeros = (value) => {
  if (value < 1) return '00'
  if (value < 10) return '0' + value;
  else return '' + value;
}

export const datetime = (year, month, day) => {
  if (year && month && day) return new Date(year, month, day)
  else return new Date()
}

export const dateTimeFull = (date) => {
  if (date) return new Date(date)
  else return new Date()
}

export const ISOtoJSDate = (ISODateString) => {
  if (ISODateString === undefined || ISODateString === null) {
    return ''
  } else {
    const year = ISODateString.substring(0, 4)
    const month = ISODateString.substring(5, 7)
    const day = ISODateString.substring(8, 10)
    const jsdateObject = new Date(year, month - 1, day)
    // console.log('convertToDateObject - Before:', ISODateString, 'After:', dateObject)
    return jsdateObject
  }
}

export const LocaltoJSDate = (LocalDateString) => {
  if (LocalDateString === undefined || LocalDateString === null) {
    return ''
  } else {
    const year = LocalDateString.substring(0, 2)
    const month = LocalDateString.substring(3, 5)
    const day = LocalDateString.substring(6, 11)
    // console.log('year:',year,'month:',month,'day:',day)
    const jsdateObject = new Date(year, month - 1, day)
    // console.log('convertToDateObject - Before:', ISODateString, 'After:', dateObject)
    return jsdateObject
  }
}

// Time
export const localSeconds = padLeadingZeros(datetime().toLocaleString("en-GB", { second: "2-digit" }))
export const localMinute = padLeadingZeros(datetime().toLocaleString("en-GB", { minute: "2-digit" }))
export const localHour = datetime().toLocaleString("en-GB", { hour: "2-digit" })
export const localTime_HH_MM_SS = datetime().toLocaleTimeString()
export const localTime_HH_MM = localHour + ":" + localMinute

export const UTCSeconds = padLeadingZeros(datetime().getUTCSeconds())
export const UTCMinute = padLeadingZeros(datetime().getUTCMinutes())
export const UTCHour = padLeadingZeros(datetime().getUTCHours())
export const UTCTime_HH_MM_SS = UTCHour + ":" + UTCMinute + ":" + UTCSeconds
export const UTCTime_HH_MM = UTCHour + ":" + UTCMinute

// Dates
export const local_DD = datetime().toLocaleString("en-GB", { day: "2-digit" });
export const local_DDD = datetime().toLocaleString("en-GB", { weekday: "short" });
export const local_DDDD = datetime().toLocaleString("en-GB", { weekday: "long" });
export const local_MM = datetime().toLocaleString("en-GB", { month: "2-digit" });
export const local_MMM = datetime().toLocaleString("en-GB", { month: "short" });
export const local_MMMM = datetime().toLocaleString("en-GB", { month: "long" });
export const local_YY = datetime().toLocaleString("en-GB", { year: "2-digit" });
export const local_YYYY = datetime().getFullYear();

export const localDate_YYYY_MM_DD = local_YYYY + "/" + local_MM + "/" + local_DD
export const localDate_DD_MM_YYYY = datetime().toLocaleDateString()

export const date_YYYY_MM = (date) => {
  console.log('date_YYYY_MM', date)
  const newdate = dateTimeFull(date)
  const local_MM = newdate.toLocaleString("en-GB", { month: "2-digit" });
  const local_YYYY = newdate.getFullYear();
  return local_YYYY + "/" + local_MM
}

export const localISODate = (year, month, day) => {
  // console.log('year,month,date',year,month,date)
  const newdate = datetime(year, month, day)
  const local_DD = newdate.toLocaleString("en-GB", { day: "2-digit" });
  const local_MM = newdate.toLocaleString("en-GB", { month: "2-digit" });
  const local_YYYY = newdate.getFullYear();
  return local_YYYY + "-" + local_MM + "-" + local_DD
}

export const UTC_DD = datetime().getUTCDate()
export const UTC_MM = datetime().getUTCMonth() + 1
export const UTC_YYYY = datetime().getUTCFullYear();
export const UTC_DD_MM_YYYY = datetime().toLocaleDateString()

export const UTCISODate =
  padLeadingZeros(UTC_YYYY) + "-" +
  padLeadingZeros(UTC_MM) + "-" +
  padLeadingZeros(UTC_DD);


// Date & Time
export const toString = datetime().toString()
export const toISOString = datetime().toISOString()
export const toUTCString = datetime().toUTCString()
export const toLocaleString = datetime().toLocaleString()
export const localCleanISODateTime = localDate_YYYY_MM_DD + ' ' + localTime_HH_MM_SS
export const UTCCleanISODateTime = UTCISODate + ' ' + UTCTime_HH_MM_SS

//Calendar

const presentDay = new Date();
const presentYear = presentDay.getFullYear();
const presentMonth = presentDay.getMonth();

const daysInMonth = (year = presentYear, month = presentMonth) => {
  return 32 - new Date(year, month, 32).getDate();
};

const dateObject = (date, month, year) => {
  return {
    date,
    month,
    year,
    localDate: new Date(year, month, date).toLocaleDateString(),
    jsDate: new Date(year, month, date),
    ISODate: localISODate(year, month, date)
  };
};

const checkStartingDate = (data) => {
  const { startingDay, month, previousMonth, previousYear, nextMonth, nextYear, calendarDates } = data;

  if (startingDay > 0 && startingDay < 7) {
    const dates = [];
    const newWeek = calendarDates[0].slice(0, startingDay);
    const loopLength = 7 - newWeek.length;

    if (newWeek[0].month !== month) {
      for (let i = 0; i < loopLength; i += 1) {
        newWeek.unshift(dateObject(newWeek[0].date - 1, newWeek[0].month, newWeek[0].year));
      }
    } else {
      const previousMonthDays = daysInMonth(previousYear, previousMonth);
      for (let i = 0; i < loopLength; i += 1) {
        newWeek.unshift(dateObject(previousMonthDays - i, previousMonth, previousYear));
      }
    }

    dates.push(newWeek);

    calendarDates.forEach((each, i) => {
      const cloneWeek = [...each];
      cloneWeek.splice(0, startingDay);
      let slice = [];

      if (i + 1 < calendarDates.length) {
        slice = calendarDates[i + 1].slice(0, startingDay);
      }

      cloneWeek.push(...slice);
      dates.push(cloneWeek);
    })

    const lastIndex = dates.length - 1;

    if (dates[lastIndex].length < 7) {
      const leftOverDay = 7 - dates[lastIndex].length;
      const lastWeek = dates[lastIndex];

      if (lastWeek[lastWeek.length - 1].month !== month) {
        for (let i = 0; i < leftOverDay; i += 1) {
          dates[lastIndex].push(dateObject(lastWeek[lastWeek.length - 1].date + 1, nextMonth, nextYear));
        }
      } else {
        for (let i = 0; i < leftOverDay; i += 1) {
          dates[lastIndex].push(dateObject(i + 1, nextMonth, nextYear));
        }
      }
    }

    return dates;
  }

  return calendarDates;
};


export const datesGenerator = (data = {}) => {
  const presentDay = new Date();
  const {
    year = presentDay.getFullYear(),
    month = presentDay.getMonth(),
    startingDay = 1   // Monday = 1, Sunday = 0
  } = data;

  const previousYear = month === 0 ? year - 1 : year;
  const previousMonth = month === 0 ? 11 : month - 1;
  const nextYear = month === 11 ? year + 1 : year;
  const nextMonth = month === 11 ? 0 : month + 1;
  const calendarDates = [];
  const firstDay = (new Date(year, month)).getDay(); // 0 = 'Sunday', 6 = 'Saturday'
  const days = daysInMonth(year, month); // how many days in a month

  let x = 0;
  for (let i = 0; i < days; i += 1) {
    if (calendarDates.length === 0) {
      calendarDates.push([]);

      if (firstDay !== 0) {
        for (let y = 0; y < firstDay; y += 1) {
          const previousMonthDays = daysInMonth(previousYear, previousMonth);
          calendarDates[x].unshift(dateObject(previousMonthDays - y, previousMonth, previousYear));
        }

        calendarDates[x].push(dateObject(i + 1, month, year));
      } else {
        calendarDates[x].push(dateObject(i + 1, month, year));
      }
    } else if (calendarDates.length !== 0) {
      if (calendarDates[x].length < 7) {
        calendarDates[x].push(dateObject(i + 1, month, year));
      } else {
        calendarDates.push([dateObject(i + 1, month, year)]);
        x += 1;
      }
    }
  }

  const lastIndex = calendarDates.length - 1;

  if (calendarDates[lastIndex].length < 7) {
    const leftOverDay = 7 - calendarDates[lastIndex].length;
    for (let i = 0; i < leftOverDay; i += 1) {
      calendarDates[lastIndex].push(dateObject(i + 1, nextMonth, nextYear));
    }
  }

  const dates = checkStartingDate({ startingDay, month, year, previousMonth, previousYear, nextMonth, nextYear, calendarDates });

  const filterFirstWeekDates = dates[0].filter(each => each.month === month);

  if (filterFirstWeekDates.length === 0) {
    dates.splice(0, 1);
  }

  const lastWeekIndex = dates.length - 1;
  const filterLasttWeekDates = dates[lastWeekIndex].filter(each => each.month === month);

  if (filterLasttWeekDates.length === 0) {
    dates.splice(lastWeekIndex, 1);
  }

  return {
    dates,
    previousYear,
    previousMonth,
    nextYear,
    nextMonth,
  };
};