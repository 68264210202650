// import "../../tabs/Tabs.module.css"
import Tabs from "../../tabs/Tabs";
import ItemTab from "./ItemTab";

const ItemTabs = (props) => {
  const item_tabs = props.feature_state.definition.data.item_config.item_tabs;
  // console.log("item_tabs", item_tabs);
  return (
    <Tabs>
      {[...item_tabs]
        .sort((a, b) => a.tab_order - b.tab_order)
        .map((tab) => (
          <div key={tab.id} label={tab.translation.en}>
            <ItemTab current_tab={tab.id} tab_definition={tab} {...props} />
          </div>
        ))}
    </Tabs>
  );
};
export default ItemTabs;
