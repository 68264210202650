import { Link, useNavigate } from 'react-router-dom'
import tablestyle from './Table.module.css';
import { itemValues, handleItemClick } from '../ListLogic'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import { GeneralAPIs } from '../../../api/GeneralAPIs';
import { features_actions } from '../../../state/slices/FeatureSlice'

const TableRowData = (props: any) => {
  // console.log('TableRowData -props:', props)
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const user_settings = useAppSelector((state) => state.userSettings)
  const appMode = useAppSelector((state) => state.ui.appMode)
  const item = props.data_item
  const field_name = props.column_definition.field.field_name
  const list_config = props.list_config
  const media_server = process.env.REACT_APP_MEDIA_SERVER
  const object_store = props.feature_definition.object_store
  // console.log('TableRowData - column_definition:', props.column_definition) 

  const fieldDisplay = (item: any) => {
    // if (props.column_definition.field.field_type.field_type === 'image') {
    //   return <img src={media_server + object_store + '/' + item[field_name]} alt={item.letter} width="50" height="33" />
    // } else {
    //   // return item[field_name] 
    return itemValues(props, user_settings, dispatch)
    // }
  }

  // console.log('list_config.item_onclick_allow', list_config.item_onclick_allow)
  return (
    <>
      {list_config.item_onclick_allow === false ?
        <td
          key={item.id + item.field_name}>
          {fieldDisplay(item)}
        </td>
        :
        list_config.item_onclick_open === "field_details" ?
          <td
            key={item.id + item.field_name}>
            {fieldDisplay(item)}
          </td>
          :
          <td
            key={item.id + item.field_name}
            style={{ cursor: 'pointer' }}
            onClick={() => { handleItemClick(dispatch, navigate, props.module_definition, props.feature_definition, item.id, appMode) }}
          >
            {fieldDisplay(item)}
          </td>

        // list_config.item_onclick_modal === true ?
        //   <td
        //     key={item.id + item.field_name}
        //     style={{ cursor: 'pointer' }}
        //     onClick={() => {
        //       dispatch(GeneralAPIs({
        //         method: 'get',
        //         end_point: props.feature_definition.api_route + '/' + item.id,
        //         actions: features_actions,
        //         feature_id: props.feature_state.feature_id,
        //         type: 'item'
        //       }))
        //       dispatch(props.actions.openItemModal({
        //         item_id: item.id,
        //         feature_id: props.feature_state.feature_id,
        //         view_action: list_config.item_onclick_action
        //       }))
        //       // props.setItemViewMode(list_config.item_onclick_action);
        //     }}>
        //     {fieldDisplay(item)}
        //   </td>
        //   :
        //   <td
        //     key={item.id + item.field_name}
        //     style={{ cursor: 'pointer' }}
        //     onClick={() => {
        //       navigate(
        //         props.module_definition.frontend_link + '/' +
        //         props.feature_definition.frontend_link + '/' +
        //         list_config.item_onclick_action + ':' +
        //         item.id.toString())
        //     }}
        //   >
        //     {fieldDisplay(item)}
        //   </td>
      }
    </>)
}
export default TableRowData