import { EditableOptionAddButton, errorMessage, label, getFieldAttributes, handleOnChange } from "../InputFieldLogic"
import BasicGetAPI from '../../../../api/BasicGetAPI'
import { useEffect, useState } from "react"
import { MdAdd } from "react-icons/md"
import useModal from "../../../../hooks/useModal"
import { checkforqueryparms, selectField, get_conditional_options } from './SelectLogic'

export default function SelectField(props: any) {
  const field_config = props.field_config
  const field_name = field_config.field.field_name
  const field_values = props.field_values
  const static_options = field_config.options_source.split(",")
  const setFieldValues = props.setFieldValues
  const [dynamic_list, setDynamic_list] = useState([])
  const feature_view_mode = props.feature_state.item.view_action
  const [fixed_single_key_value_pair, setfixed_single_key_value_pair] = useState()

  // console.log('SelectField - props', props)
  // console.log('SelectField:', field_name, 'field_config:', field_name, field_config)
  // console.log('dynamic_list:', dynamic_list)
  // console.log('static_options:', static_options)

  const { show: showModuleModal, toggle: toggleModuleModal } = useModal();

  useEffect(() => {
    if (field_config.options_type === 'dynamic_list') {
      BasicGetAPI(field_config.options_api_endpoint + checkforqueryparms(props, field_name))
        .then((response: any) => {
          if (response.APIResult === 'success') {
            // console.log('SelectField', response.APIData)
            setDynamic_list(response.APIData)
          }
        })
    }

    // Logic for for PL Passage Crew
    if (field_config.options_type === 'fixed_single_key_value_pair') {
      // console.log('fixed_single_key_value_pair', field_name)
      if (feature_view_mode === 'add') {
        BasicGetAPI(field_config.options_api_endpoint + checkforqueryparms(props, field_name))
          .then((response: any) => {
            if (response.APIResult === 'success') {
              // console.log('SelectField', response.APIData)
              setDynamic_list(response.APIData)
            }
          })
      } else if (feature_view_mode === 'edit') {
        // console.log('feature_view_mode === edit', feature_view_mode, field_name, field_config.options_source, props.feature_state.item.data)
        const get_fixed_single_key_value_pair = props.feature_state.item.data[field_config.options_source] && props.feature_state.item.data[field_config.options_source].name

        // console.log('feature_State, item,data', field_name, get_fixed_single_key_value_pair)
        setfixed_single_key_value_pair(get_fixed_single_key_value_pair)

      }
    }
  }, [])

  const onChange = (field_value: any) => {
    const touched = getFieldAttributes(field_name, field_values, 'touched')
    // if (props.field.fieldType === 'multiSelect') {

    // } else {
    // setFieldValues(handleOnChange(field_name, field_values, field_value, field_type, field_config, field_translation, touched))
    setFieldValues(handleOnChange({
      field_config,
      field_values,
      field_value,
      touched
    }))
    // }
  }

  const conditional_options = get_conditional_options(field_config, field_values, dynamic_list)

  const getSelectFieldLogic = selectField(
    field_name,
    field_config,
    field_values,
    onChange,
    dynamic_list,
    static_options,
    conditional_options,
    fixed_single_key_value_pair,
    feature_view_mode
  )

  return (<>
    {props.hide_field === false && (
      field_config.item_add_conditional_render_logic === 'linkedField' ? (
        conditional_options.length > 0 &&
        getSelectFieldLogic

      )
        : getSelectFieldLogic
    )}

  </>)
}



{/* <ModuleModal
      show={showModuleModal}
      type='fixed'
      heading={'Add ' + field.label.EN}

      moduleModal={true}
      toggleModuleModal={toggleModuleModal}
      moduleLink={field.options.editLink}
      moduleModalItemViewMode={'add'}
      refreshSelectOptions={refreshSelectOptions}
      moduleModalField={field.fieldName}
      moduleModalFieldValues={fieldValues}

    // setNewItemId={setNewItemId}
    /> */}