import { createSlice } from '@reduxjs/toolkit'
import { apiInitialState, apiReducers } from '../reducers/APIReducer'

const userModuleSlice = createSlice({
  name: 'userModule',
  initialState: {
    ...apiInitialState(),
  },
  reducers: {
    ...apiReducers,
  }
})

export const userModulesActions = userModuleSlice.actions
export const userModulesReducer = userModuleSlice.reducer

const allModulesSlice = createSlice({
  name: 'allModules',
  initialState: {
    ...apiInitialState(),
  },
  reducers: {
    ...apiReducers,
  }
})

export const allModulesActions = allModulesSlice.actions
export const allModulesReducer = allModulesSlice.reducer