import { useAppSelector, } from '../hooks/useRedux'

// Components
import TopNavBar from "./TopNavBar";
import Sidebar from "./sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

export default function Root() {
  const isSignedIn = useAppSelector(state => state.user.isSignedIn)
  const uiState = useAppSelector(state => state.ui)

  return (
    <div id="root">
      <TopNavBar />
      <Sidebar />
      <div
        style={
          !isSignedIn ? { marginLeft: '0px' } :
            uiState.appMode === 'desktop' ? uiState.sideBarExpand === true ? {
              marginLeft: uiState.sideBarOpenWidth, transition: '0.5s'
            } : {
              marginLeft: uiState.sideBarCollapsedWidth, transition: '0.5s'
            } : { marginLeft: '0px' }
        }
      >
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}