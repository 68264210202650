// All used in InputField unless specified
import { MdAdd } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';
import ModuleModal from '../../views/ModuleModal';
import useModal from '../../../hooks/useModal';

export const EditableOptionAddButton = (field_config: any) => {
  const navigate = useNavigate();
  const { show: showModuleModal, toggle: toggleModuleModal } = useModal();
  // console.log('EditableOptionAddButton - field_config', field_config)
  return (<>
    <span
      onClick={() => {
        toggleModuleModal()
        // navigate('/' + field_config.options_frontend_edit_link + '/item:add')
        // 
      }}>
      <MdAdd
        // style={{ marginBottom: '5px' }}
        className='inputFieldEditSelectOptionsIcon'
        title={'Add ' + field_config.field.translation.en}
      />
    </span>
    <ModuleModal
      show={showModuleModal}
      hide={toggleModuleModal}
    // contents={filterFields}
    // dispatch={dispatch}
    // actions={features_actions}
    // feature_id={feature_state.feature_id}
    ></ModuleModal>
  </>)
}

export const mandatory = (field_config: any, field_values: any) => {
  // console.log('field_config',field_config.field.field_name, field_config, field_values)
  if (field_config.mandatory) { return true }
  else {
    if (field_config.mandatory_conditional_based_on) {
      const based_on_value = getFieldAttributes(field_config.mandatory_conditional_based_on, field_values, 'field_value')

      // console.log(
      //   'based_on:', field_config.mandatory_conditional_based_on,
      //   'logic:', field_config.mandatory_conditional_logic,
      //   'field_values:', field_values)
      // console.log('based_on_value:', based_on_value)

      if (field_config.mandatory_conditional_logic === 'includes') {
        // console.log('field_config.item_add_conditional_values',field_config.item_add_conditional_values)
        if (field_config.mandatory_conditional_values.includes(parseInt(based_on_value))) {
          return true
        }
      }
      if (field_config.mandatory_conditional_logic === 'equal_to') {
        // console.log('field_config.item_add_conditional_values', field_config.item_add_conditional_values)
        if (field_config.mandatory_conditional_values === based_on_value) {
          return true
        }
      }
    }
  }
}

export const label = (field_config: any, field_values: any) => {
  const field_name = field_config.field.field_name
  const field_translation = field_config.field.translation.en

  return (
    <label
      className={fieldActive(field_name, field_values, 'Active')}
    >
      {field_translation} {mandatory(field_config, field_values) && <span style={{ color: 'red' }}>*</span>}
    </label>
  )
}

export const errorMessage = (field_name: string, field_values: any) => {
  // console.log('errorMessage', field_name, getFieldAttributes(field_name, field_values, 'error_messages'))
  if (getFieldAttributes(field_name, field_values, 'has_error') === true) {
    return (
      <div className='inputFieldError' >
        <p>
          {getFieldAttributes(field_name, field_values, 'error_messages')
            .map((message: string) => message)}
        </p>
      </div>
    )
  }
}

// 1. Called from ItemView
export const setAddFormInitialFieldValues = (
  fields_config: any,
  primary_field: any,
  query_parms: any,
  input_value: any,

) =>
  fields_config.map((field_config: any) => {
    const field_name = field_config.field.field_name;
    const data_type_group = field_config.field.field_type.data_type_group;
    const html_field_type = field_config.field.field_type.html_field_type;
    // console.log('setAddFormInitialFieldValues', field_name, field_config.item_add_initial_value_value)

    const getInitialValue = () => {
      if (data_type_group === "number") {
        if (html_field_type === "select" && field_config.options_type === "static_list") {
          return field_config.item_add_initial_value_value
        } else {
          return parseInt(field_config.item_add_initial_value_value)
        }
      } else {
        // console.log('field_config.item_add_initial_value_value', field_config.item_add_initial_value_value)
        return field_config.item_add_initial_value_value
      }
    }

    // console.log('primary_field:', primary_field, 'input_value:', input_value)
    let field_value = field_name === primary_field ? input_value :
      field_config.item_add_initial_value_value ? getInitialValue() : ""

    if (query_parms) {
      for (var [key, value] of query_parms.entries()) {
        if (field_name === key) {
          field_value = Number.isInteger(parseInt(value)) ? parseInt(value) : value
          // console.log(key + '=' + value);
        }
      }
    }

    // const field_value = field_config.item_add_initial_value_value 
    //   ? data_type_group === "number"
    //     ? ( html_field_type === "select" && field_config.options_type === "static_list" && field_config.item_add_initial_value_value ) 
    //     ? parseInt(field_config.item_add_initial_value_value)
    //     : field_config.item_add_initial_value_value
    //   : "";
    // console.log('setInitialFormFieldValues - field_name:', field_name,'field_type:', html_field_type,'field_value',field_value )

    // if (fields.fieldType === 'image') {
    //   return {
    //     field_name: fields.fieldName,
    //     field_type: fields.fieldType,
    //     field_value: { src: fields.defaultImage, title: 'Add Image', defaultImage: true },
    //     touched: false,
    //     has_error: false,
    //     error_messages: []
    //   }
    // } else {

    return {
      field_name: field_name,
      field_type: html_field_type,
      field_value: field_value,
      touched: false,
      has_error: false,
      error_messages: [],
    };
  });

export const setEditFormInitialFieldValues = (
  fields_config: any,
  item_state: any
) =>
  fields_config.map((fields: any) => {
    // console.log('setEditFormInitialFieldValues - fields:', fields, item_state)
    // console.log('item_state.data:', item_state.data)
    // console.log('fields.field.field_name:', fields.field.field_name)
    return {
      field_name: fields.field.field_name,
      field_type: fields.field.field_type.html_field_type,
      field_value: item_state.data[fields.field.field_name],
      touched: false,
      has_error: false,
      error_messages: [],
    };
  });

// 2. Has the field got a value? Used for the field floating label.
export const fieldActive = (
  field_name: string,
  field_values: any,
  setClassValue: string
) => {
  const fieldstate = field_values.find((e: any) => e.field_name === field_name);
  // console.log('fieldActive - fieldstate', fieldstate)
  if (fieldstate === undefined || fieldstate === null) {
    return "";
  } else {
    // if (fieldstate.value.length >= 1) {
    if (fieldstate.field_value) {
      // console.log('add class')
      return setClassValue;
    } else {
      // console.log('no class')
      return "";
    }
  }
};

// 3. Get requested attribute from field values. Attribures include, touched, value, errors
export const getFieldAttributes = (
  field_name: string,
  field_values: any,
  field_attribute: string,
  fieldSubAttribute?: string
) => {
  // console.log('fieldAttributes - field_name:', field_name, 'field_values:', field_values, 'field_attribute:', field_attribute)

  // console.log('fieldAttributes - field_values:', field_values)
  const field_state = field_values.find(
    (e: any) => e.field_name === field_name
  );
  // console.log('fieldAttributes - field_state', field_state)
  // If field does not have any values return blank
  if (field_state === undefined) {
    // console.log('fieldAttributes - field - undefined')
    return "";
  } else {
    // If field is present but has no values return blank
    if (
      // field_state.field_value === undefined ||
      field_state.field_value === null
    ) {
      // console.log('fieldAttributes - field.field_value - undefined', field_state.field_name, field_state.field_value)
      return "";
    } else {
      // If a sub attribute e.g. src or title provided
      if (fieldSubAttribute !== undefined) {
        // console.log('fieldAttributes - fieldSubAttribute - defined', field_state[field_attribute])

        // Check if the field value is an object
        const objectValue = field_state[field_attribute];
        if (typeof objectValue === "object") {
          console.log("Im an object");
          return objectValue[fieldSubAttribute];
        }
        return field_state[field_attribute];
      } else {
        return field_state[field_attribute];
      }
    }
  }
};

//  4.
interface handleOnChange {
  field_config: any;
  field_values: any
  field_value: any
  touched: boolean
  action?: 'append' | 'delete'
}

export const handleOnChange = (
  { field_config,
    field_values,
    field_value,
    touched,
    action, }: handleOnChange
) => {
  const field_name = field_config.field.field_name;
  // const field_type = field_config.field.field_type.html_field_type
  // const field_translation = field_config.field.translation.en
  // console.log(field_name, field_value, 1);
  const { has_error, error_messages_array } = fieldValidationRules(
    field_config,
    field_value,
    field_values
  );

  const field_being_evaluated_index = field_values.findIndex(
    (e: any) => e.field_name === field_name
  );

  return field_values.map(
    (current_values: any, current_values_index: number) => {
      // console.log('current_values', current_values)

      // Handle append/delete is used by multi select items e.g. PL Passage Crew
      const handle_append = Array.isArray(current_values.field_value) ? [...current_values.field_value, field_value] : [field_value]

      const handle_delete = () => {
        console.log('before', current_values.field_value)
        const getIndex = current_values.field_value.findIndex((e: any) => e === field_value)
        console.log('getIndex', getIndex, current_values.field_value[getIndex])
        // const removedvalue = current_values.field_value.splice(getIndex, 1)
        let newArray = current_values.field_value.filter((obj: any, index: number) => index !== getIndex);
        console.log('after', newArray)
        return newArray
      }

      if (current_values_index == field_being_evaluated_index) {
        if (touched && has_error) {
          // console.log('handleOnChange- touched:', touched, 'has_error:', has_error)
          return {
            ...current_values,
            field_value: action === 'append' ? handle_append : action === 'delete' ? handle_delete() : field_value,
            has_error: true,
            error_messages: error_messages_array,
          };
        } else {
          return {
            ...current_values,
            field_value: action === 'append' ? handle_append : action === 'delete' ? handle_delete() : field_value,
            has_error: false,
            error_messages: [],
          };
        }
      } else {
        return { ...current_values };
      }
    }
  );
};

// export const setFieldAttributes = (
//   field_name: string,
//   field_values: any,
//   field_attribute: string,
//   field_value: any,
// ) => {
//   const getFieldIndex = field_values.findIndex((e: any) => e.field_name === field_name)
//   // console.log('setFieldAttributes', field_name, field_values, field_attribute, field_value)
//   // console.log('setFieldAttributes', getFieldIndex)
//   return (
//     field_values.map((currentData: any, index: any) => {
//       if (index === getFieldIndex) {
//         // console.log('yes updating', field_attribute, field_value)
//         return {
//           ...currentData,
//           [field_attribute]: field_value
//         }
//       } else {
//         // console.log('no updates')
//         return { ...currentData }
//       }
//     }))
// }

// export const updateFieldValue = (
//   field_name: string,
//   field_value: any,
//   field_values: any) => {
//   const getFieldIndex = field_values.findIndex((e: any) => e.field_name === field_name)
//   return (
//     field_values.map((currentData: any, index: any) => {
//       if (index === getFieldIndex) {
//         return {
//           ...currentData,
//           'field_value': field_value
//         }
//       } else {
//         return { ...currentData }
//       }
//     }))
// }

// 5. Validation Rules
// export const fieldValidationRules = (field_name: string, field_value: any, field_type: any, field_config: any, field_translation: string) => {
// Field values are requires for conditional mandatory fields.
export const fieldValidationRules = (field_config: any, field_value: any, field_values: any) => {
  const field_translation = field_config.field.translation.en;
  const field_type = field_config.field.field_type;

  // console.log('fieldValid field_value:', field_value, 'field_config:', field_config, 'field_translation:', field_translation)

  let has_error = false;
  let error_messages_array: any = "";

  const setError = (error_message: string) => {
    const newErrorMessage = field_translation + " " + error_message;
    // console.log('newErrorMessage', newErrorMessage)
    has_error = true;
    if (error_messages_array === "") {
      error_messages_array = [newErrorMessage];
    } else {
      error_messages_array.push(" , " + newErrorMessage);
    }
  };

  // Handling standard rules
  // const mandatory = mandatory(field_config,field_value)

  // const maxlength =
  // (field_type === 'decimal' || field_type === 'number') ? 20 :
  //   (field_type === 'textarea') ? 1000 :
  //     (field_type === 'document' || field_type === 'image' || field_type === 'url') ? 250 :
  //       50  // text autocomplete, select

  const validation_max_len =
    field_config.validation_max_len === null
      ? field_config.field.field_type.max_length
      : field_config.validation_max_len;

  // console.log('validation_max_len- after', field_name, ':', validation_max_len)
  const validation_min_len = field_config.validation_min_len;
  const validation_neg_allowed = field_config.validation_neg_allowed;

  const lengthType =
    field_type === "decimal" || field_type === "number"
      ? " digits"
      : field_type === "text" ||
        field_type === "textarea" ||
        field_type === "url"
        ? " characters"
        : " length";

  if (mandatory(field_config, field_values) && (field_value == "" || field_value == undefined)) {
    setError("is required");
  }
  if (field_value && field_value.length > 0) {
    // console.log('field_value && field_value.length > 0',field_value.length,'validation_min_len:', validation_min_len )
    if (validation_max_len && field_value.length > validation_max_len) {
      setError(
        "is too long, maximum " + validation_max_len + lengthType + " allowed"
      );
    }
    if (validation_min_len && field_value.length < validation_min_len) {
      setError(
        "is too short, minimum " + validation_min_len + lengthType + " required"
      );
    }
  }

  if (validation_neg_allowed && field_value < 0) {
    setError("can not be negative");
  }

  // Handling custom rules
  const custom_rules = field_config.custom_rules;
  // console.log('custom_rules', custom_rules)

  if (custom_rules.length > 0 && field_value !== "") {
    for (const each_custom_rule of custom_rules) {
      const custom_rule = each_custom_rule.custom_rule;
      const reg_exp = new RegExp(eval(custom_rule.reg_exp));
      // console.log('single custom rule', field_name, custom_rule.rule, reg_exp, field_value)
      // console.log('eval(custom_rule.rule)', eval(custom_rule.rule))
      if (eval(custom_rule.rule)) {
        // console.log('reg_exp',reg_exp)
        setError(custom_rule.translation.en);

        break;
      }
    }
  }
  // console.log('fieldValidationRules - has_error:', has_error, 'error_messages_array:', error_messages_array)
  return { has_error, error_messages_array };
};

// 6. handle blur validates one field at a time
export const handleOnBlur = (
  field_config: any,
  field_values: any,
  field_value: any
) => {
  const field_name = field_config.field.field_name;

  // console.log('handleOnBlur', field_name, field_values, field_value, field_type, field_translation)
  const { has_error, error_messages_array } = fieldValidationRules(
    field_config,
    field_value,
    field_values
  );

  const field_being_evaluated_index = field_values.findIndex(
    (e: any) => e.field_name === field_name
  );
  // console.log('handleOnBlur -:getindex', getindex)
  return field_values.map(
    (current_values: any, current_values_index: number) => {
      if (current_values_index == field_being_evaluated_index) {
        if (has_error) {
          // console.log('handleOnBlur - has_error:', has_error, 'error_messages_array:', error_messages_array, 'currentData:', currentData)
          return {
            ...current_values,
            touched: true,
            has_error: true,
            error_messages: error_messages_array,
          };
        } else {
          return {
            ...current_values,
            touched: true,
            has_error: false,
            error_messages: [],
          };
        }
      } else {
        return { ...current_values };
      }
    }
  );
};

export const validateAllFields = (fields_config: any, field_values: any) => {
  let newFieldValues = [...field_values];
  // console.log('newFieldValues - before', newFieldValues)

  // console.log('field_values', field_values)
  field_values.map((field_value: any) => {
    // console.log('field_value', field_value)

    fields_config
      .filter(
        (fields_config: any) =>
          fields_config.field.field_name === field_value.field_name
      )
      .map((field_config: any) => {
        // console.log('field_config', field_config)
        const { has_error, error_messages_array } = fieldValidationRules(
          field_config,
          field_value.field_value,
          field_values
        );

        const arrayIndex = newFieldValues.findIndex(
          (item: any) => item.field_name === field_value.field_name
        );

        if (has_error) {
          // console.log(field_value.field_name, arrayIndex, 'has_error')
          newFieldValues[arrayIndex] = {
            ...field_value,
            touched: true,
            has_error: true,
            error_messages: error_messages_array,
          };
        } else {
          newFieldValues[arrayIndex] = {
            ...field_value,
            touched: true,
            has_error: false,
            error_messages: [],
          };
          // console.log(field_value.field_name, arrayIndex, ' no error')
        }
      });
  });
  // console.log('newFieldValues - after ', newFieldValues)
  return newFieldValues;
};

export const isFormValid = (fields_config: any, field_values: any) => {
  const results = validateAllFields(fields_config, field_values);
  // console.log('results', results)
  const resultsHasError = results.find((item: any) => item.has_error === true);
  if (resultsHasError === undefined) return true;
  else return false;
};

// Takes the field_values array and returns an object of key value pairs
export const flatternFieldValuesToJSON = (field_values: any) => {
  const flatternedFieldValues = field_values.reduce(
    (obj: any, item: any) =>
      Object.assign(obj, { [item.field_name]: item.field_value }),
    {}
  );
  // console.log('flatternedFieldValues', flatternedFieldValues)
  // console.log('flatternedFieldValues', flatternedFieldValues)

  // Image field is an object and database only takes strings.
  if (flatternedFieldValues.image) {
    if (flatternedFieldValues.image.defaultImage === true) {
      flatternedFieldValues.image = "";
    } else {
      flatternedFieldValues.image = flatternedFieldValues.image.title;
    }
  }
  const removeFalsyValues = Object.entries(flatternedFieldValues).reduce(
    (a: any, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  );
  // console.log('removeFalsyValues', removeFalsyValues)
  return removeFalsyValues;
};

export const appendToFormData = (field_values: any) => {
  const formData: any = new FormData();
  const json_data = flatternFieldValuesToJSON(field_values);
  for (const [key, value] of Object.entries(json_data)) {
    // console.log('key/value', key, '/', value)
    formData.append(key, value);
  }
  return formData;
};
