const sortLogic = (dataToBeSorted: any, sortField: string, sortOrder: string) => {
  // console.log('sortField', sortField, 'dataToBeSorted:', dataToBeSorted)
  return [...dataToBeSorted].sort((a, b) => {
    if (a[sortField] === null||a[sortField] === undefined ) return 1;
    if (b[sortField] === null ||b[sortField] === undefined) return -1;
    if (a[sortField] === null && b[sortField] === null) return 0;
    if (a[sortField] === undefined && b[sortField] === undefined) return 0;
    // console.log('a[sortField]',a[sortField])
    return (
      a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
        numeric: true,
      }) * (sortOrder === "asc" ? 1 : -1)
    );
  });
}

export const handleSingleSortValue = (
  dispatch: any,
  actions: any,
  dataToBeSorted: any,
  sortField: string,
  sortOrder: string,
  feature_id: number,
) => {

  // console.log('dataToBeSorted:', dataToBeSorted, 'sortField:', sortField, 'sortOrder:', sortOrder)

  if (sortField) {
    const sortedData = sortLogic(dataToBeSorted, sortField, sortOrder)
    // const sortedData = [...dataToBeSorted].sort((a, b) => {
    //   if (a[sortField] === null) return 1;
    //   if (b[sortField] === null) return -1;
    //   if (a[sortField] === null && b[sortField] === null) return 0;
    //   return (
    //     a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
    //       numeric: true,
    //     }) * (sortOrder === "asc" ? 1 : -1)
    //   );
    // });
    return (
      dispatch(actions.setListData({ sortedData, feature_id })),
      dispatch(actions.setListSortOrder({ sortField, sortOrder, feature_id }))
    )
  }
};


export const addItemAndSortValues = (
  dispatch: any,
  actions: any,
  new_item: any,
  dataToBeSorted: any,
  sort_choices: any,
  feature_id: number,
) => {
  const newArray = [...dataToBeSorted]
  newArray.push({ ...new_item })
  let sortedData = newArray

  for (const sort_choice of sort_choices) {
    const sortField = sort_choice.sortField
    const sortOrder = sort_choice.sortOrder
    const partSortedData = sortLogic(sortedData, sortField, sortOrder)
    sortedData = partSortedData
  }

  return dispatch(actions.setListData({ sortedData, feature_id }))
};


export const replaceItemAndSortValues = (
  dispatch: any,
  actions: any,
  new_item: any,
  dataToBeSorted: any,
  sort_choices: any,
  feature_id: number,
) => {
  const index = dataToBeSorted.find((item: any) => item.id === new_item.id)
  const newArray = [...dataToBeSorted]
  newArray.splice(index, 1) // delete old value
  newArray.push({ ...new_item })
  let sortedData = newArray

  for (const sort_choice of sort_choices) {
    const sortField = sort_choice.sortField
    const sortOrder = sort_choice.sortOrder
    const partSortedData = sortLogic(sortedData, sortField, sortOrder)
    sortedData = partSortedData
  }

  return dispatch(actions.setListData({ sortedData, feature_id }))
};