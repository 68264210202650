import { useEffect, useState } from "react";
import UserAuthView from "../../../components/views/UserAuthView";
import { MFASetup } from "../components/MFASetup";
import BasicGetAPI from "../../../api/BasicGetAPI";

export default function EnableMFA(props: any) {
  const [data, setData] = useState<any>();
  console.log("data", data);

  useEffect(() => {
    const getData = async () => {
      await BasicGetAPI("user/mfa/new").then((response) => {
        if (response.APIResult === "success") {
          setData(response.APIData);
        }
      });
    };
    getData();
  }, []);

  const header = (props: any) => {
    return MFASetup({ otpauth_url: data.otpauth_url, secret: data.secret });
  };

  return (
    <UserAuthView
      feature_id={4}
      centerScreen={false}
      // showBackButton
      modalClose={props.modalClose}
      header={header}
    />
  );
}
