import UserAuthView from '../../../components/views/UserAuthView'
import { Link, useParams } from 'react-router-dom'
import { passwordCheck } from './AuthLogic'
import { useAppDispatch } from '../../../hooks/useRedux';
import { userAuthScreensActions } from '../../../state/slices/UserSlice';

export default function PasswordReset() {
  const { token } = useParams();
  const dispatch = useAppDispatch()
  // console.log('token', token)
  dispatch(userAuthScreensActions.setPasswordResetAction())

  const preSubmitActions = (field_values: any) => {
    return passwordCheck(field_values)
  }

  const header = (props: any) => {
    // console.log('header props', props)
    if (props.api_status === 'success') {
      return (
        <div style={{ textAlign: 'center' }}>
          <p>Your password has been successfully updated. Please sign in.</p>
          <Link to='/user/signin' >
            <button>Sign In</button>
          </Link>
        </div>
      )
    }
    if (props.api_status === 'error') {
      return (
        <div style={{ textAlign: 'center' }}>
          <p >An error has occured, please request a new password reset link or contact customer support. </p>
          <p className="linkText"><Link to='/user/password/forgot' > Request a password reset link</Link></p>
        </div>)
    }
  }

  return (<>
    <UserAuthView
      feature_id={7}
      data_to_send={{ token: token }}
      centerScreen
      header={header}
      hideFormAfterSuccessfulSubmitted
      hideFormAfterError
      preSubmitActions={preSubmitActions}
    />
  </>)
}