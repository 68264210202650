import BaseContainer from "../../components/containers/BaseContainer";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, } from '../../hooks/useRedux'
import { Link } from "react-router-dom";
import BasicGetAPI from "../../api/BasicGetAPI";

const UserAccount = () => {
  const userAccount = useAppSelector(state => state.userAccount)
  const [showScreen, setShowScreen] = useState('home')
  const [gotEmailReceipt, setGotEmailReceipt] = useState(false)

  const getEmailReceipt = async () => {
    await BasicGetAPI('user/account/receipt/email')
      .then(response => {
        if (response.APIResult === 'success') {
          setGotEmailReceipt(true)
        }
      })
  }

  const AccountHomeScreen = () => {
    return (<>
      <h3>Current Plan </h3>
      {!userAccount.currentPlanName &&
        <p>You currently do not have any active subscriptions</p>
      }
      {userAccount.currentPlanName === 'Free Tier' && <>
        <p>Plan name: Free Tier </p>
        <p>You currently do not have any active subscriptions</p>
      </>}
      {(userAccount.currentPlanName === 'Standard' ||
        userAccount.currentPlanName === 'Pro') && <>
          <p>Name: {userAccount.currentPlanName}</p>
          <p>Status: {userAccount.currentPlanStatus}</p>
          {/* <p>Renewal Date: {userAccount.currentPlanRenewalDate && userAccount.currentPlanRenewalDate.toLocaleDateString("en-GB")}</p> */}
          {userAccount.currentPlanStatus != 'Cancelled' &&
            <p>Renewal Amount: £{userAccount.currentPlanPrice}</p>}
        </>}
      <button onClick={(e) => { setShowScreen('changePlan') }}>Change Plan</button>

      <br></br>
      <h3>Billing Details</h3>
      <p>Billing address</p>
      <button onClick={(e) => { setShowScreen('updateBilling') }}>Update Billing Address</button>
      <br></br>
      <h3>Payment Methods</h3>
      <p>Card details </p>
      <button onClick={(e) => { setShowScreen('updatePayment') }}>Update Payment Details</button>
      <br></br>

      <h3>Payment History</h3>
      <p>Date, Order Number, Amount, VAT, Total, Receipt </p>
      {gotEmailReceipt ? 'Receipt Sent' : <button onClick={(e) => { getEmailReceipt() }}>Email Receipt</button>}

      <h3>Your Data </h3>
      <p>Export </p>
      <ul>
        <li>Contacts</li>
        <li>Boats</li>
        <li>Trips</li>
        <li>Passages</li>
        <li>Log Entries</li>

      </ul>
    </>)
  }

  const PlanCard = ({ name, price }: any) => {
    <>
      <h4>{name}</h4>
      {/* £{monthlyPrice}  */}

    </>
  }

  const ChangePlanScreen = () => {
    return (<>
      <h3>Select a New Plan </h3>
      Free
      Standard
      Pro
      <Link to='/pricing'>Plan details</Link>
      <button onClick={(e) => { setShowScreen('home') }}>Cancel</button>
      <button onClick={(e) => { setShowScreen('changePlan') }}>Change Plan</button>
    </>)
  }

  const UpdateBillingScreen = () => {
    return (<>
      <h3>Billing Address </h3>
      Address block
      <button onClick={(e) => { setShowScreen('home') }}>Cancel</button>
      <button onClick={(e) => { setShowScreen('changePlan') }}>Update</button>
    </>)
  }

  const UpdatePaymentScreen = () => {
    return (<>
      <h3>Card details </h3>
      Name on Card
      Card Number
      Expiry Date
      Secutiry Code
      <button onClick={(e) => { setShowScreen('home') }}>Cancel</button>
      <button onClick={(e) => { setShowScreen('changePlan') }}>Update</button>
    </>)
  }

  return (
    <BaseContainer
      type='responsive'
      heading='Account'>

      {showScreen === 'home' && <AccountHomeScreen />}
      {showScreen === 'changePlan' && <ChangePlanScreen />}
      {showScreen === 'updateBilling' && <UpdateBillingScreen />}
      {showScreen === 'updatePayment' && <UpdatePaymentScreen />}


    </BaseContainer>
  )
}
export default UserAccount