import './Layout.css';
import { useOnlineStatus } from '../hooks/useOnlineStatus'
import { MdBugReport } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useAppSelector } from '../hooks/useRedux';

const Footer = () => {
  const isOnline = useOnlineStatus();
  const userAuth = useAppSelector((state) => state.user)
  const appMode = useAppSelector((state) => state.ui.appMode)

  const supportLink = (
    <Link to='/support/tickets' className='footerLink' > | Report a <span style={{ verticalAlign: 'middle' }}><MdBugReport /></span> or request a feature</Link>
  )

  return (
    <div className="footer">
      {appMode === 'mobile' ?
        <>
          {isOnline ? '✅ Online  ' : '❌ Offline  '} {userAuth.isSignedIn && supportLink}
        </>
        : <>
          <span> © {new Date().getFullYear()} Mcboaty</span> {userAuth.isSignedIn && supportLink}
        </>
      }
    </div>
  )
}

export default Footer;