import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Layout.css';
import { useAppDispatch, useAppSelector, } from '../hooks/useRedux'
import useModal from '../hooks/useModal';
import UserNavModal from './UserNavModal';

import { MdMenu, MdQuestionMark, MdAccountCircle, MdShoppingCart, MdSearch, MdNotifications, MdNotificationsNone } from 'react-icons/md';
import { userActions } from '../state/slices/UserSlice';
import { toggleShowSideBarMenu } from '../state/slices/UiSlice';

const TopNavBar = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const { isSignedIn } = useAppSelector(state => state.user)
  const uiState = useAppSelector(state => state.ui)
  const { show, toggle } = useModal();

  const handleNavMenuClick = () => {
    dispatch(toggleShowSideBarMenu())
  }

  const iconSize = '1.8em'

  let links;

  if (isSignedIn) {
    links = <div className="text-end">
      {/* <Link
        to="/signin"
        onClick={signOut}
        className="navbar-link navbar-link-right">
        Sign Out
      </Link> */}

      {/* <NotificationsModdal
        show={showNotificationsModal}
        hide={() => setShowNotificationsModal(!showNotificationsModal)} /> */}
      <div
        title="User Options"
        className="navbar-icon navbar-link-right"
        onClick={() => toggle()}
      >
        <MdAccountCircle size={iconSize} />
        <UserNavModal
          show={show}
          hide={toggle}
          handle_logout={() => {
            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
            console.log('handle_logout')
            dispatch(userActions.signOut(''))
          }} />
      </div>
      {/* {basketQty > 0 &&
        <Link
          to="/shop/basket"
          title="Shopping Basket"
          className="navbar-icon navbar-link-right">
          {basketQty}
          <MdShoppingCart size={iconSize} />
        </Link>
      } */}
      {/* <div

        title="Notifications"
        className="navbar-icon navbar-link-right"
        onClick={() => setShowNotificationsModal(!showNotificationsModal)}
      >
        {notificationsQty === 0 ? <>
          <MdNotificationsNone size={iconSize} />
        </> : <>
          {notificationsQty}
          <MdNotifications size={iconSize} />
        </>}

      </div> */}
      <Link
        to="/support/home"
        title="Help & Support"
        className="navbar-icon navbar-link-right">
        <MdQuestionMark size={iconSize} />
      </Link>
      {/* <span
        className="navbar-icon navbar-link-right"
        title="Search">

        <MdSearch size={iconSize} />
      </span>
      <Link to="/#pricing" className="navbar-link navbar-link-right">Pricing</Link> */}
    </div>
  } else {
    links = <div className="text-end">
      <Link to="/user/signin" className="navbar-link navbar-link-right">Sign In</Link>
      <Link to="/user/signup" className="navbar-link navbar-link-right">Sign Up</Link>

      {/* {basketQty > 0 &&
        <Link
          to="/shop/basket"
          title="Shopping Basket"
          className="navbar-icon navbar-link-right">
          {basketQty}
          <MdShoppingCart size={iconSize} />
        </Link>
      } */}
      {uiState.appMode === 'desktop' && <>
        {/* <Link to="/#contactus" className="navbar-link navbar-link-right">Contact</Link> */}
        {/* <Link to="/shop/products" className="navbar-link navbar-link-right">Products</Link> */}
        {/* <Link to="/#pricing" className="navbar-link navbar-link-right">Pricing</Link> */}
        {/* <Link to="/#features" className="navbar-link navbar-link-right">Features</Link> */}
      </>}
    </div>
  }

  const hamburgerMenu =
    <a className={uiState.sideBarShow ? "navbar-link-left navbar-hamburgerMenu topnavresponsive" : "navbar-link-left navbar-hamburgerMenu "}
      onClick={handleNavMenuClick}>
      <div className={"navbar-hamburgerMenu-bar1"}></div>
      <div className={"navbar-hamburgerMenu-bar2"}></div>
      <div className={"navbar-hamburgerMenu-bar3"}></div>
    </a>

  return (
    <ul className="navbar-links-list">
      {isSignedIn ?
        (uiState.appMode === 'mobile' ?
          hamburgerMenu
          :
          <Link to="/" className="navbar-link navbar-link-left">McBoaty</Link>)
        :
        <Link to="/" className="navbar-link navbar-link-left">McBoaty</Link>
      }
      {/* {uiState.appMode === 'desktop' ?
        <Link to="/" className="navbar-link navbar-link-left">Logo</Link>
        : isSignedIn && hamburgerMenu
      } */}
      {links}
    </ul>
  )
}

export default TopNavBar