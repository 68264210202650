import React from "react";
import ReactDOM from "react-dom";
import FeaturesView from "./FeaturesView";

const ModuleModal = (props: any) => props.showModuleModal ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modalOverlay modalOverlaycolor"
    // onClick={hide}
    >
      <div className="modalCenterScreen">
        {/* <h1>I'm a modal{options_frontend_edit_link} </h1> */}

        {/* <button onClick={hide}> Close </button> */}
        <FeaturesView
          {...props}
        // module_modal={true}
        // module_modal_hide={hide}
        // module_name={'personallog'}
        // feature_name={'contacts'}
        // feature_view={feature_view}
        // input_value={input_value}
        // options_frontend_edit_link={options_frontend_edit_link}
        />
      </div>
    </div>
  </React.Fragment >, document.body
) : null;

export default ModuleModal