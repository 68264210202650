import { APIInterceptor, APIServer, APICatchErrorHandler, APIOptionsPublic, APIOptionsSecure } from './APILogic';
import { useState } from "react";

// interface request {
//   APIEndPoint: string
//   item_id?: number
//   data_to_send?: any
//   APIPermissions?: 'public'
// }

export default async function BasicPatchAPI(APIEndPoint: string, data_to_send: any, APIPermissions?: string) {
  // const [status, setStatus] = useState('not_initiated')
  // console.log('BasicGetAPI', status)
  // const APIPermissions = 'private'
  const AxiosInterceptor = APIInterceptor()

  const fetchData = async () => {
    const response = await AxiosInterceptor
      .patch(APIServer + APIEndPoint, data_to_send,
        (APIPermissions === 'public' ? APIOptionsPublic : APIOptionsSecure))
    // setStatus('success')
    // console.log('response', response)
    return response
  }

  try {
    // setStatus('loading')

    const response = await fetchData()
    // console.log('response.data', response)
    const APIResult = 'success'
    const APIData = response ? response.data ? response.data : 'No data' : 'No response'
    const APIStatusCode = response ? response.status ? response.status : 'No status' : 'No response'
    const APIMessage = { type: 'success', message: '' }
    return { APIResult, APIStatusCode, APIMessage, APIData }
  }
  catch (error: any) {
    // setStatus('error')
    console.log('error', error)
    return APICatchErrorHandler(error)
  }
}