import classes from '../../lists/table/Table.module.css';
import { setFilterValue, getFilterAttribute, getSelectOptionsFromListData, filterSelectIsChecked } from '../../lists/ListLogic'
import { useAppDispatch } from '../../../hooks/useRedux'

const FilterFields = (props: any) => {
  // console.log('FilterFields -props:', props)
  const dispatch = useAppDispatch()
  const actions = props.actions
  const filter_choices = props.feature_state.list.filter_choices
  const field_name = props.item.field.field_name
  const field_type = props.item.field.field_type.html_field_type
  const feature_id = props.feature_state.feature_id

  const FilterText = (
    <input
      className='filterField'
      type='text'
      placeholder='Type to filter...'
      value={getFilterAttribute(field_name, filter_choices, 1, 'value')}
      // onChange={e => setFilterValue(dispatch, actions, field_name, field_type, e.target.value, 1, feature_id)}
      onChange={e => dispatch(actions.setListFilter({
        field_name: field_name,
        field_type: field_type,
        filter_value: e.target.value,
        fitlerItemId: 1,
        feature_id: feature_id
      }))}
    >
    </input>
  )

  const FilterNumber = (<>
    <div>
      <select
        className='filterField'
        name="logic1"
        id="logic1"
      // value={getFilterAttribute(field_name, filter_choices, 1, 'logic')}
      // onChange={e => setFilterValue(dispatch, actions, field_name, field_type, getFilterAttribute(field_name, filter_choices, 1, 'value'), 1, e.target.value)}
      >
        <option value="includes">Includes:</option>
        <option value="eqaulTo">Equal to:</option>
        <option value="equalToGreaterThan">Equal to & Greater than:</option>
        <option value="equalToLessThan">Equal to & Less than:</option>
        <option value="greaterThan">Greater than:</option>
        <option value="lessThan">Less than:</option>
        <option value="notEqualTo">Not Equal to:</option>
      </select>
      <input
        className='filterField'
        type='number'
        placeholder='Enter a number...'
      // value={getFilterAttribute(field_name, filter_choices, 1, 'value')}
      // onChange={e => setFilterValue(dispatch, actions, field_name, field_type, e.target.value, 1)}
      >
      </input>
    </div>

    {/* {(getFilterAttribute(field_name, filter_choices, 1, 'logic') === 'equalToGreaterThan' ||
      getFilterAttribute(field_name, filter_choices, 1, 'logic') === 'greaterThan'
    ) &&
      <>
        <br></br>
        <select
          className='filterField'
          name="logic2"
          id="logic2"
          // value={getFilterAttribute(field_name, filter_choices, 2, 'logic')}
          // onChange={e => setFilterValue(dispatch, actions, field_name, field_type, getFilterAttribute(field_name, filter_choices, 2, 'value'), 2, e.target.value)}
        >
          <option value="equalToLessThan">Equal to & Less than:</option>
          <option value="lessThan">Less than:</option>
        </select>
        <input
          className='filterField'
          type='number'
          placeholder='Enter a number...'
          // value={getFilterAttribute(field_name, filter_choices, 2, 'value')}
          // onChange={e => setFilterValue(dispatch, actions, field_name, field_type, e.target.value, 2)}
        >
        </input>
      </>
    } */}

  </>
  )

  const FilterSelectCheckbox = (props: any) => {
    // console.log('FilterSelectCheckbox -props:', props)
    // Use static_list or keyValuePairs? 
    // console.log('Use static_list or keyValuePairs dynamic_list?', props.source, props.name, props.id)

    return (
      <div
        className={classes.selectOption}
        // onClick={e => setFilterValue(dispatch, actions, field_name, field_type, props.id, 1)}
        onClick={e =>
          dispatch(actions.setListFilter({
            field_name: field_name,
            field_type: field_type,
            filter_value: props.id,
            fitlerItemId: 1,
            feature_id: feature_id
          }))}
      >
        <input
          style={{
            width: '10%',
            cursor: 'pointer',
            height: '1.1em',
          }}
          type="checkbox"
          value={props.id}
          onClick={e =>
            dispatch(actions.setListFilter({
              field_name: field_name,
              field_type: field_type,
              filter_value: props.id,
              fitlerItemId: 1,
              feature_id: feature_id
            }))}

          checked={filterSelectIsChecked(filter_choices, field_name, props.id)}
          // onChange={e => props.handleSetFilterValues(field_name, field_type, e.target.value)}
          onChange={e => dispatch(actions.setListFilter({
            field_name: field_name,
            field_type: field_type,
            filter_value: props.id,
            fitlerItemId: 1,
            feature_id: feature_id
          }))}


        />
        <span > {props.name}</span>
      </div>
    );
  };

  const selectOptions = getSelectOptionsFromListData(props.list_data, field_name, props.item)
  // console.log('selectOptions', selectOptions)
  const FilterSelect = (
    <>
      {selectOptions === undefined || selectOptions === null || selectOptions === 'No data available' ? <p >No options available</p> :
        <>
          {selectOptions &&
            props.item.options_type === 'static_list' ?
            selectOptions
              .sort((a, b) => {
                if (a === null) return 1;
                if (b === null) return -1;
                if (a === null && b === null) return 0;
                return (
                  a.toString().localeCompare(b.toString(), "en", {
                    numeric: true,
                  })
                );
              })
              .map((item) => (

                <FilterSelectCheckbox
                  key={item}
                  id={item}
                  name={item}
                  field_name={field_name}
                  field_type={field_type}
                  source={'static_list'}
                  feature_id={feature_id}
                />
              ))
            :
            selectOptions
              .sort((a, b) => {
                if (a.name === null) return 1;
                if (b.name === null) return -1;
                if (a.name === null && b.name === null) return 0;
                return (
                  a.name.toString().localeCompare(b.name.toString(), "en", {
                    numeric: true,
                  })
                );
              })
              .map((item) => (
                <FilterSelectCheckbox
                  key={item}
                  id={item.id}
                  name={item.name}
                  field_name={field_name}
                  field_type={field_type}
                  source={'dynamic_list'}
                  feature_id={feature_id}
                />
              ))}

          <FilterSelectCheckbox
            key='blank'
            id={9999}
            name='(blank)'
            field_name={field_name}
            field_type={field_type}
            source={'static_list'}
            feature_id={feature_id}
          />
        </>}
    </>)

  const fieldSelector = () => {
    // console.log('fieldSelector:', field_type)
    switch (field_type) {

      // case 'autocomplete':
      //   return FilterAutoComplete

      case 'decimal':
        return FilterNumber

      case 'select':
        return FilterSelect

      case 'text':
        return FilterText

      case 'number':
        return FilterNumber

      default:
        return FilterText
    }
  }


  return (
    <div className={props.setClassFor === 'tableControl' ? `${classes.filterModalFields} ${classes.filterModalNumber}` : ''}>

      {fieldSelector()}
      {/* {field_type === 'text' && FilterText}
      {field_type === 'number' && FilterNumber}
      {field_type === 'select' && FilterSelect} */}
    </div>
  )
}

export default FilterFields