// React
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/useRedux'
//Components
// import Table from '../../lists/table/Table'
import AddFormButton from '../../buttons/AddFormButton'
import useModal from '../../../hooks/useModal'
// import ModuleModal from '../../../layout/ModuleModal';
// import TableHead from './TableHead'
// import TableRowData from './TableRowData'
//Style
import tablestyle from '../../lists/table/Table.module.css';
//Logic
import TableRowOptions from '../../lists/table/TableRowOptions'
import { basicItemValues } from '../../lists/ListLogic'
import BasicGetAPI from "../../../api/BasicGetAPI";
import { fieldAttributes } from '../../fields/inputFields/InputFieldLogic'

const ItemTab = (props) => {
  // console.log('ItemTab - props:', props)
  // console.log('ItemTab - tab_definition:', props.tab_definition)

  const tab = props.current_tab
  const navigate = useNavigate();
  const [data, setData] = useState()
  const [message, setMessage] = useState('')
  const [message_type, setMessageType] = useState('')
  const [loading, setLoading] = useState(false)
  const { show: showModuleModal, toggle: toggleModuleModal } = useModal();
  const userSettings = useAppSelector((state) => state.userSettings)

  const tab_definition = props.tab_definition

  useEffect(() => {
    const getData = async () => {
      await BasicGetAPI(tab_definition.api_route + '/' + props.item_id)
        .then(response => {
          if (response.APIResult === 'success') {
            setData(response.APIData)
          }
        })
    }
    getData()
  }, [tab_definition])

  // const getData = async () => {
  //   if (props.dataItem.id) {
  //     await GeneralAPIs('get', props.APIEndpoint + '/' + props.dataItem.id)
  //       .then(response => {
  //         if (response.APIResult === 'success') {
  //           // console.log('response.APIData', response.APIData)
  //           setData(response.APIData)
  //         } else {
  //           setMessageType('error')
  //           setMessage(response.APIMessage.message)
  //         }
  //       })
  //       .catch(error => {
  //         setLoading(false)
  //         setMessageType('error')
  //         setMessage(error.response.data.error ? error.response.data.error : 'An unknown error has occured')
  //       })
  //   }
  // }
  // // console.log(data && data.length)

  // useEffect(() => { getData() }, [props.dataItem.id])


  // const setQueryString = () => {
  //   const queryParms = tab_definition.addOptions.queryParms
  //   // console.log('queryParms', queryParms)
  //   if (queryParms) {
  //     return (
  //       queryParms.map((param, index) => {
  //         return ((index === 0 ? '?' : '&') + param + '='
  //           + fieldAttributes(param, props.field_values, 'value'
  //           ))
  //       })
  //     ).join('')
  //   }
  // }

  // console.log('setQueryParameters', setQueryParameters())

  return (
    <>
      {/* {tab_definition.text && <div style={{ marginTop: '0.5rem' }}>{tab_definition.text} </div>} */}
      {/* {console.log('add_query_parms', tab_definition.add_query_parms, props.item_id)} */}
      {tab_definition.add_allow === true &&
        <AddFormButton
          onClick={() => {
            // toggleModuleModal()
            navigate('/' + tab_definition.add_link + '/item:add' + (tab_definition.add_query_parms && '?' + tab_definition.add_query_parms + '=' + props.item_id))
          }}
          {...props}
        />
      }
      {data &&
        data.length >= 1 ?

        <table className={tablestyle.table}>
          <thead>
            <tr>

              {[...tab_definition.item_tab_fields]
                .sort((a, b) => a.table_column_order - b.table_column_order)
                .map(field => {
                  return (
                    <th
                      key={field.id}
                      className={tablestyle.notClickableHeader}
                      style={{ minWidth: "100px" }}
                    >
                      {field.field.translation.en}
                    </th>
                  )
                })
              }
              {tab_definition.row_options && tab_definition.row_options.length !== 0 &&
                <th className={tablestyle.notClickableHeader} >
                  Options
                </th>
              }
            </tr>
          </thead>
          <tbody>
            {data.map(data_item => {
              return (
                <tr
                  key={data_item.id}
                  style={{ cursor: 'pointer' }}
                  className={tablestyle.trhover}
                  onClick={() => {
                    navigate('/' + tab_definition.link + '/item:' + tab_definition.link_action + '?id=' + data_item[tab_definition.link_field])
                  }}
                >
                  {/* {console.log('data_item', data_item)} */}
                  {/* {console.log('tab_definition.item_tab_fields', tab_definition)} */}
                  {[...tab_definition.item_tab_fields]
                    .sort((a, b) => a.table_column_order - b.table_column_order)
                    .map(field => {
                      return (
                        <td
                          key={field.id}
                        >
                          {/* {console.log('field', data_item, field.field.field_name)} */}
                          {field.alt_field_name ? data_item[field.alt_field_name] : data_item[field.field.field_name]}
                        </td>
                      )
                    })
                  }
                  {tab_definition.row_options && tab_definition.row_options.length !== 0 &&
                    <TableRowOptions
                      data_item={data_item}
                      rowOptions={tab_definition.row_options.split(",")}
                      {...props}
                    />
                  }
                </tr>
              )
            })}
          </tbody>
        </table>


        :
        // <p>No data found</p>
        <p>No {tab_definition.translation.en.toLowerCase()} found</p>
      }
      {/* {tab_definition.addOptions &&
        <ModuleModal
          show={showModuleModal}
          type='fixed'
          heading={tab_definition.addOptions.name}

          moduleModal={true}
          toggleModuleModal={toggleModuleModal}
          moduleLink={tab_definition.addOptions.link}
          moduleLinkAction={(tab_definition.addOptions && tab_definition.addOptions.allow === true) && 'add'}
          moduleModalItemViewMode={'add'}
          moduleModalType={'notaninlinefield'}
          queryString={setQueryString()}

        // refreshSelectOptions={refreshSelectOptions}
        // moduleModalField={field.fieldName}
        // moduleModalFieldValues={field_values}
        />
      } */}
    </>
  );
};
export default ItemTab;