import { Link, useNavigate, useRouteError } from "react-router-dom";
import TopNavBar from "./TopNavBar";
import Footer from "./Footer";
import Sidebar from "./sidebar/Sidebar";

export default function ErrorPage() {
  const error: any = useRouteError();
  // console.error(error);
  const navigate = useNavigate();

  return (
    <div id="error-page">
      <TopNavBar />
      <Sidebar />
      <h1>Oops!</h1>
      <div style={{ textAlign: "center" }}>
        <h3 className="center">Sorry, an unexpected error has occurred. </h3>
        {error.statusText === "Not Found" && (
          <>
            <p className="center">
              The link you have used appears to be broken.
            </p>
          </>
        )}
        <span>Please </span>
        <Link to="/support/tickets" className="clickableText">
          raise a support ticket
        </Link>
        <span> to get this issue fixed. </span>
        {error.message && (
          <p> Please included this error message: {error.message} </p>
        )}
        <p className="center">
          <span>otherwise you can try and </span>
          <span onClick={(e) => navigate(0)} className="clickableText">
            return back{" "}
          </span>
          <span> to the previous page or </span>
          <Link to="/" className="clickableText">
            return to the home page.
          </Link>
        </p>
      </div>
      <Footer />
    </div>
  );
}
