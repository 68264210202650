import { MdAdd } from 'react-icons/md';
import GenericButton from './GenericButton';

export default function AddFormButton(props: any) {
  return (
    <GenericButton
      name='Add'
      onClick={props.onClick}
      icon={MdAdd}
      className={`${'green'} ${'addEditFormButtons'} ${'responsiveButton'}`}
      responsiveButtonText
    />
  )
}