// Contents 
// 1. Search
// 2. Filter
// 3. Sort
// 4. Groups
// 5. Select Options
// 6. View data

// React
import { useMemo, useState } from 'react';
import { checkboxValue, dateValue, timeFieldValue, keyValuePairValue } from '../fields/viewFields/FieldValues'
import ViewImageField from '../fields/viewFields/ImageField'

// Hooks
// import handleSelectOptions from '../../../hooks/handleSelectOptions'
import handleNumberFilter from '../../hooks/handleNumberFilter'
import useDateFilter from '../../hooks/useDateFilter'
import useTimeFilter from '../../hooks/useTimeFilter'
import { handleSingleSortValue } from '../../hooks/handleSortData';

import { GeneralAPIs } from '../../api/GeneralAPIs';
import { features_actions } from '../../state/slices/FeatureSlice'
import BasicPatchAPI from '../../api/BasicPatchAPI'

// 1. Search
export const HandleSearch = (list_data: any, searchInput: any) => {
  if (searchInput !== '') {
    // console.log('searchlogic', searchInput)
    return list_data
      .filter((item: any) => {
        // console.log('searchlogic item:', item)
        return Object
          .values(item)
          .join('')
          .toString()
          .toLowerCase()
          .includes(searchInput.toString().toLowerCase())
      })
  }
  else {
    // console.log('searchlogic -skipped')
    return list_data
  }
}

export const setSearchInput = (
  dispatch: any,
  actions: any,
  search_input: string | number,
  feature_id: number,
) => {
  dispatch(actions.setSearchInput({ input: search_input, feature_id: feature_id, type: 'list' }))
}

export const clearSearchInput = (
  dispatch: any,
  actions: any,
  feature_id: number,
) => {
  dispatch(actions.setSearchInput({ input: '', feature_id: feature_id, type: 'list' }))
}

// 2. Filters
export const setFilterValue = (
  dispatch: any,
  actions: any,
  field_name: string,
  field_type: string,
  filter_value: any,
  fitlerItemId: number,
  logic?: any,
) => {
  dispatch(actions.setListFilter({
    field_name, field_type, filter_value, fitlerItemId, logic
  }))
}

export const getFilterAttribute = (
  field_name: string,
  filter_choices: any,
  fitlerItemId: number,
  filterAttribute: string,
) => {
  const filterObject = filter_choices.find((item: any) => item.field_name === field_name)

  if (filterObject === undefined) {
    return ''
  } else {
    const filterItem = filterObject.filterItems.find((item: any) => item.id === fitlerItemId)
    if (filterItem === undefined) {
      return ''
    } else {
      return filterItem[filterAttribute]
    }
  }
}

export const setFilterLogic = (
  dispatch: any,
  actions: any,
  field_name: string,
  field_type: string,
  logicOption: any,
  logicId: number) => {
  dispatch(actions.listAddFilterLogic({ field_name, field_type, logicOption, logicId }))
}

export const getFilterLogic = (field_name: string, filter_choices: any, attribute: string) => {
  const fieldstate = filter_choices.find((item: any) => item.field_name === field_name)
  if (fieldstate === undefined) {
    return ''
  } else {
    return fieldstate[attribute]
  }
}



export const HandleFilters = (list_data: any, list_state: any) => {

  // 1. Loop each row from the database
  //  2. Loop each field from the row
  //    3. Loop each filters and apply to that field 
  //    4. If a single filter apply equals to logic
  //    5. If multiple filters, apply OR logic
  //  6. Return True or False for the field
  // 7. Return True or False for the row 

  const filtered_data = useMemo(() => {
    // console.log('filtered_data- list_data:', list_data)
    // 1. Loop each row from the database
    return list_data
      .filter((databaseRow: any) => {
        // console.log('L1 - Database Row', databaseRow)

        //  2. Loop each field from the row
        const filterRowResults = Object.entries(databaseRow)
          .map(([dbFieldName, dbValue]: any) => {
            // console.log('L2 - Each Field', dbFieldName, dbValue)

            // 3. Loop each filters and apply to that field
            const filterFieldResults = list_state.filter_choices
              .map((filterObject: any) => {
                // console.log('L3a - Each Field and apply filter', dbFieldName, dbValue)
                if (filterObject.field_name == dbFieldName) {

                  if (filterObject.field_type === 'text') {
                    // console.log('filterObject', filterObject.filterItems)
                    const filterValueLC = filterObject.filterItems[0].value.toLowerCase().trim()
                    const dbValueLC = dbValue.toLowerCase().trim()
                    return dbValueLC.includes(filterValueLC)
                  }
                  // if (filterObject.field_type === 'checkbox') { return dbValue == filterObject.filterValue }
                  // if (filter.field_type === 'date') { return useDateFilter(filter, dbValue) }
                  if (filterObject.field_type === 'number') { return handleNumberFilter(filterObject, dbValue) }
                  // if (filter.field_type === 'time') { return useTimeFilter(filter, dbValue) }
                  if (filterObject.field_type == 'select') {
                    const selectFilterValues = filterObject.filterItems[0].value.map((singleFilterValue: any) => {
                      if (singleFilterValue === '0' && dbValue === null) {
                        return true
                      }
                      else {

                        if (filterObject.field_name == dbFieldName && singleFilterValue == dbValue) {
                          return true
                        } else {
                          return false
                        }
                      }
                    })
                    return selectFilterValues.some((item: any) => item === true);
                  }
                } else {
                  return true
                }
              })

            // Result for: 3. Loop each filters and apply to that field
            return filterFieldResults.every((bool: boolean) => bool);

          })
        // Result for: 2. Loop each field from the row
        return filterRowResults.every((bool: boolean) => bool);
      })
  }, [list_data, list_state.filter_choices, list_state.searchInput])

  return filtered_data
}

// 3. Sort

// export const defaultSort = (dispatch: any, actions: any, list_data: any, sortField: string, sortOrder: string, feature_id: number) => {
//   handleSortData(
//     dispatch,
//     actions,
//     list_data,
//     sortField,
//     sortOrder,
//     feature_id);
// }


export const HandleSortGroupButtons = (props: any) => {
  console.log('HandleSortGroupButtons:', props)
  const currentSortOrder = props.list_state.sort_choices.find((item: any) => item.sortField === props.field_name)
  console.log('currentSortOrder', currentSortOrder)
  const handleSortingChange = () => {
    const sortOrder = !currentSortOrder ? 'asc' :
      currentSortOrder.sortOrder === 'asc' ? 'desc' : 'asc';
    handleSingleSortValue(
      props.dispatch,
      props.actions,
      props.list_data,
      props.field_name,
      sortOrder,
      props.feature_id);
  };

  // const longFieldName = (field_name: string) => {
  //   console.log('props', props)
  //   const moduleFields = props.moduleFields.find((item: any) => item.field_name === field_name)
  //   const fieldLongName = moduleFields.fieldLongName
  //   if (fieldLongName) {
  //     return fieldLongName
  //   } else {
  //     return field_name
  //   }
  // }
  if (props.mode === 'group') {
    return (
      <a
        href="#"
        onClick={() => {
          handleSortingChange();
          props.setShowSortByOptions(false)
          props.dispatch(props.actions.changeListGroup({
            field_name: props.field_name,
            feature_id: props.feature_id
          }))
        }}
      >
        {props.sort_name === undefined ? 'Group' : props.sort_name}
      </a>
    )
  } else {

    return (
      <a
        href="#"
        onClick={() => {
          handleSortingChange();
          props.setShowSortByOptions(false)
        }}
      >
        {props.sort_name === undefined ? 'Sort' : props.sort_name}
      </a>
    )
  }
}

// 4. Groups

const rowHeadingName = (field: any) => {
  return (<td
    // colspan={4}
    style={{ borderLeft: '0px', borderRight: '0px' }}>
    <h3 style={{ marginBottom: '0px' }}>{field} </h3>
  </td>)
}


export const getGroupByRowHeadingName = (groupBy: any, dataItem: any, index: any, array: any) => {
  // console.log('getGroupName', group, data, index, array)
  // const moduleFields = props.moduleFields.find((item: any) => item.field_name === groupBy)
  // const fieldLongName = moduleFields.fieldLongName
  // if (fieldLongName) {
  //   // console.log('fieldLongName', fieldLongName)
  //   // console.log('fieldLongName for current row:', data[fieldLongName], 'fieldLongName for next row:', array[index + 1] ? array[index + 1][fieldLongName] : 'empty');
  //   // For the first heading 
  //   if (index === 0) {
  //     return rowHeadingName(data[fieldLongName])
  //   }
  //   //Each subsequent heading
  //   if (array[index - 1]) {
  //     if (data[fieldLongName] != array[index - 1][fieldLongName]) {
  //       return rowHeadingName(data[fieldLongName])
  //     }
  //   }
  // } else {
  // console.log('dataItem', dataItem)
  // console.log('groupBy', groupBy)
  // console.log('dataItem[groupBy]', dataItem[groupBy])
  // console.log('array, index', array.length, index + 1,)
  // console.log('array, index', array[index + 1][groupBy])
  // Look at the value of the current data item and compare it to the next item in the array. 
  const nextItem = (index = array.length ? 'stop' : array[index + 1][groupBy])

  // [groupBy] === undefined ? array[index + 1][groupBy] : '')
  if (dataItem[groupBy] != nextItem) {
    return rowHeadingName(dataItem[groupBy])
  }
  // }
}

// 5. Select Options
// This logic looks at the data returned and calculates the select options.
export const getSelectOptionsFromListData = (data: any, field_name: any, item: any) => {
  // console.log('data', data)
  // console.log('field_name', field_name)
  // console.log('item', item)
  const getuniquevalues: any[] = [];
  const selectOptions: any[] = [];

  if (data === undefined) {
    // console.log('data === undefined')
    return 'No data available'
    // Logic for static_list values, use field name only
  } else if (item && item.options_type) {
    if (item.options_type === 'static_list') {
      data.map((item: any) => {
        if (item[field_name] !== null) {
          if (getuniquevalues.indexOf(item[field_name]) === -1) {
            getuniquevalues.push(item[field_name])
            if (item[field_name] !== undefined) {
              selectOptions.push(item[field_name])
            }
          }
        }
      });
      // console.log(`static_list selectOptions for ${field_name}`, selectOptions)
      return selectOptions
    } else {
      const key_value_pair = item.key_value_pair
      console.log('key_value_pair', key_value_pair)
      // Default Logic for keyvalue pairs values
      data.map((item: any) => {
        console.log('item[key_value_pair]', item[key_value_pair])
        // if their is a key value pair
        if (item[key_value_pair] !== null) {
          // check to see if their is an exisiting keyvalue pair. 
          console.log('getuniquevalues - before:', getuniquevalues)
          const findExisitingkey_value_pair = getuniquevalues.find(value => value.id === item[key_value_pair].id)
          if (findExisitingkey_value_pair) {
            // If an exisiting value if found ignore
            return
          } else {
            // If no value is found add the key_value_pair to the unique list
            getuniquevalues.push(item[key_value_pair])
            if (item[key_value_pair] !== undefined) {
              // console.log('key_value_pair', key_value_pair)
              selectOptions.push({ id: item[key_value_pair].id, name: item[key_value_pair].name })
            }
          }
        }
      })
    };
    return selectOptions
  }
  // console.log(`database selectOptions for ${field_name}`, selectOptions)

}


export const filterSelectIsChecked = (filter_choices: any, field_name: any, selectValue: any) => {
  // console.log('filterSelectIsChecked - filter_choices:', filter_choices)
  // console.log('filterSelectIsChecked - field_name:', field_name)
  // console.log('filterSelectIsChecked - selectValue:', selectValue)
  // const currentFilterValues = filter_choices.map((filter:any ) => filter.filterValue)
  // if (currentFilterValues[0] == undefined) {
  //   return false
  // } else 
  // console.log('filter_choices', filter_choices)

  // Does a filterValue exist for this field?
  if (filter_choices.find((item: any) => item.field_name === field_name) !== undefined) {

    // If filter value exists then get the index of the filter field 
    const filterObjectIndex = filter_choices.findIndex((item: any) => item.field_name ===
      field_name)
    // console.log('filterSelectIsChecked - filterObjectIndex:', filterObjectIndex)
    // As select values are stored in an array, the item index will always be 0. 
    if (filter_choices[filterObjectIndex].filterItems[0].value.find((item: any) => item === selectValue)) {
      // console.log('filterSelectIsChecked - found in filter filter_choices')
      return true
    }
  } else {
    // console.log('filterSelectIsChecked - NOT found in filter filter_choices')
    return false
  }
}



const checkboxField = (props: any, value: any, dispatch: any) => {
  const field_config = props.column_definition
  const APIEndPoint = props.feature_definition.api_route
  const id = props.data_item.id

  // console.log('checkboxField', props)
  const getIndex = props.list_data.findIndex((item: any) => item.id === props.data_item.id)


  const handleOnChange = () => {
    dispatch(features_actions.changeCheckBox({
      feature_id: props.feature_state_id,
      value: !value,
      index: getIndex
    }))
    BasicPatchAPI(APIEndPoint + '/' + id, { 'check': !value })
    // .then(response => {
    // // if (response.APIResult === 'success') {
    // //   // setData(response.APIData)
    // // }
    // })
  }


  return (
    <input
      key={field_config.field.field_name}
      style={{ height: '20px' }}
      className='inputCheckBox'
      type="checkbox"
      // id={field_name}
      // value={value}
      checked={value}
      // onChange={e => setFieldValues(handleChange(field_name, e.target.value, field_values))}
      onChange={() => handleOnChange()}
    // onBlur={e => onBlur(field_name, e.target.value)}
    // autoComplete={field.autoComplete}
    // readOnly={field.readonly ? field.readonly : false}
    />
  )
}


// 6.1 Data Values - complex
export const itemValues = (props: any, user_settings: any, dispatch: any) => {
  // console.log('itemValues - props:', props, user_settings)
  const field_config = props.column_definition
  const value = props.data_item[field_config.field.field_name]
  // console.log('itemValues  value:', value, 'field_config', field_config)
  // console.log('itemValues  data_item:', props.data_item)
  switch (field_config.field.field_type.html_field_type) {

    case 'autocomplete':
      return keyValuePairValue(props.data_item, field_config)

    case 'boolean':
      return checkboxValue(value)

    case 'checkbox':
      if (field_config.table_clickable_checkbox) {
        return checkboxField(props, value, dispatch)
      } else {
        return checkboxValue(value)
      }

    case 'date':
      return dateValue(value, user_settings)

    case 'image':
      return ViewImageField(props)

    case 'select':
      switch (field_config.options_type) {
        case 'static_list':
          return value

        case 'dynamic_list':
          return keyValuePairValue(props.data_item, field_config)

        default:
          return value
      }

    case 'time':
      return timeFieldValue(value, user_settings)

    default:
      return value
  }
}

// 6.2 Data Values - basic
export const basicItemValues = (value: any, field_type: string, userSettings: any) => {

  switch (field_type) {

    case 'boolean':
      return checkboxValue(value)

    case 'checkbox':
      return checkboxValue(value)

    case 'date':
      return dateValue(value, userSettings)

    case 'time':
      return timeFieldValue(value, userSettings)

    default:
      return value
  }
}


export const handleItemClick = (dispatch: any, navigate: any, module_definition: any, feature_definition: any, item_id: number, appMode: string) => {
  // console.log('handleItemClick -module_definition:', module_definition)
  // console.log('handleItemClick -feature_definition:', feature_definition)
  // console.log('handleItemClick -item_id:', item_id)
  const list_config = feature_definition.list_config

  if (list_config.item_onclick_modal === true && appMode !== 'mobile') {
    dispatch(GeneralAPIs({
      method: 'get',
      end_point: feature_definition.api_route + '/' + item_id,
      actions: features_actions,
      feature_id: feature_definition.id,
      type: 'item'
    }))
    dispatch(features_actions.openItemModal({
      item_id: item_id,
      feature_id: feature_definition.id,
      view_action: list_config.item_onclick_action.split(':')[1]
    }))
  } else {
    const link = list_config.item_onclick_action ? '/' + list_config.item_onclick_action : ""
    if (list_config.item_onclick_custom_path) {
      navigate(
        '/' + list_config.item_onclick_custom_path +
        link + '?id=' + item_id.toString())
    } else {
      navigate(
        '/' + module_definition.frontend_link + '/' +
        feature_definition.frontend_link +
        link + '?id=' + item_id.toString())
    }
  }
}
