import { useState } from "react";
import BaseContainer from "../../components/containers/BaseContainer";

import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { Link } from "react-router-dom";
import classes from './Support.module.css'

export default function SupportFAQS() {

  const data = [{
    category: 'Subscription',
    question: 'What is the difference between a passage and a trip?',
    answer: "A trip could be a holiday, a weekend on a boat or a series of races. A passage as defined by the RYA is 'a non-stop voyage from a departure port / safe haven to a destination port / safe haven.' The app uses this passage information to track your progress towards gaining qualifications such as RYA Yachtermaster."
  }, {
    category: 'Subscription',
    question: 'What happens when I reach my tier limit?',
    answer: 'You can continue to access and use the App in read only mode until you upgrade to a higher tier. '
  }, {
    category: 'Subscription',
    question: 'What payment options do you accept?',
    answer: 'We only accept payments via Visa or MasterCard. '
  }, {
    category: 'Subscription',
    question: 'Can I get a refund if I cancel my subscription?',
    answer: 'No, we recommend you try the free tier out before you commit to a subscription.'
  }, {
    category: 'Subscription',
    question: 'How can I cancel my subscription.',
    answer: <p>You can cancel your subscription at anytime. Once you have signed in go to <Link to='/user/account'>your account</Link> and select cancel my subscription. You will be able to continue to be use the app until the remaning time left on the subscription expires, then you will be downgraded to the free tier. </p>
  }, {
    category: 'Subscription',
    question: 'When my subscription payments taken.',
    answer: 'Monthly payments are taken on the same day each month that you original subsribed. Annual payments are taken on the same day each year on the month that you subsribed'
  }, {
    category: 'Subscription',
    question: 'How are my subscription payments taken.',
    answer: 'When you subscribe you will grant us permission to set up a recurring payment. We will take the same payment each month from your debit or credit card until you cancel your subscription.'
  }, {
    category: 'Subscription',
    question: 'What happens if my card expires and my subscription lapses.',
    answer: 'If we are unable to take your subscription payment then your account will be downgraded to the free tier. You will still be able to access all your data as read only.'
  }, {
    category: 'Subscription',
    question: 'How long is my data kept for once my subscription has finisded?',
    answer: <div>
      <p>
        Paid for plans: Your data will be kept as long a you have an active susbricption. If you stop paying your subscription then you will revert to the free plan and you can continue to access the App.</p>
      <p>
        Free plan: If you do not sign into the app after 12 months we will notifiy you that your data will be deleted in 30 days. If you signin before the end of the 30 days your data will continue to be kept for another 12 months.
      </p>
    </div>
  }, {
    category: 'My Data',
    question: 'How can I get a copy of my data?',
    answer: 'You can export all your data in a csv format when logged in online.'
  }, {
    category: 'My Data',
    question: 'What happens with my data when I delete the App?',
    answer: 'If you delete the App your data will still be available online.'
  }, {
    category: 'My Data',
    question: 'I lost my phone overboard how do I recover my data?',
    answer: 'Your data will still be available online. Depending on when the phone last had internet connection, any data that has been entered on the phone and not yet synced to the cloud will be lost. '
  },
  ]


  const Structure = ({ question, answer }: any) => {
    const [toggle, setToggle] = useState(false);
    return (
      <div >
        <p className={classes.FAQS}
          onClick={(event: React.MouseEvent<HTMLElement>) => { setToggle(!toggle) }}>
          {toggle ? <MdExpandLess /> : <MdExpandMore />}
          {question}
        </p>
        {toggle &&
          <p className='marketing-FAQS-answer'>{answer}</p>
        }

        <hr className='marketing-FAQS-line'></hr>
      </div>)
  }

  return (
    <BaseContainer
      type='fixed'
      heading='Frequently Asked Questions'
      id='faqs'
      containerStyle={{ width: '97%' }}
      showBackButton
    >
      <div className='marketing-FAQS'>
        {data.map((item, index) =>
          <div key={index}>
            <Structure
              question={item.question}
              answer={item.answer}
            />
          </div>
        )}
      </div>
    </BaseContainer>
  )
}