import './ToggleSwitch.css';

const ToggleSwitch = ({ fieldName, isChecked, handleToggle }: any) => {
  return (
    <>
      <input
        checked={isChecked}
        onChange={handleToggle}
        className="toggleSwitchCheckbox"
        id={fieldName}
        type="checkbox"
      />
      <label
        style={{ background: isChecked && '#06D6A0' }}
        className="toggleSwitchLabel"
        htmlFor={fieldName}
      >
        <span
          className={'toggleSwitchText'}
          style={{
            display: isChecked && 'block',
            marginLeft: '10px'
          }}

        > </span>
        <span
          className={'toggleSwitchText'}
          style={{
            // display: !isChecked && 'block',
            marginLeft: '28px'
          }}

        > Off </span>
        <span className={`toggleSwitchButton`} />
      </label>
    </>
  );
};
export default ToggleSwitch;