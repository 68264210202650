import { PayloadAction, createSlice } from '@reduxjs/toolkit'

// interface sideBarHeadingState {
//   heading: string,
//   expanded: boolean
// }

interface initialUiState {
  sideBarMenu: boolean
  sideBarHeading: any,
  appMode: string,
}

const initialUiState = {
  sideBarShow: false,
  sideBarExpand: true,
  sideBarOpenWidth: '200px',
  sideBarCollapsedWidth: '60px',
  sideBarHeading: [{ heading: 'Personal Log', expanded: true }],
  appMode: 'mobile'
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialUiState,
  reducers: {
    setShowSideBarMenu(state: any) {
      state.sideBarShow = true
    },
    toggleShowSideBarMenu(state: any) {
      state.sideBarShow = !state.sideBarShow
    },
    toggleExpandSideBarMenu(state: any) {
      state.sideBarExpand = !state.sideBarExpand
    },
    toggleSideBarSectionDropDown(state: any, action: PayloadAction<any>) {
      // console.log('action.payload', action.payload)
      state.sideBarHeading = [{ heading: action.payload.heading, expanded: action.payload.expanded }]
    },
    setAppMode(state: any, action: PayloadAction<any>) {
      state.appMode = action.payload
    }
  }
})

export const {
  setShowSideBarMenu,
  toggleShowSideBarMenu,
  toggleExpandSideBarMenu,
  toggleSideBarSectionDropDown,
  setAppMode } = uiSlice.actions
export const uiReducer = uiSlice.reducer

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

