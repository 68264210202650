import { getFieldAttributes } from '../../../components/fields/inputFields/InputFieldLogic'

export const passwordCheck = (field_values: any) => {
  // Signup
  const password = getFieldAttributes('password', field_values, 'field_value')

  // Signup and password reset
  const confirm_password = getFieldAttributes('password_confirm', field_values, 'field_value')

  // Password reset
  const new_password = getFieldAttributes('new_password', field_values, 'field_value')

  // console.log('passwordCheck - field_values', field_values)
  // console.log('passwordCheck', password, confirm_password)

  let password1 = ''
  let password2 = ''

  if (password) {
    password1 = password
    password2 = confirm_password
  } else {
    password1 = new_password
    password2 = confirm_password
  }
  if (password1 !== password2) {
    console.log('Passwords do not match')
    return { result: false, message: 'Passwords do not match' }
  } else {
    return { result: true }
  }
}