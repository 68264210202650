import { useEffect, useState } from 'react';
import tablestyle from '../../../components/lists/table/Table.module.css';
import HorizontalRadioSwitch from '../../../components/switches/HorizontalRadioSwitch'

const initialState = [
  {
    "tidal": false,
    "total_distance_sailed": 0,
    "total_night_hours": 0,
    "total_no_of_days": 0,
    "total_passages": 0
  }, {
    "tidal": true,
    "total_distance_sailed": 0,
    "total_night_hours": 0,
    "total_no_of_days": 0,
    "total_passages": 0
  }, {
    "tidal": "Total",
    "total_distance_sailed": 0,
    "total_night_hours": 0,
    "total_no_of_days": 0,
    "total_passages": 0
  }]

const tripTypes = [
  {
    id: 1,
    name: 'Yacht Sailing',
    default: true,
  }, {
    id: 2,
    name: 'Dinghy Sailing',
  }, {
    id: 3,
    name: 'Powerboat',
  }, {
    id: 4,
    name: 'Motorboat',
  },
]

const activityTypes = [
  {
    id: 1,
    name: 'All',
    default: true,
  }, {
    id: 2,
    name: 'Cruising',
  }, {
    id: 3,
    name: 'Racing',
  },
]

const roles = [
  {
    id: 1,
    name: 'All',
    default: true,
  }, {
    id: 2,
    name: 'Skipper',
  }, {
    id: 3,
    name: 'Crew',
  },
]



const PLSailingCV = (props: any) => {
  const data = props.data ? props.data : initialState
  // const [data, setData] = useState<any>(initialState)
  const [tripType, setTripType] = useState('Yacht Sailing')
  const [activityType, setActivityType] = useState('All')
  const [role, setRole] = useState('All')

  // const getData = async () => {
  //   // if (props.dataItem.id) {
  //   await generalAPI('get', '/personallog/dashboard/sailingcv/100')
  //     .then(response => {
  //       if (response.APIResult === 'success') {
  //         // console.log('response.generalAPI', response.APIDataFromDB)
  //         setData(response.APIDataFromDB)
  //       } else {
  //         console.log('error', response.APIMessage.message)
  //         // setMessageType('error')
  //         // setMessage(response.APIMessage.message)
  //       }
  //     })
  //     .catch(error => {
  //       console.log('error.generalAPI', error.APIDataFromDB)
  //       // setLoading(false)
  //       // setMessageType('error')
  //       // setMessage(error.response.data.error ? error.response.data.error : 'An unknown error has occured')
  //     })
  //   // }
  // }
  // // console.log(data && data.length)

  // useEffect(() => { getData() }, [])

  const sumUp = (array: any, attribute: string) => {
    // console.log('array', array)
    return array.reduce((accumulator: any, object: any) => {
      return accumulator + parseInt(object[attribute]);
    }, 0)

  }

  // console.log('data', data.filter((item: any) => item.tidal === false));

  const optionslogic = (data: any) => {
    // console.log('optionslogic Data:', data)
    const tripLogic = data.name === tripType
    const racingLogic = data.racing === (activityType === 'Racing' ? true : false)
    const rolelogic = (role === 'Skipper' ? data.pl_role_id === 10 : data.pl_role_id !== 10)

    if (activityType === 'Cruising' || activityType === 'Racing') {
      if (role === 'Skipper' || role === 'Crew') {
        return tripLogic && racingLogic && rolelogic
      } else {
        return tripLogic && racingLogic
      }
    } else if (role === 'Skipper' || role === 'Crew') {
      return tripLogic && rolelogic
    } else {
      return tripLogic
    }
  }

  return (<>
    <h3>Sailing CV </h3>
    <HorizontalRadioSwitch
      switchValues={tripTypes}
      selected={tripType}
      onChange={setTripType}
    />
    <br></br>

    <HorizontalRadioSwitch
      switchValues={activityTypes}
      selected={activityType}
      onChange={setActivityType}
    />
    <span>          </span>
    <HorizontalRadioSwitch
      switchValues={roles}
      selected={role}
      onChange={setRole}
    />


    <table className={tablestyle.table}>
      <thead>
        <tr>
          <th className={tablestyle.notClickableHeader}>

          </th>
          <th className={tablestyle.notClickableHeader} style={{ width: "20%", textAlign: "center" }}>
            Tidal
          </th>
          <th className={tablestyle.notClickableHeader} style={{ width: "20%", textAlign: "center" }}>
            Not Tidal
          </th>
          <th className={tablestyle.notClickableHeader} style={{ width: "20%", textAlign: "center" }}>
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Miles
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)).filter((item: any) => item.tidal === true), 'total_distance_sailed')}
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)).filter((item: any) => item.tidal === false), 'total_distance_sailed')}
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)), 'total_distance_sailed')}
          </td>
        </tr>
        <tr>
          <td>
            Days
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)).filter((item: any) => item.tidal === true), 'total_no_of_days')}
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)).filter((item: any) => item.tidal === false), 'total_no_of_days')}
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)), 'total_no_of_days')}
          </td>
        </tr>
        <tr>
          <td>
            Passages
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)).filter((item: any) => item.tidal === true), 'total_passages')}
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)).filter((item: any) => item.tidal === false), 'total_passages')}
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)), 'total_passages')}
          </td>
        </tr>
        <tr>
          <td>
            Night Hours
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)).filter((item: any) => item.tidal === true), 'total_night_hours')}
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)).filter((item: any) => item.tidal === false), 'total_night_hours')}
          </td>
          <td style={{ textAlign: "right" }}>
            {sumUp(data.filter((fields: any) => optionslogic(fields)), 'total_night_hours')}
          </td>
        </tr>
      </tbody>
    </table >
  </>
  )
}
export default PLSailingCV;