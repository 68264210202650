import { useLocation, useNavigate } from "react-router-dom";
import UserAuthView from "../../../components/views/UserAuthView";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks/useRedux";
import BasicGetAPI from "../../../api/BasicGetAPI";

export default function TermsndConditions() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [tandcs, setTandCs] = useState<any>();

  // Get email address used at sign in and temp signin token
  if (!location.state) {
    navigate("/user/signin");
  } else {
    var email = location.state.email;
    var temp_token = location.state.temp_token;
  }
  // console.log('location.state', location.state)
  //  console.log('email', email)

  useEffect(() => {
    const getData = async () => {
      await BasicGetAPI("user/tandcs", "public").then((response) => {
        if (response.APIResult === "success") {
          setTandCs(response.APIData);
        }
      });
    };
    getData();
  }, []);

  // console.log('tandcs.content', tandcs)
  const data = tandcs && tandcs.content ? JSON.parse(tandcs.content) : [];

  const header = () => {
    let listofTandCs = data.map((element: any, index: any) => (
      <div key={index}>
        <h3>{element.heading}</h3>
        {element.sections.map((section: any, index: any) => (
          <p key={index}>
            <b>{section.section_name}</b>
            {section.contents}
          </p>
        ))}
      </div>
    ));

    return <div>{listofTandCs}</div>;
  };

  const postSubmitActions = (props: any) => {
    if (props.item_state.next_action === "setup_mfa") {
      navigate("/user/mfa", { state: { data: props.item_state.data } });
    }
  };

  console.log("version", tandcs ? tandcs.version_no : 0);
  return (
    <UserAuthView
      feature_id={13}
      data_to_send={{
        email: email,
        version: tandcs ? tandcs.version_no : 0,
        token: temp_token,
      }}
      width={"600px"}
      header={header}
      hideFormAfterSuccessfulSubmitted
      postSubmitActions={postSubmitActions}
    />
  );
}
