import { PayloadAction } from '@reduxjs/toolkit'
import { error } from 'console'
import { itemInitialState } from '../reducers/ItemReducer'
import { apiInitialState } from '../reducers/APIReducer'

export const featureStateType = (state: any, feature_id: number, type: string) => {
  // console.log('featureStateType - feature_id:', feature_id, 'type:', type)
  if (type === 'definition') return state[feature_id].definition
  if (type === 'list') return state[feature_id].list
  if (type === 'item') return state[feature_id].item
  if (type === 'selectOptions') return state[feature_id].selectOptions
}
// Contents Order
// Feature
// Lists
// Items 

////////////////////Feature/////////////////////////////////

const setFeatureAttributes = (state: any, action: PayloadAction<any>) => {
  // console.log('setFeatureAttributes:', action.payload)
  const feature_id = action.payload.feature_id
  const item_id = action.payload.item_id
  if (item_id) {
    state[feature_id].item.item_id = item_id
  }

  state[feature_id].link = action.payload.link
  const feature_view = action.payload.view  // list:table, list:grid, item:view, item:add, 

  if (feature_view) {
    const view = feature_view.split(":")
    const view_type = view[0]     //list, item
    const view_action = view[1]   //view, add , edit   || table, grid  
    // console.log('type:', view_type, 'action:', view_action)
    if (view_type === 'item') {
      state[feature_id].view = view_type
      if (view_action == 'view' || view_action == 'edit') {
        state[feature_id].item.view_action = view_action
      } else if (view_action === undefined || view_action == 'add') {
        state[feature_id].item.view_action = 'add'
      }
    } else { // else list
      state[feature_id].view = view_type
      if (view_action === undefined) {
        state[feature_id].list.view_choice = 'table'
      } else {
        state[feature_id].list.view_choice = view_action
      }
    }
  }
}

////////////////////Lists/////////////////////////////////
////////////////////Lists/////////////////////////////////

const setListView = (state: any, action: PayloadAction<any>) => {
  // console.log('setListView:', action.payload)
  const feature_id = action.payload.feature_id
  state[feature_id].list.view_choice = action.payload.view
}
const addItemToList = (state: any, action: PayloadAction<any>) => {
  // console.log('addItemToList:', action.payload)
  const feature_id = action.payload.feature_id
  state[feature_id].list.data.push({ ...action.payload.item })
}

// const replaceItemInList = (state: any, action: PayloadAction<any>) => {
//   console.log('replaceItemInList:', action.payload)
//   const item = action.payload.item
//   const feature_id = action.payload.feature_id
//   const target = state[feature_id].list.data.find((obj: any) => obj.id === item.id);
//   Object.assign(target, state[feature_id].list.data)
//   // state[feature_id].list.data.push({ ...action.payload.item })
// }



const setSearchInput = (state: any, action: PayloadAction<any>) => {
  // console.log('setSearchInput:', action.payload)
  const feature_id = action.payload.feature_id
  const type = action.payload.type
  eval(featureStateType(state, feature_id, type)).search_input = action.payload.input
}

// const clearSearchInput = (state: any, action: PayloadAction<any>) => {
//   const feature_id = action.payload.feature_id
//   const type = action.payload.type
//   eval(featureStateType(state, feature_id, type)).search_input = ''
// }

const setListFilter = (state: any, action: PayloadAction<any>) => {
  // console.log('setListFilter', action.payload)
  const newItem = action.payload
  const feature_id = action.payload.feature_id
  const existingObject = state[feature_id].list.filter_choices.find((item: any) => item.field_name === newItem.field_name)
  // console.log('existingObject', existingObject)
  // If filter value exists, then replace filtervalues
  if (existingObject) {
    // 1. Get the index of the exisiting filter object.
    const filterObjectIndex = state[feature_id].list.filter_choices.findIndex((item: any) => item.field_name === newItem.field_name)
    // 2. Get the index of the filter item 
    const filterItemIndex = state[feature_id].list.filter_choices[filterObjectIndex].filterItems.findIndex((item: any) => item.id === newItem.fitlerItemId)
    // console.log('filterItemIndex', filterItemIndex)
    // 3. Check if exisiting object has an exisiting item
    if (filterItemIndex >= 0) {
      // console.log('exisiting object, exisiting item', filterItemIndex)
      const exisitingItem = state[feature_id].list.filter_choices[filterObjectIndex].filterItems[filterItemIndex]

      // If the items is a select item, then the filter values are an array
      if (newItem.field_type === 'select') {
        const newSelectItem = action.payload.filter_value
        const currentArray = state[feature_id].list.filter_choices[filterObjectIndex].filterItems[filterItemIndex].value
        // console.log('newSelectItem', newSelectItem)
        // console.log('currentArray', currentArray);
        // If this select value already exists, then delete it from the array                        
        if (currentArray.find((item: any) => item === newSelectItem)) {
          const selectValueIndex = currentArray.findIndex((item: any) => item === newSelectItem)
          // If only one value in the current Array, then as its the last item the whole array can be deleted. 
          if (currentArray.length === 1) {
            // console.log('filter - select values - all deleted')
            const newData = [...state[feature_id].list.filter_choices] // new array
            newData.splice(selectValueIndex, 1)
            state[feature_id].list.filter_choices = newData
          } else {
            // if multiple filter values then just remove the single value from the filter values array
            // console.log('filter - select values - remove single')
            const newData = [...state[feature_id].list.filter_choices] // new array
            currentArray.splice(selectValueIndex, 1); // remove old object
            state[feature_id].list.filter_choices = newData
          }
        } else {
          // if the filter object exists and the filter item exists but the value is not in the array, then add the value to the select values array
          // console.log('filter - select values - add single value')
          const newData = [...state[feature_id].list.filter_choices] // new array
          newData[filterObjectIndex].filterItems[filterItemIndex].value.push(...[newSelectItem])
          state[feature_id].list.filter_choices = newData
        }
      } else {
        // 3. Update the filter item (for text and numbers)
        const replaceExisitingFilterItem = {
          id: newItem.fitlerItemId,
          value: newItem.filter_value,
          logic: newItem.logic === undefined ? exisitingItem.logic : newItem.logic,
        }
        const newData = [...state[feature_id].list.filter_choices] // new array
        newData[filterObjectIndex].filterItems.splice(filterItemIndex, 1); // remove old filter item
        newData[filterObjectIndex].filterItems.push(...[replaceExisitingFilterItem]) // add new filter item
        state[feature_id].list.filter_choices = newData
      }
    } else { // If exisiting object but new item 
      // console.log('exisiting object, but new item', filterItemIndex)
      const newFilterItem = {
        id: newItem.fitlerItemId,
        value: newItem.field_type === 'select' ? [newItem.filter_value] : newItem.filter_value,
        logic: newItem.logic ? newItem.logic : 'equalToLessThan'
      }
      const newData = [...state[feature_id].list.filter_choices] // new array
      newData[filterObjectIndex].filterItems.push(...[newFilterItem]) // add new filter item
      state[feature_id].list.filter_choices = newData
    }
  } else { // otherwise create new filter Object
    // console.log('create new filter Object')
    const newFilterItem = {
      id: newItem.fitlerItemId,
      value: newItem.field_type === 'select' ? [newItem.filter_value] : newItem.filter_value,
      logic: newItem.logic ? newItem.logic : 'includes'
    }
    const newFilterObject = {
      field_name: newItem.field_name,
      field_type: newItem.field_type,
      filterItems: [newFilterItem]
    }
    state[feature_id].list.filter_choices.push({
      ...newFilterObject
    })
  }
}

const removeSingleListFilter = (state: any, action: PayloadAction<any>) => {
  const field_name = action.payload.field_name
  const feature_id = action.payload.feature_id
  state[feature_id].list.filter_choices = state[feature_id].list.filter_choices.filter((item: any) => item.field_name !== field_name)
}

const clearAllListFilters = (state: any, action: PayloadAction<any>) => {
  // console.log('clearAllListFilters', action.payload)
  const feature_id = action.payload
  state[feature_id].list.filter_choices = []
}

const setListData = (state: any, action: PayloadAction<any>) => {
  // console.log('setSortedData', action)
  const feature_id = action.payload.feature_id
  state[feature_id].list.data = action.payload.sortedData
  // setListSortOrder(state, action)
}

const setListSortOrder = (state: any, action: PayloadAction<any>) => {
  // console.log('setListSortOrder', action)
  const feature_id = action.payload.feature_id
  const newItem = action.payload
  const existingItemIndex = state[feature_id].list.sort_choices.findIndex((item: any) => item.sortField === newItem.sortField)
  // console.log('existingItemIndex', existingItemIndex)
  const newSortItem = {
    sortField: newItem.sortField,
    sortOrder: newItem.sortOrder
  }
  if (existingItemIndex >= 0) {
    // console.log('existingItem: true')
    const newData = [...state[feature_id].list.sort_choices] // new array
    newData.splice(existingItemIndex, 1); // remove old sort item
    newData.push(...[newSortItem]) // add new sort item
    state[feature_id].list.sort_choices = newData
  } else {
    // console.log('existingItem: false')
    state[feature_id].list.sort_choices.push({ ...newSortItem })
  }
}

const changeListGroup = (state: any, action: PayloadAction<any>) => {
  // console.log('changeListGroup -action.payload:', action.payload)
  const feature_id = action.payload.feature_id
  state[feature_id].list.group_choices = action.payload.field_name
}

const changeCheckBox = (state: any, action: PayloadAction<any>) => {
  const feature_id = action.payload.feature_id
  const index = action.payload.index
  console.log('changeCheckBox -action.payload:', action.payload)
  state[feature_id].list.data[index].check = action.payload.value
}

////////////////////Items/////////////////////////////////
////////////////////Items/////////////////////////////////
////////////////////Items/////////////////////////////////

const setItemId = (state: any, action: PayloadAction<any>) => {
  // console.log('setItemId:', action.payload)
  const feature_id = action.payload.feature_id
  state[feature_id].item.item_id = action.payload.item_id
}

const openItemModal = (state: any, action: PayloadAction<any>) => {
  // console.log('openItemModal:', action.payload)
  const feature_id = action.payload.feature_id
  const view_action = action.payload.view_action
  state[feature_id].item.view_action = view_action
  state[feature_id].item.item_id = action.payload.item_id
  state[feature_id].item.show_modal = true
}

const closeItemModal = (state: any, action: PayloadAction<any>) => {
  // console.log('closeItemModal:', action.payload)
  const feature_id = action.payload
  // state[feature_id].item.show_modal = false
  state[feature_id].item = {
    ...itemInitialState({
      default_view_action: 'view',
      show_modal: false
    })
  }
}

const resetItem = (state: any, action: PayloadAction<any>) => {
  // console.log('closeItemModal:', action.payload)
  const feature_id = action.payload
  // state[feature_id].item.show_modal = false
  state[feature_id].item = {
    ...itemInitialState({
      default_view_action: 'view',
      show_modal: false
    })
  }
}

const saveFormState = (state: any, action: PayloadAction<any>) => {
  // console.log('saveFormState:', action.payload)
  const feature_id = action.payload.feature_id
  state[feature_id].item.form_state.field_values = action.payload.field_values
  state[feature_id].item.form_state.button_action = action.payload.button_action
}

const resetStateAddNewItem = (state: any, action: PayloadAction<any>) => {
  // console.log('resetStateAddNewItem:', action.payload)
  const feature_id = action.payload.feature_id
  state[feature_id].item = {
    ...itemInitialState({
      default_view_action: 'add',
      show_modal: action.payload.show_modal,
      form_message: action.payload.message,
      form_message_type: action.payload.message_type,

    })
  }
}

// const setFormState = (state: any, action: PayloadAction<any>) => {
//   const feature_id = action.payload.feature_id

//   state[feature_id].item.form_state.status = action.payload.status
//   state[feature_id].item.form_state.field_values = action.payload.field_values
//   state[feature_id].item.api_messages[0].message = action.payload.message
//   state[feature_id].item.api_messages[0].message_type = action.payload.message_type

// }



// const changeField = (state: any, action: PayloadAction<any>) => {
//   console.log('changeField -action.payload:', action.payload)
//   const feature_id = action.payload.feature_id
//   const field_name = action.payload.field_name

//   state[feature_id].item.data[field_name] = action.payload.field_value
// }

// const saveFieldValues = (state: any, action: PayloadAction<any>) => {
//   console.log('saveFieldValues -action.payload:', action.payload)
//   const field_name = action.payload.field_name
//   const feature_id = action.payload.feature_id

//   // set new field value
//   // state[feature_id].item.data[field_name] = action.payload.field_value

//   // set field status
//   // const field_status_index = action.payload.field_status_index
//   const error = action.payload.error

//   const new_field_status = {
//     field_name: field_name,
//     field_type: ,
//     touched: true,
//     has_error: error.has_error,
//     error_messages: error.error_messages_array
//   }

//   // console.log('changeFieldOnBlur -new_field_status:', new_field_status)
//   state[feature_id].item.form_state = new_field_status
// }


export const featureReducers = {
  //  Features
  setFeatureAttributes,

  // Lists
  addItemToList,
  // replaceItemInList,

  setListView,
  setSearchInput,
  // clearSearchInput,
  setListFilter,
  removeSingleListFilter,
  clearAllListFilters,
  setListData,
  setListSortOrder,
  changeListGroup,
  changeCheckBox,

  // Items
  setItemId,
  resetItem,
  openItemModal,
  closeItemModal,
  saveFormState,
  resetStateAddNewItem,
  // resetItemState,

  // changeField,
  // changeFieldOnBlur,
}
