import BaseContainer from "../../components/containers/BaseContainer";
import { Link } from 'react-router-dom';
import { MdQuestionMark, MdMenuBook, MdOutlineSupportAgent } from 'react-icons/md';
import CardItem from "../../components/cards/CardItem";
import classes from './Support.module.css'

export default function SupportHome() {

  const supportoptions = [
    {
      id: 1,
      title: 'F&Qs',
      icon: <MdQuestionMark />,
      link: '/support/faqs/',
    },
    {
      id: 2,
      title: 'How to guides',
      icon: <MdMenuBook />,
      link: '/support/howtoguides/',
    },
    {
      id: 3,
      title: 'Rasie a ticket',
      icon: <MdOutlineSupportAgent />,
      link: '/support/tickets/',
    }
  ]

  return (
    <BaseContainer
      // type='fixed'
      heading='Help & Support'
      showBackButton
    >

      {/* <div className={classes.sectionA}>
        <div className={classes.ContainerColumnsFour}>
          {supportoptions.map((option: any) => { return (CardItem(option)) })}
        </div>

      </div> */}

      <div className={classes.gridContainer}>
        {supportoptions.map((option: any) => { return (CardItem(option)) })}
      </div>

    </BaseContainer>
  )
}
