import { useEffect, useState } from "react";
import QRCode from "qrcode";

export const MFASetup = (props: any) => {
  const [img, setImg] = useState<any>(null);
  const [showKey, setShowKey] = useState(false);

  const otpauth_url = props.otpauth_url;
  // console.log("otpauth_url", otpauth_url);

  useEffect(() => {
    QRCode.toDataURL(otpauth_url, (err: any, imgdata: any) => {
      setImg(<img src={imgdata} style={{ width: "50%", maxWidth: "200px" }} />);
    });
  }, []);

  return (
    <div>
      <p style={{ textAlign: "center" }}>
        Please use an authentictor app such as Google Authenticator to scan this
        QR code.
      </p>
      <div style={{ textAlign: "center" }}>{img}</div>
      {showKey === false ? (
        <p style={{ textAlign: "center" }}>
          Not able to scan this code?
          <span
            onClick={() => {
              setShowKey(true);
            }}
            className="clickableText"
          >
            {" "}
            Show Key
          </span>
        </p>
      ) : (
        <>
          <p
            onClick={() => {
              setShowKey(false);
            }}
            style={{ textAlign: "center" }}
          >
            <span className="clickableText"> Hide Key</span>
          </p>
          <p style={{ textAlign: "center" }}> {props.secret}</p>
        </>
      )}
    </div>
  );
};
