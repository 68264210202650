import UserAuthView from "../../../components/views/UserAuthView";
import { passwordCheck } from "../auth/AuthLogic";

const preSubmitActions = (field_values: any) => {
  return passwordCheck(field_values)
}

export default function ChangePassword(props: any) {
  return (
    <UserAuthView
      feature_id={9}
      // showBackButton
      modalClose={props.modalClose}
      centerScreen

      preSubmitActions={preSubmitActions}
    />
  )
}