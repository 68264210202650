import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux'
import { GeneralAPIs } from '../../api/GeneralAPIs'
import { features_actions } from '../../state/slices/FeatureSlice'
import ViewSelector from './ViewSelector'
import Spinner from '../loaders/spinner/SpinnerFullScreen'
import { displayMessage } from '../messages/UserMessages'

export default function FeaturesView(props: any) {
  const dispatch = useAppDispatch()
  // console.log('Element 1A. Features View - get data:', props)
  // 0. Props are used when a feature is opened as a modal. This is used by Autocomplete and select fields when a value needs to be added or updated.
  const link_parts = props.options_frontend_edit_link ? props.options_frontend_edit_link.split('/') : ''

  // 1. Get url params and query params 
  const url_params = useParams()
  const module_name = props.options_frontend_edit_link ? link_parts[0] : url_params.module     // Examples: personallog, knowledgebase
  const feature_name = props.options_frontend_edit_link ? link_parts[1] : url_params.feature || '' // Examples: contacts, boats
  const feature_view = props.feature_view ? props.feature_view : url_params.view          // Options: list:table, list:grid, item:view, item:add, item:edit
  const query_parms: any = new URLSearchParams(window.location.search) // ?####&###
  const item_id = query_parms.get('id')                            // id=1
  // const feature_modal = (query_parms.get('modal') || props.modal)         // modal=true
  // console.log('E1.Step 1 - module:', module_name, ' feature:', feature_name, ' feature_view:', feature_view, ' itemId:', item_id,)
  // console.log('query_parms', item_id, query_parms.keys())
  // for (var [key, value] of query_parms.entries()) {
  //   console.log(key + '=' + value);
  // }

  // 2. Get module from url params 
  const modules = useAppSelector((state) => state.allModules.data)
  const module_definition = modules.find((item: any) => item.frontend_link === module_name)
  const module_index = modules.findIndex((item: any) => item.frontend_link === module_name)
  // console.log('E1.Step 2 - module:', module, 'module_index:', module_index)

  // 3. Get feature definitions from backend. 
  useEffect(() => {
    // console.log('E1.Step 3 - UseEffect ')
    if (module_index >= 0) {
      const feature = module_definition.feature.find((item: any) => item.frontend_link === feature_name)
      // console.log('step 3 - Get feature definition', feature)
      dispatch(GeneralAPIs({
        method: 'get',
        end_point: 'system/feature/definition/' + feature.id,
        actions: features_actions,
        feature_id: feature.id,
        type: 'definition'
      }))
    }
  }, [module_index, feature_name])

  const list_views = (feature_view === undefined || feature_view === 'list:table' || feature_view === 'list:grid')
  const item_views = (feature_view === 'item:view' || feature_view === 'item:add' || feature_view === 'item:edit')

  // 4.Get item /list data from backend
  useEffect(() => {
    // console.log('E1.Step 4 - UseEffect ')
    if (module_index >= 0) {
      const feature = module_definition.feature.find((item: any) => item.frontend_link === feature_name)

      dispatch(features_actions.setFeatureAttributes({
        feature_id: feature.id,
        view: feature_view === undefined ? 'list:table' : feature_view,
        link: module_name + '/' + feature_name,
        item_id: item_id,
      }))
      if (item_id) dispatch(features_actions.setItemId({ item_id: item_id, feature_id: feature.id }))

      if (list_views) {
        // console.log('step 4.1 - Get list data :', feature, 'feature_view:', feature_view,)
        dispatch(GeneralAPIs({
          method: 'get',
          end_point: item_id ? (feature.api_route + '/list/' + item_id) : feature.api_route + '/list',
          actions: features_actions,
          feature_id: feature.id,
          type: 'list'
        }))
      } else if (item_views && item_id) {
        // console.log('step 4.2 - Get Item data:', 'item_id', item_id)
        dispatch(GeneralAPIs({
          method: 'get',
          end_point: feature.api_route + '/' + item_id,
          actions: features_actions,
          feature_id: feature.id,
          type: 'item'
        }))
      }
    }
  }, [module_index, feature_name, feature_view])

  // 5. Get the Feature state (Definition and list/item data )
  const feature_id = (module_definition: any, feature_name: string) => {
    // console.log('feature_id:', module_definition, feature_name)
    if (module_definition && feature_name) {
      const feature = module_definition.feature.find((item: any) => item.frontend_link === feature_name)
      // console.log('feature:', feature)
      return feature.id
    } else return 0
  }

  const feature_state = useAppSelector((state) => state.features[feature_id(module_definition, feature_name)])
  // console.log('feature_state', feature_state)

  return (<>
    {(feature_state.feature_id != 0 && feature_state.definition.api_status === 'loading') && <Spinner />}

    {(feature_state.feature_id != 0 && feature_state.definition.api_status === 'success') &&
      <ViewSelector
        {...props}
        module_definition={module_definition}
        feature_state_id={feature_state.feature_id}
        params_item_id={item_id}
        query_parms={query_parms}
      // searchInput={searchInput}
      // setSearchInput={setSearchInput}

      //Fields used by module modal
      // module_modal={props.module_modal}
      // module_modal_hide={props.module_modal_hide}
      // input_value={props.input_value}
      />}
    {(feature_state.feature_id != 0 && feature_state.definition.api_status === 'error') && displayMessage([{ message_type: 'error', message: 'Unable to connect to Server, please contact customer support' }])}
  </>)
}