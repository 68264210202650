import UserAuthView from '../../../components/views/UserAuthView'

export default function PasswordForgot() {

  const header = (props: any) => {
    if (props.api_status === 'success') {
      return (
        <div style={{ textAlign: 'center' }}>
          <p>If you entered a valid email address, a reset link will be sent to that email address.</p>
          {/* <p className="linkText" ><Link to="/user/signin"> Sign In</Link></p> */}
        </div>
      )
    } else if (props.api_status === 'error') {
      return (<>
        <p>An error has occured, please contact customer support. </p>
      </>)
    } else {
      return (
        <p
          style={{ textAlign: 'center' }}>
          Enter your email address to reset your password. </p>
      )
    }
  }

  return (<>
    <UserAuthView
      feature_id={8}
      showBackButton
      centerScreen
      header={header}
      hideFormAfterSuccessfulSubmitted
    />
  </>)
}