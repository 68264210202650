// This image field is used in list and grid views
export default function ViewImageField(props: any, image_field?: any) {
  // console.log("DisplayImageField", props, image_field);
  // console.log('fields_config', props.fields_config)
  const media_server = process.env.REACT_APP_MEDIA_SERVER;
  const object_store = props.feature_definition.object_store;
  const data_item = props.data_item;

  // image_field comes from grid view, otherwise from list view
  const field_name = image_field
    ? image_field
    : props.column_definition.field.field_name;
  // console.log('field_name', field_name)
  const fields_config = props.fields_config.find(
    (item: any) => item.field.field_name === field_name
  );
  // console.log('fields_config', fields_config)
  const default_image =
    fields_config.default_image_name + "." + fields_config.default_image_type;
  // console.log('default_image', default_image)

  const imgSrc = () => {
    // console.log('data_item', data_item)
    //  used for user inputted images such as pl/ contacts and boats
    if (data_item.file_url) {
      if (data_item[field_name]) {
        return data_item[field_name].src;
      } else {
        return "/static/images/default/" + default_image;
      }
    } else {
      // Used for static images such as kb/ Alphabet
      if (data_item[field_name]) {
        return media_server + object_store + "/" + data_item[field_name];
      } else {
        return "/static/images/default/" + default_image;
      }
    }
  };

  const imgTitle = () => {
    if (data_item[field_name]) {
      return data_item[field_name];
    } else {
      return "Default Image";
    }
  };

  return (
    <img
      src={imgSrc()}
      title={imgTitle()}
      alt={data_item.letter}
      width="50"
      height="50"
      //   style={grid_view.imageStyle === 'square' ? { borderRadius: "0px" } : { borderRadius: "50px" }}
    />
  );
}
