import InputField from "../fields/inputFields/InputField";
import ViewFields from "../fields/viewFields/ViewFields";
import { getFieldAttributes } from "../fields/inputFields/InputFieldLogic";
import { fileURLToPath } from "url";

export default function FormViewSelector(props: any) {
  const view_action = props.feature_state.item.view_action
  const fields_config = props.feature_state.definition.data.fields_config

  const inputField = (field: any) => {
    return <InputField
      key={field.id}
      field_config={field}
      {...props}
    />
  }

  // console.log('Element 3. FormView - props:', view_action, props)
  return (<>
    <form className='moduleform'>
      {view_action === 'view' ?
        fields_config
          .filter((field: any) => field.item_view_use_field === true)
          .sort((a: any, b: any) => a.item_view_order - b.item_view_order)
          .map((field: any) =>
            <ViewFields
              key={field.id}
              field_config={field}
              hide_field={field.item_view_hide_field}
              {...props}
            />
          )
        :
        view_action === 'edit' ?
          fields_config
            .filter((field: any) => field.item_edit_use_field === true)
            .sort((a: any, b: any) => a.item_edit_order - b.item_edit_order)
            .map((field: any) =>
            //         // If field has conditional logic === includes
            (field.item_edit_conditional_render_based_on &&
              field.item_edit_conditional_render_logic === 'includes' ?
              field.item_edit_conditional_render_values
                .includes(parseInt(getFieldAttributes(field.item_edit_conditional_render_based_on, props.field_values, 'field_value'))) &&
              <InputField
                key={field.id}
                field_config={field}
                hide_field={field.item_edit_hide_field}
                {...props}
              />
              :
              field.item_edit_conditional_render_logic === 'equal_to' ?
                getFieldAttributes(field.item_edit_conditional_render_based_on, props.field_values, 'field_value') === field.item_edit_conditional_render_values &&
                <InputField
                  key={field.id}
                  field_config={field}
                  hide_field={field.item_edit_hide_field}
                  {...props}
                />
                :
                // If no conditional logic display all fields.
                <InputField
                  key={field.id}
                  field_config={field}
                  hide_field={field.item_edit_hide_field}
                  {...props}
                />)

            )
          :
          // Add
          fields_config
            .filter((field: any) => field.item_add_use_field === true)
            .sort((a: any, b: any) => a.item_add_order - b.item_add_order)
            .map((field: any) => {
              //         If field has conditional logic === includes
              // console.log(
              //   'field name:', field.field.field_name,
              //   'field based on:', field.item_add_conditional_render_based_on,
              //   'field logic:', field.item_add_conditional_render_logic,
              //   'values:', field.item_add_conditional_render_values,
              //   'getFieldAttributes:', getFieldAttributes(field.item_add_conditional_render_based_on, props.field_values, 'field_value'
              //   )
              // )

              return (
                (field.item_add_conditional_render_based_on &&
                  field.item_add_conditional_render_logic === 'includes' ?
                  field.item_add_conditional_render_values
                    .includes(parseInt(getFieldAttributes(field.item_add_conditional_render_based_on, props.field_values, 'field_value'))) &&
                  <InputField
                    key={field.id}
                    field_config={field}
                    hide_field={field.item_add_hide_field}
                    {...props}
                  />
                  :
                  field.item_add_conditional_render_logic === 'equal_to' ?
                    getFieldAttributes(field.item_add_conditional_render_based_on, props.field_values, 'field_value') === field.item_add_conditional_render_values &&
                    <InputField
                      key={field.id}
                      field_config={field}
                      hide_field={field.item_add_hide_field}
                      {...props}
                    />
                    :
                    // If no conditional logic display all fields.
                    <InputField
                      key={field.id}
                      field_config={field}
                      hide_field={field.item_add_hide_field}
                      {...props}
                    />
                )
              )
            }
            )
      }
    </form>
  </>)
}