import classes from './Card.module.css'
import { Link } from 'react-router-dom';

export default function CardItem(props: any) {
  return (
    <div className={classes.cardItem}>
      <Link className={classes.cardItemLink} to={props.link}>
        <p className={classes.cardItemIcon}>{props.icon}</p>
        <p className={classes.cardItemTitle}>{props.title}</p>
        {/* <p className={classes.cardItemDescription}>{props.description}</p> */}
      </Link>
    </div>
  )
}
