import BaseContainer from "../../components/containers/BaseContainer";
import { Link } from 'react-router-dom';
import { MdQuestionMark, MdMenuBook, MdOutlineSupportAgent } from 'react-icons/md';
import CardItem from "../../components/cards/CardItem";
import classes from './Support.module.css'

export default function SupportHowtoGuide() {

  const supportoptions = [
    {
      id: 1,
      title: 'F&Qs',
      icon: <MdQuestionMark />,
      link: '/support/faqs/',
    },
    {
      id: 2,
      title: 'How to guides',
      icon: <MdMenuBook />,
      link: '/support/faqs/',
    },
    {
      id: 3,
      title: 'Rasie a ticket',
      icon: <MdOutlineSupportAgent />,
      link: '/support/tickets/',
    }
  ]

  return (
    <BaseContainer
      type='fixed'
      heading='Help'
      showBackButton
    >

      {/* <div className={classes.sectionA}>
        <div className={classes.ContainerColumnsFour}>
          {supportoptions.map((option: any) => { return (CardItem(option)) })}
        </div>

      </div> */}

      <p>Getting Started</p>
      1. Add your first Trip. Give it a name like 'Weekend on the Solent'
      2. Next add the passages you have undertaken, For example

      <p> A trip is made up of 1 or more passages. For example a trip could be a weekend away, a holiday or a series of races. </p>
      <p> A passage is a non-stop voyage between two locations. </p>
      https://www.rya.org.uk/training/certificates-of-competence/qualifying-passages

    </BaseContainer>
  )
}
