import AddFormButton from '../buttons/AddFormButton'
import EditFormButton from "../buttons/EditFormButton";
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux'
import { features_actions } from '../../state/slices/FeatureSlice'
import { useNavigate } from "react-router-dom";
import BaseContainer from "./BaseContainer";

export default function FeatureContainer({ heading, children, ...props }: any) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const appMode = useAppSelector((state) => state.ui.appMode)
  // console.log('FeatureContainer - props:', props)
  const rightHandButtons = () => {
    return (<>
      {props.showAddFormButton &&
        <AddFormButton
          onClick={() => {
            props.setFeatureMessages([])
            appMode === 'mobile' ?
              navigate('/' + props.feature_state.link + '/item:add')
              :
              dispatch(features_actions.openItemModal({
                feature_id: props.feature_state.feature_id,
                view_action: 'add'
              }))
          }}
          {...props}
        />}
      {props.showEditFormButton &&
        <EditFormButton
          onClick={() => {
            props.setFeatureMessages([])
            props.feature_state.view === 'item' ?
              navigate('/' + props.feature_state.link + '/item:edit?id=' + props.feature_state.item.item_id)
              :
              dispatch(features_actions.openItemModal({
                feature_id: props.feature_state.feature_id,
                item_id: props.feature_state.item.data.id,
                view_action: 'edit'
              }))
          }}
          {...props}
        />
      }
    </>)
  }

  return (
    <BaseContainer
      {...props}
      heading={heading}
      children={children}
      rightHandButtons={rightHandButtons()}>
    </BaseContainer>
  );
};