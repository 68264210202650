//  React
import { useState } from 'react';
// import { MdNorth, MdSouth, MdOutlineSortByAlpha } from 'react-icons/md';
// Style
import tablestyle from './Table.module.css';
// Hooks
import { handleSingleSortValue } from '../../../hooks/handleSortData';
import { HandleOutsideClick } from '../../../hooks/handleOutSideClick';
import { useAppDispatch } from '../../../hooks/useRedux'
// Components
import FilterFields from '../../fields/filterFields/FilterFields';

const TableHead = (props: any) => {
  // console.log('TableHead - props:', props)
  const dispatch = useAppDispatch()
  // const colclass = ('colDisP' + (props.tableDisplayPriority))
  const [showDropDownContent, setShowDropDownContent] = useState(false)
  const field_name = props.item.field.field_name
  const field_type = props.item.field.field_type.html_field_type
  const feature_id = props.feature_state.feature_id
  const Dropdowncontent = (props: any) => {
    // { console.log('Dropdowncontent - props.field_type', props.item.field.field_name, props.field_type) }
    return (
      <div
        className={showDropDownContent ?
          `${tablestyle.dropdowncontent} ${tablestyle.show}` :
          tablestyle.dropdowncontent}
      >
        <a
          href="#"
          onClick={() => {
            handleSingleSortValue(dispatch, props.actions, props.list_data, field_name, 'asc', feature_id);
            setShowDropDownContent(false)
          }}
        >
          {field_type === 'number' ? 'Sort: Low to High' : 'Sort: A to Z'}
        </a>
        <a
          href="#"
          onClick={() => {
            handleSingleSortValue(dispatch, props.actions, props.list_data, field_name, 'dsc', feature_id);
            setShowDropDownContent(false)
          }}
        >
          {field_type === 'number' ? 'Sort: High to Low' : 'Sort: Z to A'}
        </a>

        <hr className={tablestyle.divider}></hr>
        <FilterFields {...props} />
        <hr className={tablestyle.divider}></hr>
        <div
        // style={{marginLeft: '5%'}}
        >
          <button
            className={`${'orange'} ${'standardButton'}`}
            onClick={e => {
              dispatch(props.actions.removeSingleListFilter({ field_name: field_name, feature_id: feature_id }));
              setShowDropDownContent(false);
            }}
          >
            Clear filter
          </button>
          <button
            className={`${'green'} ${'standardButton'}`}
            onClick={e => {
              setShowDropDownContent(false)
            }}
          >
            Apply filter
          </button>
        </div>
      </div>

    )
  }

  const delay = (ms: any) => new Promise(resolve => setTimeout(resolve, ms));

  return (
    <th
    // className={tablestyle[colclass]} 
    >
      <div className={tablestyle.clickableHeader} onClick={() => setShowDropDownContent(current => !current)} >
        <section className={tablestyle.container} >
          <div className={tablestyle.headerLeft}>
            {props.item.field.translation.en}
          </div>
          <div className={tablestyle.headerRight}>
            <img className={tablestyle.menuicon} src={'/static/images/KebabMenu.png'} alt="menu icon" />
          </div>
        </section>
      </div>
      <div className={tablestyle.dropdownTableMenu}>
        <HandleOutsideClick
          show={showDropDownContent}
          onClickOutside={async (event: any) => { await delay(10); setShowDropDownContent(false) }}
          contents={Dropdowncontent(props)}
        />
      </div>
    </th>
    //   :
    //   < className={tablestyle[colclass]} >
    //   <div className={tablestyle.notClickableHeader}>
    //     <section className={tablestyle.container} >
    //       <div className={tablestyle.headerLeft}>
    //         {props.label}
    //       </div>
    //       <div className={tablestyle.headerRight}>
    //       </div>
    //     </section>
    //   </div>
    // </th>
  )
}
export default TableHead