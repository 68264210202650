import { createBrowserRouter } from "react-router-dom";
import Root from "./Root";
import FeaturesView from "../components/views/FeaturesView";
import ErrorPage from "./ErrorPage";

//User
///Auth
import SignUp from "../customviews/user/auth/SignUp";
import SignIn from "../customviews/user/auth/SignIn";
import TandCs from "../customviews/user/auth/TermsAndConditions";
import MFA from "../customviews/user/auth/MFA";
// import { SignedOut } from "../views/user/SignedOut";
import EmailVerificationResend from "../customviews/user/auth/EmailVerificationResend";
import EmailVerification from "../customviews/user/auth/EmailVerification";
import PasswordForgot from "../customviews/user/auth/PasswordForgot";
import PasswordReset from "../customviews/user/auth/PasswordReset";
// import PasswordUpdate from "../customviews/user/auth/PasswordUpdate";
// import ManageMFA from '../customviews/user/auth/ManageMFA'
import UserProfile from "../customviews/user/Profile";
import UserAccount from "../customviews/user/Account";
import UserSettings from "../customviews/user/Settings";

//Support
import SupportHome from "../customviews/support/SupportHome";
import SupportFAQS from "../customviews/support/FAQs";
import HowToGuides from "../customviews/support/HowToGuides";

//Personal Log
import Dashboard from "../customviews/personallog/dashboard/Dashboard";

export const routerSignedIn = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    // errorElement: childrenRoutes(),
    // children: <ChildrenRoutes />
    children: [
      {
        index: true,
        element: <Dashboard />,
      },

      // Signed out routes
      { path: "/user/signin", element: <SignIn /> },
      { path: "/user/signup", element: <SignUp /> },
      {
        path: "/user/emailverification/:token",
        element: <EmailVerification />,
      },
      {
        path: "/user/emailverificationresend",
        element: <EmailVerificationResend />,
      },
      { path: "/user/password/forgot", element: <PasswordForgot /> },
      { path: "/user/password/reset/:token", element: <PasswordReset /> },
      { path: "/user/tandcs", element: <TandCs /> },

      // Signed in or out
      { path: "/user/mfa", element: <MFA /> },

      //Signed in
      { path: "/user/account", element: <UserAccount /> },
      // { path: "/user/manage/mfa", element: <ManageMFA /> },
      // { path: "/user/password/update", element: <PasswordUpdate /> },
      { path: "/user/profile/:action?", element: <UserProfile /> },
      { path: "/user/settings", element: <UserSettings /> },

      //Personal Log
      {
        path: "/personallog/dashboard/",
        element: <Dashboard />,
      },

      //Support
      {
        path: "/support/home/",
        element: <SupportHome />,
      },
      {
        path: "/support/faqs/",
        element: <SupportFAQS />,
      },
      {
        path: "/support/howtoguides/",
        element: <HowToGuides />,
      },

      //Generic
      {
        path: "/:module/:feature/:view?/",
        element: <FeaturesView />,
      },
    ],
  },

  // {
  //   path: "/test",
  //   element: <p>I'm another parent</p>,
  // },
]);
export const routerSignedOut = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    // errorElement: childrenRoutes(),
    // children: <ChildrenRoutes />
    children: [
      {
        index: true,
        element: <SignIn />,
      },

      // Signed out routes
      { path: "/user/signin", element: <SignIn /> },
      { path: "/user/signup", element: <SignUp /> },
      {
        path: "/user/emailverification/:token",
        element: <EmailVerification />,
      },
      {
        path: "/user/emailverificationresend",
        element: <EmailVerificationResend />,
      },
      { path: "/user/password/forgot", element: <PasswordForgot /> },
      { path: "/user/password/reset/:token", element: <PasswordReset /> },
      { path: "/user/tandcs", element: <TandCs /> },

      // Signed in or out
      { path: "/user/mfa", element: <MFA /> },
    ],
  },
]);
