import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import LinkItem from './LinkItem';
import GroupItem from './GroupItem';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { toggleExpandSideBarMenu } from '../../state/slices/UiSlice';
import Spinner from '../../components/loaders/spinner/SpinnerFullScreen'

const SideBar = (props: any) => {
  const dispatch = useAppDispatch()
  const modules = useAppSelector((state) => state.allModules.data)
  const uiState = useAppSelector(state => state.ui)
  const isSignedIn = useAppSelector((state) => state.user.isSignedIn)
  // console.log('sidebar - isSignedIn:', isSignedIn, 'uiState:', uiState, 'modules: ', modules)

  const SideBarOptions = (props: any) => {
    return <div style={{ color: 'white' }}>
      {/* {props.item.sidebar_type === 'group' ? */}
      <GroupItem {...props} />
      {/* : */}
      {/* <LinkItem {...props} />} */}
    </div>
  }

  return (
    <>

      {isSignedIn && uiState.sideBarShow === true &&
        <div className="sidebar"
          style={uiState.sideBarExpand === true ? { width: uiState.sideBarOpenWidth } : { width: uiState.sideBarCollapsedWidth }}>
          {/* Arrow to open and close side menu */}
          <div className="sidebarOpenCloseButton">
            {uiState.appMode === 'desktop' && (
              uiState.sideBarExpand === true ?
                <a
                  onClick={() => { dispatch(toggleExpandSideBarMenu()) }}
                ><span className='sideBarIcon' > <MdKeyboardArrowLeft /> </span>
                </a> : <a
                  onClick={() => { dispatch(toggleExpandSideBarMenu()) }}
                ><span className='sideBarIcon' ><MdKeyboardArrowRight /></span>
                </a>
            )}
          </div>
          {modules ? modules
            .filter((item: any) => item.sidebar_show === true)
            .map((item: any) =>
              <SideBarOptions
                key={item.id}
                item={item}
                {...props}
              />
            )
            :
            <Spinner />
          }
        </div>
      }
    </>
  )
}

export default SideBar