import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux'
import '../modals/Modal.css'
import { features_actions } from '../../state/slices/FeatureSlice'
import ItemView from '../item/ItemView'
import ListView from '../lists/ListView'
import FeatureContainer from '../containers/FeatureContainer'
import Spinner from '../loaders/spinner/SpinnerFullScreen'
import { displayMessage } from '../messages/UserMessages'
import { GeneralAPIs } from '../../api/GeneralAPIs'

export default function ViewSelector(props: any) {
  const dispatch = useAppDispatch()
  // console.log('Element 1B. ViewSelector:', props)
  const feature_state = useAppSelector((state) => state.features[props.feature_state_id])
  const feature_definition = feature_state.definition.data
  const feature_fields_config = feature_definition.fields_config
  // console.log('ViewSelector - feature_definition:', feature_definition)
  const list_state = useAppSelector((state) => state.features[props.feature_state_id].list);
  // console.log('ViewSelector - list_state:', list_state)

  type MessageType = {
    message?: string,
    message_type?: string
  }

  const [featureMessages, setFeatureMessages] = useState<MessageType[]>([])

  const ListHeading = () => {
    const list_config = feature_definition.list_config
    // console.log('list_config', list_config)
    // console.log('ListHeading', list_config.heading, list_config.heading_field, list_state.data)
    if (list_config.heading === 'item_name' && props.params_item_id) {
      //  This logic is used by inventory checklist items 
      // const levels = list_config.heading_field.split('.')
      // console.log('list_config.heading_field', list_config.heading_field, list_state.data)
      // return list_state.data[0][levels[0]][levels[1]]
      if (list_state.data.length > 0) {
        return list_state.data[0][list_config.heading_field]
      } else {
        return feature_definition.name_translation.en
      }
    } else {
      return feature_definition.name_translation.en
    }
  }

  const ItemHeading = () => {
    // console.log('ItemHeading:', feature_definition.item_config)
    if (feature_state.item.view_action === 'add') {
      return feature_definition.item_config.add_heading + feature_definition.singular_translation.en
    } else {
      if (feature_definition.primary_field) {
        return feature_state.item.data[feature_definition.primary_field] !== undefined && feature_state.item.data[feature_definition.primary_field]
      } else {
        return feature_definition.name_translation.en
      }
    }
  }



  const refreshListData = () => {
    dispatch(GeneralAPIs({
      method: 'get',
      end_point: feature_definition.api_route + '/list',
      actions: features_actions,
      feature_id: feature_definition.id,
      type: 'list'
    }))
  }

  const featureMessageClose = () => {
    console.log('featureMessageClose')
    setFeatureMessages([])
  }

  // When an item modal is closed the messages are brought to the feature view.
  // useEffect(() => {
  //   // console.log('feature_state.item.form_state.message', feature_state.item.form_state.message)
  //   if (feature_state.item.show_modal === true) {
  //     setFeatureMessages([{
  //       message: feature_state.item.form_state.message,
  //       message_type: feature_state.item.form_state.message_type
  //     }])
  //   }
  // }, [feature_state.item.form_state.message])



  // Issue with code, keeps on causing a rerender every 10 seconds
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setFeatureMessages([{
  //       message: '',
  //       message_type: ''
  //     }])
  //     // console.log("Delayed for 10 second.");
  //   }, 10 * 1000);
  //   return () => clearTimeout(timeoutId);
  // }, [featureMessages])

  //  Item Modal is when a user is already in the item list view and selects an item.
  const ItemModal = (props: any) => ReactDOM.createPortal(
    <React.Fragment>
      <div className="modalOverlay modalOverlaycolor"
      // onClick={() => dispatch(features_actions.closeItemModal(feature_state.feature_id))}
      >
        <div className="modalCenterScreen">
          <FeatureContainer
            {...props}
            heading={ItemHeading()}
            type='responsive'
            // module_modal={props.module_modal}
            // module_modal_hide={props.module_modal_hide}
            showBackButton
            showEditFormButton={(feature_state.item.view_action === 'view' && feature_definition.item_config.edit_allow) && true}
            feature_state={feature_state}
            features_actions={features_actions}
            messages={feature_state.item.api_messages}
            setFeatureMessages={setFeatureMessages}
          >
            <ItemView
              {...props}
              feature_state={feature_state}
              item_id={feature_state.item.item_id}
              feature_id={feature_state.feature_id}
              module={module}
              actions={features_actions}
              feature_definition={feature_definition}
              fields_config={feature_fields_config}
              refreshListData={refreshListData}
              setFeatureMessages={setFeatureMessages}
            />
          </FeatureContainer>
        </div>
      </div>

    </React.Fragment>, document.body
  )

  // // This breaks input fields such as search and filter. 
  // const ListOrItem = (props: any) => {
  //   // console.log('props.feature_state.list.api_status', feature_state)
  //   if (feature_state.view === 'item') {
  //     //  The item view is called when the url links directly to an item.
  //     return <ItemView {...props} />
  //   } else if (feature_state.list.api_status === 'success') {
  //     //  The list view is the default view when a feature is used. 
  //     return <ListView {...props} />
  //   } else if (feature_state.list.api_status === 'loading') {
  //     return <Spinner />
  //   } else {
  //     return displayMessage([{ message: 'Unable to connect to database, please contact customer support', message_type: 'error' }])
  //   }
  // }

  // This is the main Containter for all lists and items view directly from a url
  return (
    <FeatureContainer
      {...props}
      heading={feature_state.view === 'item' ? ItemHeading() : ListHeading()}
      type='responsive'
      // module_modal={props.module_modal}
      // module_modal_hide={props.module_modal_hide}
      showBackButton={feature_definition.show_back_button}
      showAddFormButton={(feature_state.view === 'list' && feature_definition.item_config.add_allow) && true}
      showEditFormButton={(
        feature_state.view === 'item' &&
        feature_state.item.view_action === 'view' &&
        feature_definition.item_config.edit_allow)
        && true}
      feature_state={feature_state}
      messages={feature_state.item.api_messages}
      setFeatureMessages={setFeatureMessages}
    >
      {displayMessage(featureMessages, featureMessageClose)}
      {feature_state.list.api_status === 'loading' ? <Spinner /> :
        feature_state.view === 'item' ?
          <ItemView
            {...props}
            feature_state={feature_state}
            item_id={feature_state.item.item_id}
            feature_id={feature_state.feature_id}
            module={module}
            actions={features_actions}
            feature_definition={feature_definition}
            fields_config={feature_fields_config}
            setFeatureMessages={setFeatureMessages}
          /> :
          feature_state.list.api_status === 'success' ?

            <ListView
              {...props}
              feature_state={feature_state}
              item_id={feature_state.item.item_id}
              module={module}
              actions={features_actions}
              feature_definition={feature_definition}
              module_definition={props.module_definition}
              fields_config={feature_fields_config}
              setFeatureMessages={setFeatureMessages}
            />
            :
            displayMessage([{ message: 'Unable to connect to database, please contact customer support', message_type: 'error' }])
      }


      {/* <ListOrItem
        {...props}
        state={feature_state}
        item_id={feature_state.item.id}
        module={module}
        actions={features_actions}
        definition={feature_definition}
        fields_config={feature_fields_config}
      /> */}
      {/* List and Item Modal can be show at the same time. */}
      {feature_state.item.show_modal === true && <ItemModal />}
      {/* {console.log('feature_state.item.show_modal', feature_state.item.show_modal)} */}
    </FeatureContainer>
  )
}

