import { MdSearch, MdClear } from 'react-icons/md';
import classes from './Search.module.css'
import { useAppDispatch } from '../../hooks/useRedux'
import { setSearchInput, clearSearchInput } from '../lists/ListLogic'

const SearchField = (props) => {
  // console.log('SearchField', props)
  const dispatch = useAppDispatch()
  const searchInput = props.list_state.search_input

  const clearIconWithInput = {
    position: 'relative',
    top: '5px',
    right: '25px',
    fontSize: '20px',
    cursor: 'pointer',
  }

  const clearIconNoInput = {
    position: 'relative',
    top: '5px',
    right: '25px',
    fontSize: '20px',
    color: 'white',
    cursor: 'default',
  }

  return (
    <div
      style={{
        position: 'relative',
      }}>

      <MdSearch
        style={{
          position: 'relative',
          top: '7px',
          left: '30px',
          fontSize: '22px',
          // color: 'red',
          cursor: 'pointer',
        }}
      />
      <input
        className={classes.searchBox}
        style={{ marginLeft: 5 }}
        type="text"
        placeholder={(props.feature_definition.name ? "Search " + props.feature_definition.name_translation.en + "..." : 'Search module...')}
        value={searchInput}
        // Redux state
        onChange={e => setSearchInput(dispatch, props.actions, e.target.value, props.feature_id)}
      // React state
      // value={props.searchInput}
      // onChange={e => props.setSearchInput(e.target.value)}
      />


      <MdClear
        className="clearCloseCross"
        style={searchInput ? clearIconWithInput : clearIconNoInput}
        // Redux state
        onClick={e => clearSearchInput(dispatch, props.actions, props.feature_id)}
      // React state
      // onClick={e => props.setSearchInput('')}
      />
    </div>
  )
}
export default SearchField