// Source: not exact but similar https://upmostly.com/tutorials/modal-components-react-custom-hooks

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { MdOutlineLogout, MdSettings, MdAccountCircle, MdOutlinePayment } from 'react-icons/md';

import '../components/modals/Modal.css';

const Links = [{
  name: 'Profile',
  icon: <MdAccountCircle />,
  link: '/user/profile/view',
}, {
  name: 'Account',
  icon: <MdOutlinePayment />,
  link: '/user/account',
}, {
  name: 'Settings',
  icon: <MdSettings />,
  link: '/user/settings',
},
]

const UserModalLink = ({ name, icon, link, }: any) => {
  return (
    <div className='userNavModalItem'>
      <Link to={link}>
        <span className='userNavModalIcon'>
          {icon}
        </span>
        <span className='userNavModalText'
        >
          {name}
        </span>
      </Link>
    </div >
  )
}

const UserModal = ({ show, hide, handle_logout }: any) => show ? ReactDOM.createPortal(

  <React.Fragment>

    <div className="modalOverlay modalOverlaycolor" onClick={hide} />
    <div className="modalWrapper" aria-modal aria-hidden tabIndex={-1} role="User Modal" >
      <div className="userNavModalContent">
        <div className="userNavModalHeader">
          <span className="modalClose" id="user-modalClose" onClick={hide}>&times;</span>
        </div>
        <div>
          <Link to="/user/signin" onClick={() => { hide(); handle_logout(); }}><span className='userNavModalIcon'><MdOutlineLogout /></span>
            <span className='userNavModalText'
            >Sign Out  </span></Link>
          {Links.map((link) =>
            <div
              key={link.name}
              onClick={hide}>
              <UserModalLink
                name={link.name}
                icon={link.icon}
                link={link.link}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default UserModal;