import { Link, useNavigate } from "react-router-dom";
import UserAuthView from "../../components/views/UserAuthView";
import { getFieldAttributes } from "../../components/fields/inputFields/InputFieldLogic";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { GeneralAPIs } from "../../api/GeneralAPIs";
import { userAuthScreensActions } from "../../state/slices/UserSlice";
import { useEffect, useState } from "react";
import React from "react";
import ReactDOM from "react-dom";
import useModal from "../../hooks/useModal";
import { MFASetup } from "./components/MFASetup";
import ChangePassword from "./profile/ChangePassword";
import ChangeEmail from "./profile/ChangeEmail";
import EnableMFA from "./profile/EnableMFA";
import DisableMFA from "./profile/DisableMFA";
import GenericButton from "../../components/buttons/GenericButton";

export default function UserProfile() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const feature_id = 10;
  const user = useAppSelector((state) => state.user.details);
  const profile = useAppSelector((state) => state.userAuthScreens[feature_id]);
  const { show: showModal, toggle: toggleModal } = useModal();
  const [modalType, setModalType] = useState("");
  console.log("user", user);
  console.log("profile", profile);

  useEffect(() => {
    dispatch(
      GeneralAPIs({
        method: "get",
        end_point: "user/profile",
        actions: userAuthScreensActions,
        feature_id: feature_id,
        type: "item",
      })
    );
    dispatch(userAuthScreensActions.setProfileAction("view"));
  }, []);

  const customButtons = (props: any) => {
    // return (
    //   profile.item.view_action === 'edit' && <>
    //     <button
    //       style={{ backgroundColor: "var(--green)" }}
    //       onClick={() => dispatch(userAuthScreensActions.setProfileAction('view'))}
    //     >Cancel
    //     </button></>
    // )

    return (
      profile.item.view_action === "edit" && (
        <GenericButton
          name="Cancel"
          onClick={() =>
            dispatch(userAuthScreensActions.setProfileAction("view"))
          }
          className={`${"orange"}`}
        />
      )
    );
  };

  const footer = (props: any) => {
    return (
      <>
        <div style={{ paddingLeft: "10px" }}>
          <div style={{ textAlign: "center" }}>
            {profile.item.view_action === "view" && (
              <>
                <button
                  onClick={() =>
                    dispatch(userAuthScreensActions.setProfileAction("edit"))
                  }
                >
                  Edit Profile
                </button>
                {/* <br></br> */}
                <button
                  onClick={() => {
                    dispatch(userAuthScreensActions.setViewAction({ feature_id: 9, view_action: 'edit' }))
                    setModalType("change_password");
                    toggleModal();
                  }}
                >
                  Change Password
                </button>
                <button
                  onClick={() => {
                    dispatch(userAuthScreensActions.setViewAction({ feature_id: 63, view_action: 'edit' }))
                    setModalType("change_email");
                    toggleModal();
                  }}
                >
                  Change Email
                </button>
              </>
            )}
          </div>
          {profile.item.view_action === "view" && (
            <>
              <h4> Multi Factor Authentication (MFA)</h4>
              <div>
                Current MFA Status:
                {profile.item.data.mfa_enabled ? (
                  <span className="successMessage" style={{ padding: "5px" }}>Enabled</span>
                ) : (
                  <span className="errorMessage" style={{ padding: "5px" }}>
                    Disabled
                  </span>
                )}

                {/* <div style={{ textAlign: "center" }}> */}
                {profile.item.data.mfa_enabled ?
                  <button
                    className="orange"
                    onClick={() => {
                      dispatch(userAuthScreensActions.setViewAction({ feature_id: 64, view_action: 'edit' }))
                      setModalType("disable_mfa");
                      toggleModal();
                    }}
                  >
                    Disable MFA
                  </button> :
                  <button
                    className="green"
                    onClick={() => {
                      setModalType("enable_mfa");
                      toggleModal();
                    }}
                  >
                    Enable MFA
                  </button>
                }
                {/* </div> */}
              </div>

              <h4> Delete Account</h4>
              <div style={{ maxWidth: "600px" }}>
                <p>
                  Before you delete your account, we recommend you export your
                  data. Once your account has been deleted all your data will be
                  permanently erased. No refunds are given for any remaining
                  subscriptions.
                </p>
              </div>
              <div style={{ textAlign: "center" }}>
                <button
                  className="red"
                  onClick={() => {
                    setModalType("delete_account");
                    toggleModal();
                  }}
                >
                  Delete Account
                </button>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const postSubmitActions = (props: any) => {
    // console.log('postSubmitActions')
    if (showModal) toggleModal();
    dispatch(userAuthScreensActions.setProfileAction("view"));
  };

  // const postSubmitFormSuccessSection = (props: any) => {
  //   console.log('postSubmitFormSuccessSection')
  //   // navigate('/user/profile/view')
  //   dispatch(userAuthScreensActions.setProfileAction('view'))
  // }

  const deleteAccount = async () => {
    console.log("deleteAccount");
    // const APIMethod = 'patch'
    // const APIEndPoint = '/user/delete'

    // await generalAPI(APIMethod, APIEndPoint)
    //   .then(response => {
    //     if (response) {
    //       if (response.APIResult === 'success') {
    //         console.log('Account deleted')
    //         signOutActions()
    //         navigate("/")
    //       } else {
    //         dispatch(userProfileSlice.actions.setMessages({ messageType: 'error', message: 'A server error has occured' }))
    //       }
    //     }
    //   })
    //   .catch(error => {
    //     console.log('error', error)
    //     dispatch(userProfileSlice.actions.setMessages({ messageType: 'error', message: 'A server error has occured' }))
    //   })
  };

  const modalContents = () => {
    if (modalType === "change_password")
      return (
        <ChangePassword
          modalClose={() => {
            toggleModal();
          }}
        />
      );
    if (modalType === "change_email")
      return (
        <ChangeEmail
          modalClose={() => {
            toggleModal();
          }}
        />
      );
    if (modalType === "enable_mfa")
      return (
        <EnableMFA
          modalClose={() => {
            toggleModal();
          }}
        />
      );
    if (modalType === "disable_mfa")
      return (
        <DisableMFA
          modalClose={() => {
            toggleModal();
          }}
        />
      );
    if (modalType === "delete_account")
      return (
        <div className="genericModalContent">
          <h3>Confirm Deletion</h3>
          <p>Are you sure you want to delete your account and all your data?</p>
          <div style={{ textAlign: "center" }}>
            <button onClick={() => toggleModal()}>Cancel</button>
            <button className="red" onClick={() => deleteAccount()}>
              Confirm Deletion
            </button>
          </div>
        </div>
      );
  };

  const Modal = (props: any) =>
    showModal
      ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modalOverlay modalOverlaycolor">
            <div className="genericModalSection">{props.modalContents}</div>
          </div>
        </React.Fragment>,
        document.body
      )
      : null;

  return (
    <>
      <UserAuthView
        feature_id={feature_id}
        footer={footer}
        postSubmitActions={postSubmitActions}
        customButtons={customButtons}
      />
      <Modal modalContents={modalContents()} />
    </>
  );
}
