import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector, } from '../../hooks/useRedux'
import { toggleShowSideBarMenu } from '../../state/slices/UiSlice';
import {
  MdPeople, MdSailing, MdOutlineLoop, MdTimeline, MdLocationPin, MdSettings, MdDashboard, MdSchool, MdFormatListNumbered, MdAbc, MdLightMode, MdOutlineFlag, MdVisibility, MdOutlineWater, MdRadio, MdVolumeUp, MdWindPower, MdChecklistRtl, MdViewModule, MdSecurity, MdOutlineWidgets, MdInventory

} from 'react-icons/md';
import { WiDayRainMix } from 'react-icons/wi';
import { RiFirstAidKitFill } from "react-icons/ri";
import { TiSortAlphabetically, TiSpanner } from "react-icons/ti";

const components = {
  MdPeople: MdPeople,
  MdSailing: MdSailing,
  MdOutlineLoop: MdOutlineLoop,
  MdTimeline: MdTimeline,
  MdLocationPin: MdLocationPin,
  MdSettings: MdSettings,
  MdDashboard: MdDashboard,
  MdSchool: MdSchool,
  MdAbc: MdAbc,
  TiSortAlphabetically: TiSortAlphabetically,
  RiFirstAidKitFill: RiFirstAidKitFill,
  MdOutlineFlag: MdOutlineFlag,
  MdLightMode: MdLightMode,
  TiSpanner: TiSpanner,
  MdFormatListNumbered: MdFormatListNumbered,
  MdOutlineWater: MdOutlineWater,
  MdVolumeUp: MdVolumeUp,
  MdRadio: MdRadio,
  MdVisibility: MdVisibility,
  WiDayRainMix: WiDayRainMix,
  MdWindPower: MdWindPower,
  MdChecklistRtl: MdChecklistRtl,
  MdViewModule: MdViewModule,
  MdSecurity: MdSecurity,
  MdOutlineWidgets: MdOutlineWidgets,
  MdInventory: MdInventory
};

function DynamicComponent(props) {
  // console.log('DynamicComponent -props:', props.icon)
  try {
    const SelectIcon = components[props.icon];
    return <SelectIcon />
  }
  catch (err) {
    return ''
  }
}

const LinkItem = (props) => {
  // console.log('LinkItem - props:', props)
  const dispatch = useAppDispatch()
  const uiState = useAppSelector(state => state.ui)

  return (
    <Link
      to={props.moduleLink + '/' + props.item.frontend_link}
      title={props.item.name}
      onClick={() => uiState.appMode === 'mobile' && dispatch(toggleShowSideBarMenu())}
    >
      {/* {console.log('Link item -props:', props.item)} */}
      <span className="sideBarIcon">
        {/* {props.item.icon} */}
        {props.item && props.item.icon && <DynamicComponent icon={String(props.item.icon)} />}
        {/* {props.item && props.item.icon && <DynamicComponent icon={String('MdWindPower')} />} */}

      </span>
      {uiState.sideBarExpand &&
        <span className='sideBarText'>
          {props.item.name_translation.en}
        </span>
      }
    </Link >
  )
}
export default LinkItem