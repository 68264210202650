import SpinnerCSS from './SpinnerMini.module.css';

export default function SpinnerMini() {
  return (
    // <div style={{
    //   // margin: 'auto',
    //   // width: '50%'
    // }} >
      <div className={SpinnerCSS.circleSmall}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    // </div>
  )
}