import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { apiInitialState, apiReducers, apiReducersInArray } from '../reducers/APIReducer';
import { itemInitialState } from '../reducers/ItemReducer';

export const userAuthPathIdlist = [
  { "id": 0 },
  { "id": 1 },
  { "id": 2 },
  { "id": 3 },
  { "id": 4 },
  { "id": 5 },
  { "id": 6 },
  { "id": 7 },
  { "id": 8 },
  { "id": 9 },
  { "id": 10 },
  { "id": 11 },
  { "id": 12 },
  { "id": 13 },
  { "id": 14 },
  { "id": 15 },
  { "id": 16 },
  { "id": 17 },
  { "id": 18 },
  { "id": 19 },
  { "id": 20 },
  { "id": 21 },
  { "id": 22 },
  { "id": 23 },
  { "id": 24 },
  { "id": 25 },
  { "id": 26 },
  { "id": 27 },
  { "id": 28 },
  { "id": 29 },
  { "id": 30 },
  { "id": 31 },
  { "id": 32 },
  { "id": 33 },
  { "id": 34 },
  { "id": 35 },
  { "id": 36 },
  { "id": 37 },
  { "id": 38 },
  { "id": 39 },
  { "id": 40 },
  { "id": 41 },
  { "id": 42 },
  { "id": 43 },
  { "id": 44 },
  { "id": 45 },
  { "id": 46 },
  { "id": 47 },
  { "id": 48 },
  { "id": 49 },
  { "id": 50 },
  { "id": 51 },
  { "id": 52 },
  { "id": 53 },
  { "id": 54 },
  { "id": 55 },
  { "id": 56 },
  { "id": 57 },
  { "id": 58 },
  { "id": 59 },
  { "id": 60 },
  { "id": 61 },
  { "id": 62 },
  { "id": 63 },
  { "id": 64 },
  { "id": 65 },
  { "id": 66 },
  { "id": 67 },
  { "id": 68 },
  { "id": 69 },
  { "id": 70 },
]


const getInitialAuthState = (props: any) => {

  const initialAuthState = {
    id: props.id,
    path: props.path,
    definition: { ...apiInitialState() },
    item: { ...itemInitialState({ default_view_action: 'add' }) },
  }

  return { ...initialAuthState }
}

const combineInitialStateWithBlankList = () => {
  return (userAuthPathIdlist.map((item: any) => getInitialAuthState(item))
  )
}

const userAuthScreensSlice = createSlice({
  name: 'userAuthScreens',
  initialState: combineInitialStateWithBlankList(),
  reducers: {
    ...apiReducersInArray,

    clearSignInMFAScreenState(state: any) {
      state[2].item = itemInitialState({ default_view_action: 'add' })
      state[3].item = itemInitialState({ default_view_action: 'add' })
    },
    setProfileAction(state: any, action: PayloadAction<any>) {
      state[10].item.view_action = action.payload
    },
    setPasswordResetAction(state: any) {
      state[7].item.view_action = 'edit'
    },
    setViewAction(state: any, action: PayloadAction<any>) {
      // console.log('setFeatureAttributes:', action.payload)
      const feature_id = action.payload.feature_id
      const view_action = action.payload.view_action
      state[feature_id].item.view_action = view_action
    }

  }
});

export const userAuthScreensActions = userAuthScreensSlice.actions
export const userAuthScreensReducer = userAuthScreensSlice.reducer



const userInitialState = {
  isSignedIn: false,
  access_token: '',
  refresh_token: '',
  details: { mfa_enabled: false },
}

const getUserDetailsFromToken = (token: string) => {
  const tokenParts = token.split('.')
  const encodedPayLoad = tokenParts[1]
  const decodedPayLoad = atob(encodedPayLoad)
  const payLoadData = JSON.parse(decodedPayLoad)
  // console.log('payLoadData', payLoadData)
  const user_details = {
    id: payLoadData.user_id,
    email: payLoadData.email,
    first_name: payLoadData.first_name,
    last_name: payLoadData.last_name,
    full_name: payLoadData.first_name + ' ' + payLoadData.last_name
  }
  return user_details
}

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    signIn(state: any, action: PayloadAction<any>) {
      const access_token = action.payload.access_token
      // console.log('user - signIn:', action.payload)
      state.isSignedIn = true
      state.access_token = access_token
      state.refresh_token = action.payload.refresh_token
      state.details = getUserDetailsFromToken(access_token)
    },
    signOut(state: any, action: PayloadAction<any>) {
      // console.log('user - signOut:', action.payload)
      state.isSignedIn = false
      state.access_token = ''
      state.refresh_token = ''
      state.details = ''
    },
  }
});

export const userActions = userSlice.actions
export const userReducer = userSlice.reducer


interface UserAccountState {
  currentPlanName: string,
  currentPlanStatus: string,
  // currentPlanRenewalDate: Date | null,
  currentPlanPrice: number,
  // lastLoggedIn: Date,
  billingaddress: [],
  paymentMethods: [],
  paymentHistory: [],
}

// interface BillingAddressState {
//   currentPlan: string,
//   billingaddress: string,
//   user_name: string,
//   user_id: number,
// }

const initialUserAccountState: UserAccountState = {
  currentPlanName: 'Pro',
  currentPlanStatus: 'Active',
  // currentPlanRenewalDate: new Date(),
  currentPlanPrice: 10,
  // lastLoggedIn: new Date(),
  billingaddress: [],
  paymentMethods: [],
  paymentHistory: []
}

const userAccountSlice = createSlice({
  name: 'userAccount',
  initialState: initialUserAccountState,
  reducers: {
    subscribe: (state, action: PayloadAction<any>) => {
      state.currentPlanName = action.payload.plan
      state.currentPlanStatus = 'Active'
      // state.currentPlanRenewalDate = new Date()
      state.currentPlanPrice = action.payload.price
    },
    cancelSubscription: (state, action: PayloadAction<any>) => {
      state.currentPlanStatus = 'Cancelled'
      // state.currentPlanRenewalDate = null
    },
    upgradeSubscription: (state, action: PayloadAction<any>) => {
      state.currentPlanName = action.payload.plan
      // state.currentPlanRenewalDate = new Date()
      state.currentPlanPrice = action.payload.price
    }
  }
});
export const userAccountActions = userAccountSlice.actions
export const userAccountReducer = userAccountSlice.reducer

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: apiInitialState(),
  reducers: {
    ...apiReducers,
    updateUserSetting: (state, action: PayloadAction<any>) => {
      // console.log('updateUserSetting:', action.payload)
      state.data[action.payload.fieldName] = action.payload.fieldValue
      // state.settings = action.payload
    },
  }
});
export const userSettingsActions = userSettingsSlice.actions
export const userSettingsReducer = userSettingsSlice.reducer



interface UserNotificationsState {
  notifications: any,
  totalNotifications: number,
}

const initialUserNotificationsState: UserNotificationsState = {
  notifications: [],
  totalNotifications: 0
}

const userNotificationsSlice = createSlice({
  name: 'userNotifications',
  initialState: initialUserNotificationsState,
  reducers: {
    createNotification: state => {
      state.totalNotifications += 1
    },
    closeNotification: state => {
      state.totalNotifications -= 1
    },
    createMultipleNotification: (state, action: PayloadAction<number>) => {
      state.totalNotifications += action.payload
    }
  }
});
export const userNotificationsActions = userNotificationsSlice.actions
export const userNotificationsReducer = userNotificationsSlice.reducer