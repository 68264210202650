import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import tablestyle from './Table.module.css';
import TableHead from './TableHead'
import TableRowData from './TableRowData'
import TableRowOptions from './TableRowOptions'
// import { defaultSort, getGroupByRowHeadingName } from '../ListLogic'
// import { successDeleteItem } from '../../itemDetails/ItemLogic'
// import { handleSortData } from '../../../../hooks/handleSingleSortValue';

export default function TableView(props: any) {
  // console.log('TableView - props:', props)
  const uiState = useAppSelector((state) => state.ui);
  const list_config = props.feature_definition.list_config
  // console.log('TableView - props:', props, list_config.table_row_options)
  const table_row_options = list_config.table_row_options ? list_config.table_row_options.split(",") : null
  // console.log('TableView - table_row_options:', table_row_options)
  return (<div className={tablestyle.div}>
    <table className={tablestyle.table}>
      <thead>
        <tr>
          {/* Table head logic must be kept in sync with table body logic */}
          {props.fields_config
            .filter((item: any) => item.table_show_column === true)
            .sort((a: any, b: any) => a.table_column_order - b.table_column_order)
            .map((item: any) => (
              <TableHead
                key={item.id}
                item={item}
                label={item.label}
                fieldName={item.fieldName}
                field_type={item.field_type}
                {...props} />
            ))}
          {table_row_options && table_row_options.length !== 0 && uiState.appMode !== "mobile" &&
            <th className={tablestyle.notClickableHeader} >
              Options
            </th>
          }
        </tr>
      </thead>
      <tbody>
        {/* Map items from database */}
        {props.filtered_data  // contains 1 line per data base item e.g. 200 records 
          // .sort((item:any) =>  )
          .map((data_item: any, index: number, array: any) =>
            <React.Fragment key={data_item.id}>

              {/* {props.listState.groupOptions.length >= 1 &&
                <>
                  <tr
                  // colspan={5}
                  >
                    {getGroupByRowHeadingName(props.listState.groupOptions, data_item, index, array)}
                  </tr>
                </>
              } */}
              <tr
                key={data_item.id} // e.g. 200 records 
                className={tablestyle.trhover}>
                {/* {console.log('tablebody - data_item:', data_item.id)} */}
                {/* Table body logic must be kept in sync with table head logic */}
                {props.fields_config // for each database item, 
                  .filter((item: any) => item.table_show_column === true)
                  .sort((a: any, b: any) => a.table_column_order - b.table_column_order)
                  .map((column_definition: any) => (
                    <TableRowData
                      key={data_item.id + column_definition.field.field_name}
                      data_item={data_item}
                      column_definition={column_definition}
                      {...props}
                    />
                  ))}
                {table_row_options && table_row_options !== 0 && uiState.appMode !== "mobile" &&
                  <TableRowOptions
                    data_item={data_item}
                    rowOptions={table_row_options}
                    {...props}
                  />
                }
              </tr>
            </React.Fragment>
          )}


      </tbody>
    </table>
  </div>)
}