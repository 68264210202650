import React from 'react';
import ReactDOM from 'react-dom';

import '../modals/Modal.css'
// import tablestyle from '../../style/table.module.css';

export const FilterModal = ({ hide, show, ref, contents, dispatch, actions, feature_id }: any) => show ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modalOverlay" onClick={hide} />
    {/* <div className="filterModalWrapper" aria-modal aria-hidden tabIndex={-1} role="Filter Modal" > */}
    <div className='filterModalContainer'>
      <div ref={ref} className="filterModalBody">
        <div className="filterModalHeader">

          <button
            onClick={() => {
              hide();
              dispatch(actions.clearAllListFilters(feature_id));
            }}
            className={`${'orange'} ${'standardButton'}`}>
            Reset all filters
          </button>
          <button
            className='green'
            onClick={() => { hide(); }}>
            Apply Filters
          </button>
          <span className="modalClose" id="modalClose" onClick={hide}>&times;</span>
        </div>
        <div className='filterModalContent'>
          {/* <div
          // className={tablestyle.filterModalContent}
          > */}
          {contents}
          {/* </div> */}
        </div>
      </div>
      {/* </div> */}
    </div>
  </React.Fragment>, document.body
) : null;