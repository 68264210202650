import { MdAdd, MdOutlineHorizontalRule } from "react-icons/md";
import BaseContainer from "../../../components/containers/BaseContainer";
import Favourites from "./Favourites";
import NextQualification from "./NextQualification";
import SailingCV from "./SailingCV";
import dashboardstyle from './Dashboard.module.css';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useRedux";
import BasicGetAPI from "../../../api/BasicGetAPI";
import { GeneralAPIs } from "../../../api/GeneralAPIs";
import { features_actions } from "../../../state/slices/FeatureSlice";

export const sectionHeading = (text: string) => {
  return (<>

    {/* https://www.javascripttutorial.net/web-apis/javascript-drag-and-drop/ */}
    <h3
    // draggable="true"
    >{text}
      {/* < MdOutlineDragIndicator
        className={dashboardstyle.sectionIcon}
        title='Move'
      /> */}
    </h3>

    < MdAdd
      className={dashboardstyle.sectionIcon}
      style={{ backgroundColor: "var(--green)" }}
      title='Expand'
    />

    < MdOutlineHorizontalRule
      className={dashboardstyle.sectionIcon}
      style={{ backgroundColor: "yellow" }}
      title='Minimise' />

    {/* < MdClose
      className={dashboardstyle.sectionIcon}
      style={{ backgroundColor: "red" }}
      title='Close'
    /> */}
  </>)
}

export const changeUserOptions = async (dispatch: any, fieldName: string, fieldValue: any) => {
  // dispatch(PLUserOptionsActions.update({ fieldName, fieldValue }))
  console.log('changeUserOptions - fieldName: ', fieldName, 'fieldValue: ', fieldValue)
  // const APIMethod = 'patch'
  // const APIEndPoint = '/personallog/dashboard/useroptions'
  const data_to_send = { fieldName, fieldValue }

  // await generalAPI(APIMethod, APIEndPoint, '', APIData2Send)
  //   .then(response => {
  //     if (response) {
  //       if (response.APIResult === 'success') {
  //         console.log('Update Success')
  //       }
  //     }
  //   })
  //   .catch(error => {
  //     console.log('error', error)
  //   })

  dispatch(GeneralAPIs({
    method: 'patch',
    end_point: 'personallog/dashboard/useroptions',
    actions: features_actions,
    data_to_send: data_to_send,
    feature_id: 28,
    type: 'item'
  }))
}


export default function Dashboard(props: any) {
  const navigate = useNavigate()
  const isSignedIn = useAppSelector((state) => state.user.isSignedIn)
  const [data, setData] = useState<any>()

  useEffect(() => {
    if (!isSignedIn) {
      navigate('/user/signin')
    }
  }, [isSignedIn])

  useEffect(() => {
    const getData = async () => {
      await BasicGetAPI('personallog/dashboard/sailingcv')
        .then(response => {
          if (response.APIResult === 'success') {
            setData(response.APIData)
          }
        })
    }
    getData()
  }, [])


  return (<>
    <BaseContainer
      {...props}
      type='responsive'
      heading='Dashboard'
      messages={[]}
    >
      {/* First aid status notification */}
      {/* {sectionHeading('Example')} */}
      {/* {useroptionsState.useroptions ? <> */}

      <SailingCV
        data={data}
      />
      <NextQualification
        userData={data}
      // PLUserOptionsActions={PLUserOptionsActions}
      />
      <Favourites
        // numberofrows={useroptionsState.useroptions.fav_no_of_results}
        numberofrows={20}
      />

      {/* Able to hide, show, reorder */}
      {/* </> : <p>Error loading data</p>} */}
    </BaseContainer >
  </>);
}
