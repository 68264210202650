import { useEffect } from 'react';
import './ImageField.css';
import { MdAdd, MdEdit, MdDelete } from 'react-icons/md';
import { getFieldAttributes, handleOnChange } from '../InputFieldLogic'
// import { fileUploadHandler} from './ObjectLogic'

export const ImageField = (props: any) => {
  console.log('ImageField', props)
  const file = props.file
  const field_values = props.field_values
  const setFieldValues = props.setFieldValues
  const field_config = props.field_config
  const field_name = field_config.field.field_name

  // Check which image is being displayed so correct buttons are shown
  const image_value = getFieldAttributes(field_name, field_values, 'field_value')
  // console.log('image_value', image_value)

  const default_image = field_config.default_image_name + '.' + field_config.default_image_type
  // console.log('default_image', default_image)

  const isDefaultImage = () => {
    // console.log('image_value:', image_value, 'default_image:', default_image)
    if (image_value === '' || image_value === default_image) {
      // console.log('isDefaultImage - true')
      return true
    } else {
      // console.log('isDefaultImage - false')
      return false
    }
  }
  // console.log('isDefaultImage', isDefaultImage())

  // 1. As the default image field is hidden, this file handler links the custom image field and image buttons to the hidden field. 
  const fileHandler = () => {//when the custom fileHandler is click
    props.fileUploadRef.current.click();   // open up the DOM default file hanlder
  };

  //  2. Validated uploaded file, if success save to form values.
  const fileUploadHandler = (event: any) => {
    // objectUploadHandler(event, )
    let uploadedFile;
    if (event.target.files && event.target.files.length === 1) {
      uploadedFile = event.target.files[0];
      if (uploadedFile.size > 1000000) {
        setFieldValues(setFileFields(uploadedFile.name, true, 'Maximum file size 1MB'))
      } else {
        // console.log('uploadedFile', uploadedFile)
        props.setFile(uploadedFile);
        setFieldValues(setFileFields(uploadedFile.name, false))
      }
    }
  };

  // 3. When a file is uploaded, as the default file uploader is not used this detects the change, this then saves the image to local state so that it can be displayed immediately.  
  useEffect(() => {
    if (!file) {
      return;
    }
    // use filereader, when file is uploaded save the result (the image) to temp state
    const fileReader = new FileReader(); //allows us to read uploaded file
    fileReader.onload = () => {
      // console.log('fileReader', fileReader.result)
      // console.log('fileReader', fileReader)
      props.setPreviewImageURL(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);


  // 4. If valid then save the image values to the field_values. 
  const setFileFields = (
    file_name: string,
    has_error: boolean,
    fieldErrorMessage?: string,
    reset?: boolean) => {

    const getindex = field_values.findIndex((e: any) => e.field_name === field_name)
    // console.log('setFileFields- getindex:', getindex, 'file_name:',file_name)

    return (
      field_values.map((current_values: any, index: number) => {
        if (index == getindex) {
          if (reset) {
            // console.log('setFileFields- reset:- file_name:',file_name)
            return { ...current_values, field_value: '', touched: false, has_error: false, error_messages: [] }
          } else {
            if (has_error) {
              // console.log('setFileFields- has_error:- file_name:',file_name)
              return { ...current_values, field_value: { title: file_name }, touched: true, has_error: true, error_messages: [fieldErrorMessage] }
            } else {
              // console.log('setFileFields- ok:- file_name:', file_name)
              return { ...current_values, field_value: { title: file_name }, touched: true, has_error: false, error_messages: [] }
            }
          }
        } else {
          // console.log('setFileFields- else: - file_name:',file_name)
          return { ...current_values }
        }
      }))
  }

  const deleteFile = () => {
    // console.log('deleteFile')
    setFieldValues(setFileFields('', false, '', true))
    props.setFile('');
    props.setPreviewImageURL('');
  };

  const imageSrc = () => {
    // console.log('field_values', props.feature_state.item.data)
    // console.log('image_value', image_value)
    if (props.previewImageURL) {
      return props.previewImageURL
    } else {
      if (image_value === '' || isDefaultImage() === true) {
        return '/static/images/default/' + default_image
      } else {
        return props.feature_state.item.data.src
      }
    }
  }
  // console.log('props.previewImageURL', props.previewImageURL)
  // console.log('imageSrc', imageSrc())

  const imageTitle = () => {
    const feildValueImageTitle = getFieldAttributes(field_name, field_values, 'value', 'title')
    if (feildValueImageTitle === '') {
      return 'Add Image'
    } else {
      return feildValueImageTitle
    }
  }

  return (
    <div>
      <input
        id='1'
        ref={props.fileUploadRef} //where input is saved
        style={{ display: 'none' }}
        type='file'
        accept=".jpg,.png,.jpeg"
        onChange={fileUploadHandler} // what happens when a user selects a file. 
      />
      <div className='ImageFieldContainer' >
        <img
          className='ImageFieldImage'
          src={imageSrc()}
          alt={imageTitle()}
          title={imageTitle()}
          onClick={fileHandler} />
        <div className='ImageFieldEditButtonContainer'>
          <a className='ImageFieldEditButton'
            onClick={fileHandler}>
            {isDefaultImage() ?
              <MdAdd
                title={'Add Image'}
                style={{
                  verticalAlign: 'middle',
                  fontSize: props.iconSize ? props.iconSize : '30px',
                }} /> :
              <MdEdit
                title={'Edit Image'}
                style={{
                  verticalAlign: 'middle',
                  margin: '5px',
                  fontSize: props.iconSize ? props.iconSize : '20px',
                }} />}
          </a>
        </div>
        <div className='ImageFieldDeleteButtonContainer'>
          <a className='ImageFieldDeleteButton'
            onClick={deleteFile}>
            {!isDefaultImage() &&
              <MdDelete
                title={'Delete Image'}
                style={{
                  verticalAlign: 'middle',
                  margin: '5px',
                  fontSize: props.iconSize ? props.iconSize : '20px',
                }} />}
          </a>
        </div>

      </div>

    </div >
  )

}

export default ImageField
