import GridItem from './GridItem'

export default function GridView(props: any) {

  return (<>
    {props.filtered_data
      .map((data_item: any) =>
        <GridItem
          key={data_item.id}
          data_item={data_item}
          {...props} />
      )}
  </>)
}