import { useEffect } from "react";
import BackButton from "../buttons/BackButton";
import { displayMessage } from "../messages/UserMessages";
import { useAppSelector } from "../../hooks/useRedux";

export default function BaseContainer({ heading, children, ...props }: any) {
  // console.log('BaseContainer', props)
  const uiState = useAppSelector((state) => state.ui);
  const isSignedIn = useAppSelector((state) => state.user.isSignedIn);

  useEffect(() => {
    if (heading === undefined) {
      document.title = "McBoaty";
    } else {
      document.title = "McBoaty - " + heading;
    }
  }, [heading]);
  return (
    <div
      className={`${props.centerScreen === true ? "centerScreen" : "section"}`}
      style={
        !isSignedIn
          ? { marginLeft: "0px" }
          : uiState.appMode === "desktop"
            ? uiState.sideBarExpand === true
              ? {
                left: "100px",
                transition: "0.5s",
              }
              : {
                left: "30px",
                transition: "0.5s",
              }
            : { left: "0px" }
      }
    >
      {/* <div className='section'
        style={props.style}> */}
      <div className={"responsivecontainer"}
      // className={`${props.type === "responsive" ? "responsivecontainer" : "fixedcontainer"
      //   }`}
      // style={
      //   props.width
      //     ? { maxWidth: props.width }
      //     : props.type === "responsive"
      //       ? {}
      //       : { width: "500px" }
      // }
      >
        <div className="baseContainerCSSGridContainer">
          <div className="baseContainerCSSGridItem">
            {props.showBackButton === true && <BackButton {...props} />}
          </div>

          <div className="baseContainerCSSGridItem">
            <div className="baseContainerHeading" id={props.id}>
              {heading}
            </div>
          </div>
          <div className="baseContainerCSSGridItem" style={{ float: "right" }}>
            {props.rightHandButtons && props.rightHandButtons}
            {props.modalClose && (
              <span
                className="modalClose"
                id="user-modalClose"
                onClick={props.modalClose}
              >
                &times;
              </span>
            )}
          </div>
        </div>

        {props.messages && displayMessage(props.messages)}
        {children}
      </div>
      {/* </div> */}
    </div>
  );
}
