import UserAuthView from "../../../components/views/UserAuthView";

export default function ChangeEmail(props: any) {
  const header = (props: any) => {
    if (props.api_status === "success") {
      return (
        <div style={{ textAlign: "center" }}>
          <p>
            An email verification link has been sent to your new email address.
            To complete this change please click on the verification link in the
            email.{" "}
          </p>
        </div>
      );
    } else if (props.api_status === "error") {
      return (
        <>
          <p>An error has occured, please contact customer support. </p>
        </>
      );
    }
  };

  return (
    <UserAuthView
      feature_id={63}
      // showBackButton
      modalClose={props.modalClose}
      centerScreen
      header={header}

    // formTopSection={formTopSection}
    // formBottomSection={formBottomSection}

    // preSubmitActions={preSubmitActions}
    // postSubmitActions={''}

    // postSubmitFormSuccessSection={postSubmitFormSuccessSection}
    // postSubmitFormErrorSection={postSubmitFormErrorSection}
    />
  );
}
