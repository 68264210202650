import { EditableOptionAddButton, errorMessage, label, getFieldAttributes, handleOnChange } from "../InputFieldLogic"
import BasicGetAPI from '../../../../api/BasicGetAPI'
import { useEffect, useState } from "react"
import { MdAdd } from "react-icons/md"
import useModal from "../../../../hooks/useModal"

export const checkforqueryparms = (props: any, field_name: string) => {
  if (props.query_parms) {
    // console.log('query_parms', field_name, props.query_parms[field_name])
    for (var [key, value] of props.query_parms.entries()) {
      // console.log('query_parms key / value', field_name, key, value)
      if (field_name === key) {
        console.log(key + '=' + value);
        return '/' + value
      }
    }
  }
  return ''
}

export const get_conditional_options = (field_config: any, field_values: any, dynamic_list: any) => {
  return (field_config.item_add_conditional_render_logic === 'linkedField' ?
    // console.log('field_config.item_add_conditional_render_linked_field', field_config.item_add_conditional_render_linked_field, dynamic_list)
    dynamic_list
      .filter((item: any) => item[field_config.item_add_conditional_render_linked_field] == getFieldAttributes(field_config.item_add_conditional_render_based_on, field_values, 'field_value'))
    : dynamic_list
    // console.log('conditionalOptions', conditionalOptions)
  )
}

export const selectField = (
  field_name: string,
  field_config: any,
  field_values: any,
  onChange: any,
  dynamic_list: any,
  static_options: any,
  conditional_options: any,
  fixed_single_key_value_pair: any,
  feature_view_mode: string
) => {

  return (<>
    <div className='inputField'
      style={{ flexDirection: 'row' }}
    >
      <select
        key={field_name}
        id={field_name}
        className='inputField'
        style={{ width: field_config.options_editable ? '85%' : '100%' }}
        value={getFieldAttributes(field_name, field_values, 'field_value')}
        onChange={e => {
          field_config.options_type === 'dynamic_list' ?
            onChange(parseInt(e.target.value)) :
            onChange(e.target.value);
          // console.log('e.target.value', parseInt(e.target.value)) 
        }}
        // onBlur={e => onBlur(field_name, e.target.value)}
        autoComplete={field_config.auto_complete}
        disabled={field_config.disabled ? field_config.disabled : false}
      >
        {Array.isArray(getFieldAttributes(field_name, field_values, 'field_value')) ?
          <option
            key='0'
            hidden
          >
            Select...
          </option>
          :
          <option
            key='0'
            hidden
            disabled
          >
          </option>
        }
        {
          field_config.options_type === 'static_list' && field_config.options_source &&
          static_options.map((option: any) => (
            <option
              key={option}
              value={option}
              className='inputFieldSelectOption'
            >
              {option}
            </option>
          ))
        }

        {/* Logic for for PL Passage Crew */}
        {field_config.options_type === 'fixed_single_key_value_pair' && feature_view_mode === 'add' &&

          dynamic_list.map((option: any) => (
            <option
              key={option.id}
              value={parseInt(option.id)}
              className='inputFieldSelectOption'
            >
              {option.name}
            </option>
          ))
        }

        {field_config.options_type === 'fixed_single_key_value_pair' && feature_view_mode === 'edit' &&
          <option
            key={fixed_single_key_value_pair}
            value={fixed_single_key_value_pair}
            className='inputFieldSelectOption'
          >
            {fixed_single_key_value_pair}
          </option>
        }


        {/* {field_config.options_type === 'dynamic_list' && GetSelectOptions()} */}
        {/* {console.log('getFieldAttributes(field_config.item_add_conditional_render_based_on, field_values, field_value)', getFieldAttributes(field_config.item_add_conditional_render_based_on, field_values, 'field_value'))} */}

        {field_config.options_type === 'dynamic_list' &&

          (dynamic_list.length > 0 ?

            field_config.item_add_conditional_render_logic === 'linkedField' ?
              // console.log('field_config.item_add_conditional_render_linked_field', field_config.item_add_conditional_render_linked_field, dynamic_list)
              conditional_options
                .map((option: any) => (
                  <option
                    key={option.id}
                    value={parseInt(option.id)}
                    className='inputFieldSelectOption'
                  >
                    {option.name}
                  </option>))
              :
              dynamic_list.map((option: any) => (
                <option
                  key={option.id}
                  value={parseInt(option.id)}
                  className='inputFieldSelectOption'
                >
                  {option.name}
                </option>
              )) :
            <option key='999'
              value='n/a'
              className={'message errorMessage '}
              disabled
            >
              No options available
            </option>
          )
        }

      </select>
      <div
        style={{ verticalAlign: 'middle' }}
      >
        {field_config.options_editable === true && EditableOptionAddButton(field_config)}
      </div>
      {label(field_config, field_values)}
    </div>
    {errorMessage(field_name, field_values)}
  </>
  )
}
