import SpinnerCSS from './Spinner.module.css';

const Spinner = () => {
  return (
    <div style={
      {
        margin: 'auto',
        width: '25%'
      }} >
      <div className={SpinnerCSS.circle}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )

}
export default Spinner