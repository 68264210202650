import { MdOutlineWarningAmber, MdErrorOutline, MdOutlineCheck } from "react-icons/md";

export const displayMessage = (messages: any, featureMessageClose?: any) => {

  const userMessage = (messages: any) => {
    return messages.map((message: any) => {
      return <div key={message}>
        {message.message_type === 'error' &&
          <div className={'message errorMessage'}>
            <span className={'userMessagesIcon'}>  <MdErrorOutline /></span>
            {message.message}
          </div>
        }
        {message.message_type === 'warning' &&
          <div className={'message warningMessage'}>
            <span className={'userMessagesIcon'}>  <MdOutlineWarningAmber /></span>
            {message.message}
          </div>
        }
        {message.message_type === 'success' &&
          <div className={'message successMessage'}>
            <span className={'userMessagesIcon'}> <MdOutlineCheck /></span>
            {message.message}
            {featureMessageClose && <span className="messageClose" title='Close message'
              onClick={() => featureMessageClose()}
            >&times;</span>}
          </div>
        }

      </div>

    })
  }
  return userMessage(messages)
}