import { MdEdit } from 'react-icons/md';
import GenericButton from './GenericButton';

export default function EditFormButton(props: any) {
  return (
    <GenericButton
      name='Edit'
      onClick={props.onClick}
      icon={MdEdit}
      className={`${'green'} ${'addEditFormButtons'} ${'responsiveButton'}`}
      responsiveButtonText
    />
  )
}