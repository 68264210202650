import { Link, useLocation } from "react-router-dom";
import UserAuthView from "../../../components/views/UserAuthView";

export default function EmailVerification() {
  const location = useLocation();
  let email = location.state.email;
  // console.log('email', email)

  const header = (props: any) => {
    if (props.api_status !== 'success') {
      return (
        <p style={{ textAlign: 'center' }}>To use this App you must verify your email address.</p>
      )
    }
  }

  return (
    <UserAuthView
      feature_id={6}
      data_to_send={{ email: email }}
      centerScreen
      successMessage={'useAPIMessages'}
      header={header}
      hideFormAfterSuccessfulSubmitted
    />
  )
}