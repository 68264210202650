import { PayloadAction } from '@reduxjs/toolkit'
import { featureStateType } from './FeatureReducer'

export const apiInitialState = (initialData?: any) => {
  if (initialData) {
    return {
      data: initialData,
      api_status: 'not_initiated', //not_initiated  / loading / success / error
      api_messages: [{
        message: '',
        message_type: '',
      }]
    }
  } else {
    return {
      data: [],
      api_status: 'not_initiated', //not_initiated  / loading / success / error
      api_messages: [{
        message: '',
        message_type: '',
      }]
    }
  }
}

// Basic API reducers - used for standalone functions.
export const apiReducers = {
  setAPIStatus(state: any, action: PayloadAction<any>) {
    state.api_status = action.payload.status
  },
  setData(state: any, action: PayloadAction<any>) {
    state.data = action.payload.data
  },
  clearData(state: any) {
    state.data = ''
  },
  setMessages(state: any, action: PayloadAction<any>) {
    state.api_messages[0].message = action.payload.message
    state.api_messages[0].message_type = action.payload.message_type
  },
  clearMessages(state: any) {
    state.api_messages[0].message = ''
    state.api_messages[0].message_type = ''
  }
}


// // API reducers for different data types - used by Auth modules.
// const stateWithType = (state: any, type: string) => {
//   if (type === 'definition') return state.definition
//   if (type === 'item') return state.item
// }
// export const apiReducersByType = {
//   setAPIStatus(state: any, action: PayloadAction<any>) {
//     const type = action.payload.type
//     stateWithType(state, type).api_status = action.payload.status
//   },
//   setData(state: any, action: PayloadAction<any>) {
//     const type = action.payload.type
//     stateWithType(state, type).data = action.payload.data
//   },
//   clearData(state: any, action: PayloadAction<any>) {
//     const type = action.payload.type
//     stateWithType(state, type).data = ''
//   },
//   setMessages(state: any, action: PayloadAction<any>) {
//     const type = action.payload.type
//     stateWithType(state, type).api_messages[0].message = action.payload.message
//     stateWithType(state, type).api_messages[0].message_type = action.payload.message_type
//   },
//   clearMessages(state: any, action: PayloadAction<any>) {
//     const type = action.payload.type
//     stateWithType(state, type).api_messages[0].message = ''
//     stateWithType(state, type).api_messages[0].message_type = ''
//   }
// }



export const apiReducersInArray = {

  setAPIStatus(state: any, action: PayloadAction<any>) {
    // console.log('setAPIStatus', action.payload)
    const feature_id = action.payload.feature_id
    const type = action.payload.type
    // if (action.payload.status === 'loading') {
    //   eval(featureStateType(state, feature_id, type)).data = []
    // }
    eval(featureStateType(state, feature_id, type)).api_status = action.payload.status
  },

  setData(state: any, action: PayloadAction<any>) {
    // console.log('setData', action.payload)
    const feature_id = action.payload.feature_id
    const type = action.payload.type
    const data = action.payload.data



    if (type === 'item') {
      // console.log('data.item_config.default_action', data)
      // let keys = Object.keys(data);
      // let field_status_array = [{}]
      // keys.map((key: any, index) => {
      //   const new_item = {
      //     field_name: key,
      //     touched: false,
      //     has_error: false,
      //     error_messages: [],
      //   }
      //   if (index === 0) {
      //     field_status_array = [new_item]
      //   } else {
      //     field_status_array.push(new_item)
      //   }
      // })
      // state[feature_id].item.field_status = field_status_array

      state[feature_id].item.data = data
      state[feature_id].item.next_action = action.payload.next_action
      state[feature_id].item.view_action = data.item_config ? data.item_config.default_action : state[feature_id].item.view_action
    } else {
      eval(featureStateType(state, feature_id, type)).data = data
    }
    // console.log('data.api_route_method ', data.api_route_method)
    // Used by password reset 
    // if (data.api_route_method && data.api_route_method === 'patch') {

    // }
  },

  clearData(state: any, action: PayloadAction<any>) {
    // console.log('clearData', action.payload)
    const feature_id = action.payload.feature_id
    const type = action.payload.type
    eval(featureStateType(state, feature_id, type)).data = ''
    eval(featureStateType(state, feature_id, type)).next_action = ''
  },

  setMessages(state: any, action: PayloadAction<any>) {
    // console.log('setMessages', action.payload)
    const feature_id = action.payload.feature_id
    const type = action.payload.type
    eval(featureStateType(state, feature_id, type)).api_messages[0].message = action.payload.message
    eval(featureStateType(state, feature_id, type)).api_messages[0].message_type = action.payload.message_type
  },

  clearMessages(state: any, action: PayloadAction<any>) {
    // console.log('clearMessages', action.payload)
    const feature_id = action.payload.feature_id
    const type = action.payload.type
    eval(featureStateType(state, feature_id, type)).api_messages[0].message = ''
    eval(featureStateType(state, feature_id, type)).api_messages[0].message_type = ''
  },
}
