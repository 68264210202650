// // React
import { useEffect, useState } from "react";
// import { useMemo, useState } from 'react';
import { Link } from "react-router-dom";
import { MdContentCopy, MdVisibility, MdOutlineFilterAlt, MdSort, MdReorder, MdUpload, } from "react-icons/md";

// Logic
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import HandleExport from "../../hooks/handleExport";
import { HandleSearch, HandleFilters, HandleSortGroupButtons, } from "./ListLogic";
import { handleSingleSortValue } from "../../hooks/handleSortData";

// Modal
import useModal from "../../hooks/useModal";
// import { FilterModal } from './FilterModal';

// Components
import GenericButton from "../buttons/GenericButton";
import DropDownButton from "../buttons/DropDownButton";
import Spinner from "../loaders/spinner/SpinnerFullScreen";

// Views
// import CalendarView from './calendar/Calendar'
import GridView from "./grid/GridView";
import TableView from "./table/TableView";

// Fields
import SearchField from "../fields/SearchField";
import FilterFields from "../fields/filterFields/FilterFields";
import { FilterModal } from "./FilterModal";
import { features_actions } from "../../state/slices/FeatureSlice";

export default function ListView(props: any) {
  // console.log('ListView - props:', props)
  const dispatch = useAppDispatch();
  const uiState = useAppSelector((state) => state.ui);
  const list_state = useAppSelector((state) => state.features[props.feature_state_id].list);
  // const list_data = feature_state.list.data
  // const list_state = feature_state.list
  const feature_state = props.feature_state;
  const feature_definition = props.feature_state.definition.data;
  const list_config = feature_definition.list_config;
  const list_data = list_state.data;
  // const list_config =_props.moduleDefinitions.list_config
  const actions = props.actions;
  const feature_id = props.feature_state.feature_id;
  // const feature_state list.api_status props.moduleState.list
  // const moduleFields = props.moduleState.module.fields

  const [showViewOptions, setShowViewOptions] = useState(false);
  const [showSortByOptions, setShowSortByOptions] = useState(false);
  const [showGroupByOptions, setShowGroupByOptions] = useState(false);
  const { show: showFilterModal, toggle: toggleFilterModal } = useModal();

  // useEffect(() => {
  //   handleSortData(dispatch, props.actions, list_data, list_config.sort_default.field, list_config.sort_default.order,feature_state.feature_id)
  // },[])

  useEffect(() => {
    // Set default sort options.
    // dispatch(actions.setListSortOrder({
    //   sortField: list_config.sort_default.field,
    //   sortOrder: list_config.sort_default.order,
    //   feature_id: feature_state.feature_id
    // }))
    // dispatch(actions.setDefaultListOptions({
    //   list_config: list_config,
    //   feature_id: feature_state.feature_id
    // }))
    if (list_config.sort_default) {
      handleSingleSortValue(dispatch, actions, list_data, list_config.sort_default.field, list_config.sort_default.order, feature_id);
    }
    if (uiState.appMode === "mobile") {
      if (list_config.grid_view_allowed)
        dispatch(props.actions.setListView({ view: "grid", feature_id: feature_id, })
        );
    }
  }, []);

  const filtered_data = HandleFilters(
    HandleSearch(list_data, list_state.search_input),
    list_state
  );

  // const sortedData = handleSortData(dispatch, props.actions, filtered_data, list_config.sort_default.field, list_config.sort_default.order,feature_state.feature_id)
  // // console.log('props.moduleDefinitions', props.moduleDefinitions)
  // console.log('props.actions', props.actions)
  const viewOptions = list_config.view_options.map((option: any) => (
    <a
      onClick={() => {
        dispatch(props.actions.setListView({ view: option.name, feature_id: feature_id, }));
        setShowViewOptions(false);
      }}
    >
      {option.label}
    </a>
  ));

  // console.log('filterFields - moduleFields:', moduleFields, 'props:', props)
  const filterFields = props.fields_config
    .filter((item: any) => item.table_show_column === true)
    .sort((a: any, b: any) => a.table_column_order - b.table_column_order)
    .map((item: any) => (
      <div key={item.field.field_name} style={{ marginTop: "10px" }}>
        <label style={{ fontWeight: "600", fontSize: "15px" }}>
          {item.field.translation.en}
        </label>
        <FilterFields
          {...props}
          field_type={item.field.field_type.html_field_type}
          field_name={item.field.field_name}
          item={item}
          list_data={list_data}
        />
      </div>
    ));

  const sortByFields = (
    <>
      {props.fields_config
        .filter((item: any) => item.table_show_column === true)
        // .sort((a, b) => a - b)
        .map((column: any) => (
          <HandleSortGroupButtons
            key={column.field.field_name}
            dispatch={dispatch}
            actions={props.actions}
            list_data={list_data}
            list_state={list_state}
            setShowSortByOptions={setShowSortByOptions}
            field_name={column.field.field_name}
            sort_name={"By " + column.field.translation.en}
            mode="sort"
            feature_id={props.feature_state.feature_id}
          />
        ))}
    </>
  );

  const groupByFields = (
    <>
      {props.fields_config
        .filter((item: any) => item.table_show_column === true)
        .map((column: any) => (
          <HandleSortGroupButtons
            key={column.field.field_name}
            dispatch={dispatch}
            actions={props.actions}
            list_data={list_data}
            list_state={list_state}
            setShowSortByOptions={setShowGroupByOptions}
            field_name={column.field.field_name}
            sort_name={"By " + column.field.translation.en}
            mode="group"
            feature_id={feature_state.feature_id}
          />
        ))}
      {feature_state.list.group_choices.length >= 1 && (
        <button
          style={{ backgroundColor: "orange", textAlign: "center", }}
          onClick={() => {
            setShowGroupByOptions(false);
            // dispatch(actions.listClearGroup())
          }}
        >
          Clear Groups
        </button>
      )}
    </>
  );

  return (
    <>
      <div style={{ textAlign: "center" }}>
        {/* Search */}
        <SearchField
          {...props}
          feature_id={feature_state.feature_id}
          list_data={list_data}
          list_state={list_state}
        />

        {/* Views */}
        {list_config.view_allow === true &&
          list_config.view_options.length >= 2 &&
          uiState.appMode !== "mobile" && (
            <DropDownButton
              showDropDownOptions={showViewOptions}
              setShowDropDownOptions={setShowViewOptions}
              // className={'standardButton'}
              contents={viewOptions}
              icon={MdVisibility}
              name="View"
              loading={feature_state.list.api_status !== "success"}
            ></DropDownButton>
          )}
        {/* Filter */}
        {list_config.filters_allow === true && (
          <GenericButton
            // className={'tableControlButton'}
            name="Filters"
            onClick={toggleFilterModal}
            icon={MdOutlineFilterAlt}
            iconSize={"20px"}
            disabled={feature_state.list.api_status !== "success"}
            responsiveButtonText
          />
        )}

        {/* Sort By */}
        {list_config.sort_allow === true && (
          <DropDownButton
            showDropDownOptions={showSortByOptions}
            setShowDropDownOptions={setShowSortByOptions}
            // className={'standardButton'}
            contents={sortByFields}
            icon={MdSort}
            name="Sort"
            loading={feature_state.list.api_status !== "success"}
          ></DropDownButton>
        )}

        {/* Group By */}
        {list_config.group_allow === true && (
          <DropDownButton
            showDropDownOptions={showGroupByOptions}
            setShowDropDownOptions={setShowGroupByOptions}
            // className={'standardButton'}
            contents={groupByFields}
            icon={MdReorder}
            iconSize={"20px"}
            name="Group"
            loading={feature_state.list.api_status !== "success"}
          ></DropDownButton>
        )}

        {/* Export Button */}

        {list_config.export_allow === true && (
          <GenericButton
            name="Export"
            onClick={() => HandleExport(filtered_data,
              props.module_definition.name + ' ' + props.feature_definition.name_translation.en)}
            icon={MdUpload}
            iconSize={"20px"}
            disabled={feature_state.list.api_status !== "success"}
            className={`${"hideOnMobile"} ${"tableControlButton"}`}
          />
        )}
      </div>
      {list_state.api_status === "loading" ? (
        <Spinner />
      ) : list_data ? (
        list_data.length === 0 ? (
          <p style={{ textAlign: "center" }}>No data found </p>
        ) : (
          <>
            <div style={{ textAlign: "center", padding: "6px" }}>
              <span>
                Showing {filtered_data.length} out of {list_data.length} items
              </span>
            </div>

            {feature_state.list.view_choice === "grid" ? (
              <GridView
                {...props}
                list_data={list_data}
                filtered_data={filtered_data}
                list_config={list_config}
              />
            ) : (
              <TableView
                {...props}
                list_data={list_data}
                filtered_data={filtered_data}
                list_config={list_config}
              />
            )}
          </>
        )
      ) : (
        <p>Error, loading data failed</p>
      )}

      <FilterModal
        show={showFilterModal}
        hide={toggleFilterModal}
        contents={filterFields}
        dispatch={dispatch}
        actions={features_actions}
        feature_id={feature_state.feature_id}
      ></FilterModal>
    </>
  );
}
