import { useEffect, useState } from 'react'
import { EditableOptionAddButton, getFieldAttributes, handleOnChange } from './InputFieldLogic'
import BasicGetAPI from '../../../api/BasicGetAPI'
import AutoCompleteStyle from './AutoComplete.module.css'
import ModuleModal from '../../views/ModuleModal'
import useModal from '../../../hooks/useModal'

export default function AutocompleteField(props: any) {
  const field_config = props.field_config
  const field_name = field_config.field.field_name
  // console.log('AutocompleteField props', field_name, props)
  const field_values = props.field_values
  const [autocompleteOptions, setAutocompleteOptions] = useState([])
  // console.log('autocompleteOptions',autocompleteOptions)
  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState<any>([]);
  const [isShow, setIsShow] = useState(false);
  const [error, setError] = useState(false);
  const { show: showModuleModal, toggle: toggleModuleModal } = useModal();

  // Input value holds exactly what the user has typed.
  const [inputValue, setInputValue] = useState('')
  // console.log('inputValue', inputValue)

  // Display value shows name from key value pair
  const [displayValue, setDisplayValue] = useState('');
  // console.log('displayValue',displayValue)

  // field value holds the id from the key value pair
  const setFieldValue = (newValue: string) => {
    // console.log('setFieldValue newValue', newValue)
    // props.setFieldValues(handleOnChange(field_config, field_values, newValue, true))
    props.setFieldValues(handleOnChange({
      field_config,
      field_values,
      field_value: newValue,
      touched: true
    }))
  }
  // console.log('field_name:', field_name, 'id:', fieldValue, 'name:', displayValue, 'loaded:', selectOptions.initialDataLoaded)

  //Update required is casued when a item has been added via the module modal
  const [updateRequired, setUpdateRequired] = useState(true)

  // console.log('updateRequired', updateRequired)

  useEffect(() => {
    // console.log('useEffect updateRequired', updateRequired)
    if (updateRequired) {
      BasicGetAPI(field_config.options_api_endpoint)
        .then((response: any) => {
          if (response.APIResult === 'success') {
            // console.log('AutocompleteField', response.APIData)
            setAutocompleteOptions(response.APIData)
          }
        })
      setUpdateRequired(false)
    }
  }, [updateRequired])

  // Set display values for edit forms
  useEffect(() => {
    // if (props.feature_state.item.view_action === 'edit') {
    const fieldValue = getFieldAttributes(field_name, field_values, 'field_value') || ''
    const key_value_pair: any = autocompleteOptions.find((item: any) => item.id == fieldValue)
    if (key_value_pair) {
      setDisplayValue(key_value_pair.name)
    }
    // }
  }, [autocompleteOptions])

  // 1. Capture user's input 
  const AutocompleteOnChange = (e: any) => {
    const input = e.currentTarget.value;
    // console.log('e.target.value', field_name, e.target.value)
    // console.log('e.currentTarget.value', e.currentTarget.value)

    // console.log('selectOptions', selectOptions)

    // Auto complete to match any part of the word
    // const newFilteredSuggestions = suggestions.filter(
    //   suggestion =>
    //     suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
    // );

    // Auto complete to extract first letter in order from start of the word
    const newFilteredSuggestions =
      autocompleteOptions.filter((option: any) => {
        const match = option.name.substr(0, input.length);
        return match && match.toLowerCase() === input.toLowerCase();
      })

    setActive(0);
    setFiltered(newFilteredSuggestions);
    // console.log('newFilteredSuggestions', newFilteredSuggestions)
    setIsShow(true);
    setInputValue(input)
    setFieldValue(input)
    setDisplayValue(input)
  };


  // 2. Handle a new value being added in a module modal 
  const NewValueAdded = (newValue: any, item_id: any) => {
    console.log('newValue', field_name, newValue, 'item_id:', item_id)
    setError(false)
    setActive(0);
    setIsShow(false);
    setInputValue(newValue)
    setFieldValue(item_id)
    setDisplayValue(newValue)
  };

  const actions = (id: any, name: any) => {
    // console.log('actions props', props)
    // console.log('actions: id,name', id, name)
    setError(false)
    setActive(0);
    setIsShow(false);
    setFieldValue(id)
    setDisplayValue(name)
    // setInput(name);
    // if (props.filtermode === true) {
    //   props.handleSetFilterValues(props.fieldName, props.fieldType, id, name)
    // } else {
    //   if (props.multipleSelect) {
    //     // console.log('multipleSelect mode', props.fieldName, id)
    //     props.handleMultiSelectAdd(props.fieldName, id)
    //   } else {
    //     props.handleChange(props.fieldName, id)
    //   }
    // }
  }

  const deleteMultiSelectFieldValue = (e: any, value: any) => {
    e.preventDefault();
    // console.log('deleteMultiSelectFieldValue', props.fieldName, value)
    props.handleMultiSelectDelete(props.fieldName, value)
  }

  const onClick = (id: any, name: any) => {
    setFiltered([]);
    actions(id, name)
  };

  const onKeyDown = (e: any) => {
    // console.log('onKeyDown', filtered, active)
    if (e.keyCode === 13) { // enter key
      // console.log('enter', filtered, active)
      if (filtered.length > 0) {
        actions(filtered[active].id, filtered[active].name)
      }
    }
    else if (e.keyCode === 38) { // up arrow
      // console.log('up arrow', filtered, active)
      return (active === 0) ? null : setActive(active - 1);
    }
    else if (e.keyCode === 40) { // down arrow
      // console.log('down arrow', filtered, active)
      return (active + 1 === filtered.length) ? null : setActive(active + 1);
    }
    else if (e.keyCode === 9) { // tab
      // console.log('tab', isShow, filtered, active)
      if (error) {
        return
      } else {
        if (isShow) {
          if (filtered.length > 0) {
            actions(filtered[active].id, filtered[active].name)
          }
        }
      }
    }
  };


  const renderAutocomplete = () => {
    if (isShow && inputValue) {
      if (filtered.length) {
        return (
          <ul className={AutoCompleteStyle.autocompleteItems}>
            {filtered.map((suggestion: any, index: any) => {
              // let className;
              let style;
              // console.log(index, active)
              if (index === active) {
                // console.log(index, " is active")
                // className = 'AutoCompleteStyle.active';
                style = { backgroundColor: '#a2d4c4' }
              }
              return (
                <li
                  // className={className}
                  style={style}
                  key={suggestion.id}
                  onClick={e => onClick(suggestion.id, suggestion.name)}>
                  {suggestion.name}
                </li>
              );
            })}
          </ul>
        );
      } else {
        return (
          <div className={AutoCompleteStyle.noResults}>
            <span className='errorMessage'>No results found</span>
            {field_config.options_editable === true &&
              <button
                onClick={(e) => { e.preventDefault(); toggleModuleModal() }}
                style={{ padding: "0.2rem 0.4rem", marginTop: "0px", marginBottom: "1px" }}>
                Add new </button>
            }


          </div>
        );
      }
    }
  }

  const clearInputField = () => {
    // console.log('clearInputField props', props)
    if (!props.multipleSelect) { props.handleChange(props.fieldName, '') }
    setFieldValue('')
    setDisplayValue('')
  }

  return (<>

    <div
      className={AutoCompleteStyle.autocomplete}
    // style={{ width: field.options.editable ? '90%' : '100%' }}
    >
      <input
        className={AutoCompleteStyle.autocompleteInputField}
        // style={{ width: field_config.options_editable ? '85%' : '100%' }}
        // style={{ width: '97%' }}
        key={field_name}
        type="text"
        id={field_name}
        value={displayValue}
        // onChange={e => setFieldValues(handleChange(field_name, e.target.value, field_values))}
        // onBlur={e => onBlur(field_name, e.target.value)}
        onChange={AutocompleteOnChange}
        onKeyDown={onKeyDown}
        autoComplete="off"
        // readOnly={field.readonly ? field.readonly : false}
        disabled={field_config.disabled ? field_config.disabled : false}
        // hidden={field_config.hidden ? field_config.hidden : false}
        hidden={props.hide_field}
      />
      {/* {field_config.options_editable === true && EditableOptionAddButton(field_config)} */}

      <div className={AutoCompleteStyle.optionsContainer} >
        {renderAutocomplete()}
      </div>
    </div>
    {/* {console.log('field_config.options_frontend_edit_link', field_config.options_frontend_edit_link)} */}
    <ModuleModal
      showModuleModal={showModuleModal}
      hideModalModal={toggleModuleModal}
      input_value={inputValue}
      feature_view={'item:add'}
      options_frontend_edit_link={field_config.options_frontend_edit_link}
      setUpdateRequired={setUpdateRequired}
      NewValueAdded={NewValueAdded}

    // contents={filterFields}
    // dispatch={dispatch}
    // actions={features_actions}
    // feature_id={feature_state.feature_id}
    ></ModuleModal>
  </>)
}