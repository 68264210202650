import UserAuthView from '../../../components/views/UserAuthView'
import { displayMessage } from '../../../components/messages/UserMessages'
import { Link } from 'react-router-dom'
import { passwordCheck } from './AuthLogic'

export default function SignUp() {

  const header = (props: any) => {
    // console.log('header props', props)
    if (props.api_status === 'success') {
      return (
        <div style={{ textAlign: 'center' }}>
          <p>Thank your for signing up.</p>
          <p>To continue you must verify your email address.</p>
          <p>Please check your emails for a verification link.</p></div>
      )
    } else {
      return (<>
        {displayMessage([{ message_type: 'warning', message: "This application is currently in a private Alpha release." }])}
        <p
        // style={{ textAlign: 'center' }}
        >If you have not been invited to join this release, you can still sign up for an account, but it will only become active when this release is made publicly available. </p>
      </>)
    }
  }

  const footer = (props: any) => {
    if (props.api_status !== 'success') {
      return (<>
        <p
          style={{ textAlign: 'center' }}
          className="linkText"  >
          Already have an account?
          <Link to="/user/signin"> Sign In</Link>
        </p>
      </>)
    } else {
      return
    }
  }

  const preSubmitActions = (field_values: any) => {
    // console.log('passwordCheck', field_values)
    return passwordCheck(field_values)
  }


  return (<>
    <UserAuthView
      feature_id={1}
      centerScreen
      header={header}
      // hideFormAfterError
      hideFormAfterSuccessfulSubmitted
      footer={footer}

      preSubmitActions={preSubmitActions}
    // postSubmitActions={postSubmitActions}
    />
  </>)
}