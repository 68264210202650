import { apiInitialState, apiReducersInArray } from '../reducers/APIReducer'

export const itemInitialState = ({ default_view_action, show_modal, form_message, form_message_type }: any) => {

  // console.log('itemInitialState', default_view_action, show_modal, form_message, form_message_type)
  return {
    item_id: 0,
    ...apiInitialState(),
    show_modal: show_modal ? show_modal : false,
    view_action: default_view_action,
    next_action: '',
    form_state: {
      // status: '',
      // field_values: [],
      message: form_message ? form_message : '',
      message_type: form_message_type ? form_message_type : '',
      button_action: '',
    }
    // field_status: [{
    //   field_name: '',
    //   touched: false,
    //   has_error: false,
    //   error_messages: [],
    // }],
  }
}