import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./hooks/useRedux";

// Nav
import { routerSignedIn, routerSignedOut } from "./layout/Routes";
import { RouterProvider } from "react-router-dom";

// Redux slices
import { setAppMode, setShowSideBarMenu } from "./state/slices/UiSlice";
import { userActions, userSettingsActions } from "./state/slices/UserSlice";
import {
  userModulesActions,
  allModulesActions,
} from "./state/slices/ModulesSlice";

import useScreenSize from "./hooks/useScreenSize";
import { GeneralAPIs } from "./api/GeneralAPIs";

export default function App() {
  const dispatch = useAppDispatch();
  const isSignedIn = useAppSelector((state) => state.user.isSignedIn);

  // 1. Calculate user screen size
  const screenSize = useScreenSize();
  useEffect(() => {
    if (screenSize.width >= 600) {
      dispatch(setShowSideBarMenu());
      dispatch(setAppMode("desktop"));
    } else {
      dispatch(setAppMode("mobile"));
    }
  }, [screenSize.width]);

  // 2. Get user details from local storage
  const access_token = localStorage.getItem("access_token");
  const refresh_token = localStorage.getItem("refresh_token");
  useEffect(() => {
    // console.log('App - useEffect on initial load only')
    if (access_token !== null) {
      dispatch(
        userActions.signIn({
          access_token: access_token,
          refresh_token: refresh_token,
        })
      );
    }

    // get user modules spec
    dispatch(
      GeneralAPIs({
        method: "get",
        end_point: "system/modules/user",
        actions: userModulesActions,
      })
    );
  }, []);

  // 3. When authenticated get list of system modules and user settings
  useEffect(() => {
    // console.log('App - isSignedIn', isSignedIn)
    if (isSignedIn) {
      const APIEndPoints = [
        {
          api_route: "system/modules/list",
          actions: allModulesActions,
        },
        {
          api_route: "user/settings",
          actions: userSettingsActions,
        },
      ];
      APIEndPoints.map((item: any) =>
        dispatch(
          GeneralAPIs({
            method: "get",
            end_point: item.api_route,
            actions: item.actions,
          })
        )
      );
    }
  }, [isSignedIn]);

  return (
    // <RouterProvider router={!isSignedIn ? routerSignedOut : routerSignedIn} />
    <RouterProvider router={routerSignedIn} />
  );
}
