// Field values is used to display data in lists or on the screen
// View fields closely follows this but is used when displaying a form in view / read only mode. Input fields is used when in add/edit mode.

export const checkboxValue = (value: any) => {
  return value === true ? "Yes" : "No";
};

export const dateValue = (value: any, userSettings: any) => {
  // console.log('dateValue- value', value)
  // console.log('userSettings', userSettings)
  // console.log('userSettings.data.localisation_date_format', userSettings.data.localisation_date_format)
  // return value
  if (value === null) {
    return "";
  } else if (userSettings.data.localisation_date_format === "DD/MM/YYYY") {
    // console.log('DD/MM/YYYY', value, new Date(value).toLocaleDateString("en-GB"))
    return new Date(value).toLocaleDateString("en-GB");
  } else if (userSettings.data.localisation_date_format === "MMM DD YYYY") {
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(new Date(value));
  } else {
    return new Date(value).toISOString().slice(0, 10);
  }
};

export const keyValuePairValue = (data_item: any, def: any) => {
  const field_name = def.field.field_name;
  const static_value = data_item[field_name];
  const key_value_pair_value = data_item[def.key_value_pair];
  // console.log('field_name:',field_name,'static_value:', static_value,'key_value_pair:',def.key_value_pair, 'key_value_pair_value:', key_value_pair_value)
  if (def.options_type === "static_list") {
    return data_item[field_name];
  } else {
    // console.log('data_item', data_item, def, def.key_value_pair)
    const value = data_item[def.key_value_pair];
    // console.log('value', value)
    if (value === null || value === undefined) {
      return "";
    } else {
      // console.log('value', value, value.name)
      return value.name;
    }
  }
};

export const timeFieldValue = (value: any, userSettings: any) => {
  // console.log('time value - before', value)
  if (userSettings.data.localisation_time_format === "AM/PM") {
    const format = (H: number, M: number) => {
      return `${(H % 12 < 10 ? "0" : "") + (H % 12)}:${
        (M < 10 ? "0" : "") + M
      } ${H < 12 ? "AM" : "PM"}`;
    };
    // console.log('time value- after', format(value.slice(0, 2), value.slice(3, 5)))
    return format(value.slice(0, 2), value.slice(3, 5));
  } else {
    // console.log('timeFieldValue - value:',value)
    if (value === "00:00:00") {
      return "";
    } else {
      return value;
    }
  }
};
