import { Link, useParams } from "react-router-dom";
import UserAuthView from "../../../components/views/UserAuthView";

export default function EmailVerification() {
  const { token } = useParams();
  console.log('token', token)

  const header = (props: any) => {
    if (props.api_status === 'success') {
      return (
        <div style={{ textAlign: 'center' }}>
          <p>Thank you for verifing your email address.</p>
          <p >To continue please <Link to='/user/signin' >
            <button>Sign In</button>
          </Link>
          </p>
        </div>
      )
    } else if (props.api_status === 'error') {
      return (
        <div style={{ textAlign: 'center' }}>
          <p className="linkText">Please <Link to="/user/signin" >sign in</Link> and request a new email verification link. </p>
        </div>
      )

    } else if (props.api_status === 'loading') {
      return (
        <p
          style={{ textAlign: 'center' }}
        >Please wait while we validate your email address... </p>
      )
    } else {
      return (
        <p
          style={{ textAlign: 'center' }}
        >An error has occured, please contact customer support. </p>
      )
    }
  }

  return (
    <UserAuthView
      feature_id={5}
      data_to_send={{ token: token }}
      instantly_submit_data={true}
      centerScreen
      header={header}
    />
  )
}