import { useState } from "react"
import { MdArrowDropUp, MdArrowDropDown } from 'react-icons/md';
import LinkItem from './LinkItem';
import { useAppDispatch, useAppSelector, } from '../../hooks/useRedux'
import { toggleSideBarSectionDropDown } from '../../state/slices/UiSlice';

const Sidebar_Group_Item = (props: any) => {
  // console.log('Sidebar_Group_Item - props:', props)
  const dispatch = useAppDispatch()
  const uiState = useAppSelector(state => state.ui)
  const modules = useAppSelector(state => state.allModules.data)
  const features = modules
    .filter((item: any) => props.item.id === item.id)
    .map((item: any) => { return item.feature })

  const sideBarGroupArray = uiState.sideBarHeading
  const sideBarGroup = sideBarGroupArray.find((item: any) => item.heading === props.item.name)

  const expanded = () => {
    if (sideBarGroup) {
      if (sideBarGroup) {
        return sideBarGroup.expanded
      } else return false
    }
  }
  const toggle = () => {
    dispatch(toggleSideBarSectionDropDown({ heading: props.item.name, expanded: !expanded() }))
  }

  return (
    <div className='sideBarHeading'
      onClick={() =>
        toggle()
      }
      title={props.item.name}
    >
      {expanded() ?
        <MdArrowDropUp />
        :
        <MdArrowDropDown />
      }
      <span
        className='sideBarText'
      >
        {uiState.sideBarExpand ?
          props.item.name
          :
          props.item.acronym
        }
      </span>


      {expanded() &&
        <div onClick={(e) => e.stopPropagation()}>
          {features.length > 0 && features[0]
            // Sort is done in the backend
            .filter((item: any) => item.sidebar_order !== null)
            // .sort((a: any, b: any) => a.sidebar_order - b.sidebar_order)
            .map((item: any) =>

              <LinkItem
                key={item.name}
                item={item}
                sideBarOpen={props.sideBarOpen}
                moduleLink={props.item.frontend_link}
              />

            )}
          {/* {props.item.children.map(((item: any) =>
            <LinkItem
              key={item.name}
              item={item}
              sideBarOpen={props.sideBarOpen}
            />

          ))} */}
        </div>
      }
    </div>
  )
}
export default Sidebar_Group_Item