import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { GeneralAPIs } from "../../api/GeneralAPIs";

import BaseContainer from "../containers/BaseContainer";
import ItemView from "../item/ItemView";
import Spinner from "../loaders/spinner/SpinnerFullScreen";

import { userAuthScreensActions } from "../../state/slices/UserSlice";
import { displayMessage } from "../messages/UserMessages";

export default function UserAuthView(props: any) {
  // console.log('Element 1. UserAuthView - props:', props)
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      GeneralAPIs({
        method: "get",
        end_point: "system/feature/definition/" + props.feature_id,
        actions: userAuthScreensActions,
        feature_id: props.feature_id,
        type: "definition",
      })
    );
  }, []);

  const feature_state = useAppSelector(
    (state) => state.userAuthScreens[props.feature_id]
  );
  // console.log('feature_state',feature_state)

  const getName = () => {
    // console.log("getName - feature_state", feature_state);
    if (
      feature_state.item.api_status === "loading" &&
      props.hideItemViewDuringLoading
    )
      return "";
    else if (feature_state.definition.api_status === "success")
      return feature_state.definition.data.singular_translation.en;
  };

  return (
    <BaseContainer
      heading={getName()}
      // Issue with messages - need to merge definitions and item together feature_state.definition.api_messages ||
      showBackButton={props.showBackButton}
      modalClose={props.modalClose}
      messages={feature_state.item.api_messages}
      centerScreen={props.centerScreen ? true : false}
      width={props.width ? props.width : "400px"}
    >
      {feature_state.definition.api_status === "error" &&
        displayMessage([
          {
            message: "An error has occured, please contact customer support",
            message_type: "error",
          },
        ])}
      {feature_state.definition.api_status === "loading" && Spinner()}
      {feature_state.definition.api_status === "success" && (
        <>
          <ItemView
            {...props}
            feature_state={feature_state}
            actions={userAuthScreensActions}
          />
        </>
      )}
    </BaseContainer>
  );
}
