import UserAuthView from "../../../components/views/UserAuthView";

export default function DisableMFA(props: any) {
  const header = (props: any) => {
    if (props.api_status === "success") {
      return (
        <div style={{ textAlign: "center" }}>
          <p>MFA has been disabled.</p>
        </div>
      );
    } else {
      return (
        <p style={{ textAlign: "center" }}>
          Please enter your password to disable MFA.{" "}
        </p>
      );
    }
  };

  return (
    <UserAuthView
      feature_id={64}
      // showBackButton
      modalClose={props.modalClose}
      centerScreen
      header={header}
    />
  );
}
