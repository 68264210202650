// Input fields is used when displaying a form in add/edit mode. View fields is used when in view / read only mode.

import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import { label, errorMessage, fieldActive, getFieldAttributes, handleOnChange, handleOnBlur, mandatory } from './InputFieldLogic'

import AutoCompleteField from './AutoCompleteField'
import ImageField from './FileFields/ImageField'
import MultiSelectField from './SelectFields/MultiSelectField'
import SelectField from './SelectFields/SelectField'

export default function InputField(props: any) {
  // console.log('Element 4. InputField:', props)
  const dispatch = useAppDispatch()
  const user_settings = useAppSelector((state) => state.userSettings)
  const appMode = useAppSelector((state) => state.ui.appMode)


  const setFieldValues = props.setFieldValues
  const field_config = props.field_config
  const field_name = field_config.field.field_name
  const field_type = field_config.field.field_type.html_field_type
  const field_translation = field_config.field.translation.en

  const field_values = props.field_values
  const field_value = getFieldAttributes(field_name, field_values, 'field_value')

  const l_d_c = field_config.item_layout_desktop_column
  const l_d_p = field_config.item_layout_desktop_position
  const l_m_c = field_config.item_layout_mobile_column
  const l_m_p = field_config.item_layout_mobile_position


  const onChange = (field_value: any) => {
    console.log('onchange', field_value)
    const touched = getFieldAttributes(field_name, field_values, 'touched')
    setFieldValues(handleOnChange({
      field_config,
      field_values,
      field_value,
      touched
    }))
  }

  // Current Issue with onblur is it require the form buttons to be pressed twice
  const onBlur = async (field_value: any) => {
    setFieldValues(handleOnBlur(field_config, field_values, field_value))
  }

  const autocomplete = (props: any) => {
    return (<>
      <div className='inputField'
      // style={{ flexDirection: 'row' }}
      >
        {AutoCompleteField(props)}

        {label(field_config, field_values)}
      </div>
      {errorMessage(field_name, field_values)}
    </>)
  }


  const checkboxField = (
    <label
      className='labelCheckBox'>
      {field_translation} {mandatory(field_config, field_values) && <span style={{ color: 'red' }}>*</span>}
      <input
        key={field_name}
        // style={{ height: '20px' }}
        className='inputCheckBox'
        type={field_type}
        // id={field_name}
        // value={field_value || ''}
        // onChange={e => setFieldValues(handleChange(field_name, e.target.value, field_values))}
        checked={field_value}
        onChange={e => onChange(field_value ? false : true)}
      // onBlur={e => onBlur(field_name, e.target.value)}
      // autoComplete={field.autoComplete}
      // readOnly={field.readonly ? field.readonly : false}
      />
      {/* <span className='labeltextCheckBox'>{field.label.EN} </span> */}
      {errorMessage(field_name, field_values)}
    </label>

  )

  const dateTimeField = (
    <div className='inputField'>
      <input
        key={field_name}
        className={fieldActive(field_name, field_values, 'dateTimeActive')}
        type={field_type}
        // id={field_name}
        value={getFieldAttributes(field_name, field_values, 'field_value') || ''}
        // onChange={e => setFieldValues(handleChange(field_name, e.target.value, field_values))}
        onChange={e => onChange(e.target.value)}
        // onBlur={e => onBlur(field_name, e.target.value)}
        // autoComplete={field.autoComplete}
        disabled={field_config.disabled ? field_config.disabled : false}
        // hidden={field_config.hidden ? field_config.hidden : false}
        hidden={props.hide_field}
      // readOnly={field.readonly ? field.readonly : false}
      />
      {label(field_config, field_values)}
      {errorMessage(field_name, field_values)}
    </div>
  )

  const image = (props: any) => {
    return (<>
      <div className='inputField'>
        {ImageField(props)}
      </div>
      {errorMessage(field_name, field_values)}
    </>)
  }

  const unfreezeInput = (e: any) => {
    e.target.value = e.target.value;
  }

  const text = (
    <div className='inputField'>
      <input
        onClick={e => unfreezeInput(e)}
        key={field_name}
        // className='inputField'
        type={field_type}
        id={field_name}
        value={getFieldAttributes(field_name, field_values, 'field_value') || ''}
        onChange={e => onChange(e.target.value)}
        // onBlur={e => onBlur(e.target.value)}
        autoComplete={field_config.auto_complete}
        disabled={field_config.disabled ? field_config.disabled : false}
        // hidden={field_config.hidden ? field_config.hidden : false}
        hidden={props.hide_field}
      />
      {!field_config.hidden && label(field_config, field_values)}
      {errorMessage(field_name, field_values)}
    </div>
  )


  const textarea = (
    <div className='inputField'>
      <textarea
        key={field_name}
        // className='inputField'
        style={{ resize: 'none' }}
        // id={field_name}
        value={getFieldAttributes(field_name, field_values, 'field_value') || ''}
        onChange={e => onChange(e.target.value)}
        // onBlur={e => onBlur(e.target.value)}
        // rows={field.rows ? field.rows : 3}
        disabled={field_config.disabled ? field_config.disabled : false}
        // hidden={field_config.hidden ? field_config.hidden : false}
        hidden={props.hide_field}
        rows={3}
      // readOnly={field.readonly ? field.readonly : false}
      />
      {label(field_config, field_values)}
      {errorMessage(field_name, field_values)}
    </div>
  )

  const fieldSelector = (field: any) => {
    switch (field_type) {

      case 'autocomplete':
        return autocomplete(props)

      case 'checkbox':
        return checkboxField

      case 'date':
        return dateTimeField

      case 'image':
        return image(props)

      case 'multi_select':
        return MultiSelectField(props)

      case 'select':
        return SelectField(props)

      case 'textarea':
        return textarea

      // case 'time':
      //   return dateTimeField

      default:
        return text
    }
  }

  // const layout = props.field.itemDetails && props.field.itemDetails.layout ? props.field.itemDetails.layout : ''
  const mobileStyle = {
    gridColumn:
      // layout === '' ? '' :
      l_m_c === 'full' ? "1/5" :
        l_m_c === 'half' ?
          (l_m_p === 'left' ? "1/3" :
            l_m_p === 'right' ? "3/5" : "1/5") :
          l_m_c === 'quarter' ?
            (l_m_p === '1' ? "1/2" :
              l_m_p === '2' ? "2/3" :
                l_m_p === '3' ? "3/4" :
                  l_m_p === '4' ? "4/5" : "1/5") :
            "1/5",
    // width: layout === '' ? '' : l_m_c === 'quarter' ? '110px' : '100%'
  };

  const desktopStyle = {
    gridColumn:
      // layout === '' ? '' :
      l_d_c === 'full' ? "1/5" :
        l_d_c === 'half' ?
          (l_d_p === 'left' ? "1/3" :
            l_d_p === 'right' ? "3/5" : "1/5") :
          l_d_c === 'quarter' ?
            (l_d_p === '1' ? "1/2" :
              l_d_p === '2' ? "2/3" :
                l_d_p === '3' ? "3/4" :
                  l_d_p === '4' ? "4/5" : "1/5") :
            "1/5",
    // width: layout === '' ? '' : l_d_c === 'quarter' ? '110px' : '100%'
  };


  return (<>
    <div
      key={field_config.field.id}
      style={appMode === "mobile" ? mobileStyle : desktopStyle}
    >
      {fieldSelector(field_config)}
    </div>
  </>
  )
}