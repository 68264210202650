import axios from "axios";
import { useNavigate } from "react-router-dom";
import { userActions } from "../state/slices/UserSlice";

export const access_token = localStorage.getItem("access_token")
export const APIServer = process.env.REACT_APP_API_SERVER;
export const APITimeout = 10000

export const APIOptionsPublic = ({
  timeout: APITimeout,
})
// console.log('access_token', access_token)

export const APIOptionsSecure = ({
  timeout: APITimeout,
  headers: {
    Authorization: `Bearer ${access_token}`,
    // 'Content-Type': 'application/json',
    // transformResponse: []
  }
  ///////////// for use with cookies  {withCredentials: true}
})

// If mulitple error message, make a list?
// const listofErrors = (errorGroup: any) => {
//   return Object.values(errorGroup).map((error: any) => (error
//   ))
// }

export const APICatchErrorHandler = (error: any) => {
  // console.log('APICatchErrorHandler', error)
  const APIResult = 'error'
  const APIStatusCode = error ? error.status ? error.status : 'No status code' : 'No response'
  const APIData = ''
  if (error.message) {
    if (error.message.includes('timeout')) {
      const APIMessage = {
        type: 'error',
        message: "System Error: Unable to reach Server, please try again later or contact customer support"
      }
      return { APIResult, APIStatusCode, APIMessage, APIData }
    }
  }
  if (error.response) {
    if (error.response.data) {
      console.log('APICatchErrorHandler', error.response.data.error)
      const APIMessage = {
        type: 'error',
        message: error.response.data.error
      }
      return { APIResult, APIStatusCode, APIMessage, APIData }
    }
  }
  // Catch all for unkown erros
  const APIMessage = {
    type: 'error',
    message: "System Error: Unknown error has occured, please contact customer support"
  }
  return { APIResult, APIStatusCode, APIMessage, APIData }
}

export const APIInterceptor = () => {
  // const navigate = useNavigate()
  const jwtAxios = axios.create({});  //Create new instance of Axios
  // const [count, setCount] = useState(0)
  let count: number = 0

  jwtAxios.interceptors.response.use(

    (response) => {
      return response;
    },

    async (error) => {
      const originalRequest = error.config;
      // console.log('API Logic :', error)
      // console.log('API Logic :', error.message)
      if (error.message === "Network Error") {
        // console.log('API Logic Network Error -')
        return Promise.reject({ message: 'Network Error' });
      }
      if (error.message.includes("timeout") && error.message.includes("exceeded")) {
        // console.log('API Logic - timeout exceeded')
        return Promise.reject({ message: 'API timeout exceeded' });
      }
      if (error.response.status === 401 || error.response.status === 403) {
        const refresh_token = localStorage.getItem("refresh_token")
        console.log('APIInterceptor- 401 has occured', error)

        count = (count + 1)
        // console.log('api try count', count)
        if (count <= 4) {
          if (refresh_token) {
            try {
              const response = await axios.post(
                APIServer + 'user/refreshtoken',
                { timeout: APITimeout, refresh: refresh_token }
              )
              // console.log('APIInterceptor- success new access token', response)
              const newAccessToken = response.data.access_token
              localStorage.setItem("access_token", newAccessToken)
              originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
              // console.log('originalRequest', originalRequest)
              return jwtAxios(originalRequest);
            }
            // }
            //////////////Cookies
            // axios.defaults.withCredentials = true;

            // try {
            //   const response = await axios.post(
            //     API_SERVER + 'token/refresh/'
            //   );
            //   if (response["status"] == 200) {
            //     return jwtAxios(originalRequest);
            //   }
            // } 
            catch (refreshError) {
              console.log('APIInterceptor- signed out')
              localStorage.removeItem("access_token")
              localStorage.removeItem("refresh_token")
              console.log('handle_logout')
              // dispatch(userActions.signOut(''))
              // navigate("/")
              return Promise.reject(refreshError);
            }
          }
        } else {
          console.log('Exceeded number of allowed refresh attempts')
          return Promise.reject({ message: 'Unable to connect to server', message_type: 'error' });
        }
      }
      return Promise.reject(error);
    }
  );
  return jwtAxios;
};


function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
// export default API