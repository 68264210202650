import BaseContainer from "../../components/containers/BaseContainer";
import ToggleSwitch from '../../components/switches/ToggleSwitch';
import { useEffect, useState } from "react";
import settingsStyle from './Settings.module.css';
import { GeneralAPIs } from "../../api/GeneralAPIs";
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { userSettingsActions } from '../../state/slices/UserSlice'

export default function UserSettings() {
  const dispatch = useAppDispatch()
  const userSettings = useAppSelector((state) => state.userSettings)
  const settingsState = userSettings.data

  const changeUserSetting = async (fieldName: string, fieldValue: any) => {

    dispatch(userSettingsActions.updateUserSetting({ fieldName, fieldValue }))
    console.log('fieldName: ', fieldName, 'fieldValue: ', fieldValue)

    dispatch(GeneralAPIs({
      method: 'patch',
      end_point: 'user/settings',
      actions: userSettingsActions,
      data_to_send: { fieldName, fieldValue }
    }))
  }
  const settingOptions = [
    // {
    //   sectionHeading: 'Notifications',
    //   sectionDescription: '',
    //   sectionContents: [{
    //     label: 'Task Notifications',
    //     fieldName: 'task_notifications',
    //     text: '',
    //     optionType: 'toggle',
    //     details: '',
    //   }, {
    //     label: 'Event Notifications',
    //     fieldName: 'event_notifications',
    //     text: '',
    //     optionType: 'toggle',
    //     details: '',
    //   }]
    // },
    {
      sectionHeading: 'Privacy',
      sectionDescription: 'We take your privacy seriously, choose how we share your data.',
      sectionContents: [{
        label: 'Marketing Consent',
        fieldName: 'marketing_consent',
        text: 'Allow us to contact you regarding new features and products',
        optionType: 'toggle',
        details: '',
      }, {
        label: 'Third Parties Marketing Consent',
        fieldName: 'thirdparties_consent',
        text: 'Allow selected third parties to contact you about products you might be interested in.',
        optionType: 'toggle',
        // details: 'Details',
      }]
    },
    // {
    //   sectionHeading: 'Appearance',
    //   sectionDescription: '',
    //   sectionContents: [{
    //     label: 'Dark Mode',
    //     fieldName: 'darkmode',
    //     text: '',
    //     optionType: 'toggle',
    //     details: '',
    //   }]
    // },
    {
      sectionHeading: 'Localisation',
      // sectionDescription: 'Configure how your apps works.',
      sectionContents: [{
        //   label: 'Language',
        //   fieldName: 'localisation_language',
        //   text: '',
        //   optionType: 'select',
        //   // optionSource: [{ id: 1, name: 'English' }],
        //   selectOptions: ['English'],
        //   details: '',
        // }, {
        //   label: 'Currency',
        //   fieldName: 'localisation_currency',
        //   text: '',
        //   optionType: 'select',
        //   // optionSource: [{ id: 1, name: 'GBP' }],
        //   selectOptions: ['GBP', 'EUR', 'USD'],
        //   details: '',
        // }, {
        //   label: 'County/region',
        //   fieldName: 'localisation_country',
        //   text: '',
        //   optionType: 'select',
        //   // optionSource: [{ id: 1, name: 'UK' }],
        //   selectOptions: ['UK'],
        //   details: '',
        // }, {
        //   label: 'Units of Measure',
        //   fieldName: 'localisation_uom_weight',
        //   text: '',
        //   optionType: 'select',
        //   selectOptions: ['Metric', 'Imperial'],
        //   details: '',
        // }, {
        label: 'Distance',
        fieldName: 'localisation_uom_distance',
        text: '',
        optionType: 'select',
        selectOptions: ['NM', 'KM'],
        details: '',
      }, {
        label: 'Depth',
        fieldName: 'localisation_uom_depth',
        text: '',
        optionType: 'select',
        selectOptions: ['Meters', 'Feet'],
        details: '',
      }, {
        label: 'Length',
        fieldName: 'localisation_uom_length',
        text: '',
        optionType: 'select',
        selectOptions: ['Meters', 'Feet'],
        details: '',
      }, {
        label: 'Speed',
        fieldName: 'localisation_uom_speed',
        text: '',
        optionType: 'select',
        selectOptions: ['Knots', 'MPH', 'KPH'],
        details: '',
      }, {
        label: 'Temperature',
        fieldName: 'localisation_uom_temperature',
        text: '',
        optionType: 'select',
        selectOptions: ['Centigrade', 'Fahrenheit'],
        details: '',
      }, {
        //   label: 'TimeZone',
        //   fieldName: 'localisation_tz',
        //   text: '',
        //   optionType: 'select',
        //   // optionSource: [{ id: 1, name: 'London' }],
        //   selectOptions: ['London'],
        //   details: '',
        // }, {
        label: 'Date Format',
        fieldName: 'localisation_date_format',
        text: '',
        optionType: 'select',
        selectOptions: ['DD/MM/YYYY', 'YYYY-MM-DD', 'MMM DD YYYY'],
        details: '',
      }, {
        label: 'Time Format',
        fieldName: 'localisation_time_format',
        text: '',
        optionType: 'select',
        selectOptions: ['24 Hours', 'AM/PM'],
        details: '',
      }
      ]
    },
  ]

  const toggleSwitch = (fieldName: string) => {
    console.log('fieldName', fieldName, settingsState[fieldName], !settingsState[fieldName])
    return (
      <ToggleSwitch
        fieldName={fieldName}
        isChecked={settingsState[fieldName]}
        handleToggle={() => changeUserSetting(fieldName, !settingsState[fieldName])}
      />
    )
  }

  const selectField = (sectionContent: any) => {
    return <select
      className='userSettingsSelectField'
      id={sectionContent.fieldName}
      value={settingsState[sectionContent.fieldName]}
      onChange={e => changeUserSetting(sectionContent.fieldName, e.target.value)}
    >
      {sectionContent.selectOptions.map((option: any) => (
        <option
          key={option}
          value={option}
          className='userSettingsSelectOption'
        >
          {option}
        </option>
      ))
      }
    </select>

  }

  return (
    <BaseContainer
      // type='responsive'
      heading='User Settings'
      messages={userSettings.api_messages}
      showBackButton>


      {settingOptions.map((sectionItem) =>

        <div
          key={sectionItem.sectionHeading}
          className={settingsStyle.sectionHeading}>
          <h3> {sectionItem.sectionHeading}</h3>
          <p>{sectionItem.sectionDescription}</p>

          <div className={settingsStyle.sectionContents}>
            {sectionItem.sectionContents.map((sectionContent) =>
              <div
                key={sectionContent.fieldName}
                className={settingsStyle.sectionContentsItem}>
                <div className={settingsStyle.sectionContentsLeft}>
                  {sectionContent.label}
                </div>
                <div className={settingsStyle.sectionContentsRight}>
                  <div className={settingsStyle.sectionContentsType}>
                    {sectionContent.optionType == 'toggle' && toggleSwitch(sectionContent.fieldName)}
                    {sectionContent.optionType == 'select' && selectField(sectionContent)}
                  </div>
                  <div className={settingsStyle.sectionContentsDetailsOption} >
                    <p style={{ fontWeight: '600', margin: '0px' }}> {sectionContent.text}</p>
                    <p> {sectionContent.details}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>)}

    </BaseContainer>
  )
}
