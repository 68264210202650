import { useState } from 'react';
import tablestyle from '../lists/table/Table.module.css';
import { HandleOutsideClick } from '../../hooks/handleOutSideClick';
import GenericButton from './GenericButton';

const DropDownButton = (props: any) => {

  // const [isDropDownButtonActive, setIsDropDownButtonActive] = useState(false);
  const delay = (ms: any) => new Promise(resolve => setTimeout(resolve, ms));

  const handleClick = (event: any) => {
    props.setShowDropDownOptions((current: any) => !current);
  };

  return (
    <div
      className={tablestyle.dropdownButton}
    >
      <GenericButton
        name={props.name}
        // className={'tableControlButton'}
        onClick={handleClick}
        icon={props.icon}
        iconSize={'20px'}
        disabled={props.disabled || props.loading}
        responsiveButtonText
      />

      <div
        className={`${tablestyle.dropdowncontent} ${props.showDropDownOptions ? tablestyle.show : ''}`}
      // style={{ textAlign: 'left' }}
      >
        <HandleOutsideClick
          show={props.showDropDownOptions}
          onClickOutside={async (event: any) => { await delay(10); props.setShowDropDownOptions(false) }}
          contents={props.contents} />
      </div>
    </div>
  );
};
export default DropDownButton;

