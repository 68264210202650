//React
import { useEffect, useState } from "react"
import { MdCheck, MdClose } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
//Components
import tablestyle from '../../../components/lists/table/Table.module.css';
import ToggleSwitch from '../../../components/switches/ToggleSwitch';
import HorizontalRadioSwitch from '../../../components/switches/HorizontalRadioSwitch';
import { dateValue } from "../../../components/fields/viewFields/FieldValues";
import { sectionHeading } from "./Dashboard"
// Logic
import BasicGetAPI from "../../../api/BasicGetAPI"
import { changeUserOptions } from './Dashboard'

import dashboardstyle from './Dashboard.module.css';
import { GeneralAPIs } from "../../../api/GeneralAPIs";
import { features_actions } from "../../../state/slices/FeatureSlice";
import Spinner from "../../../components/loaders/spinner/SpinnerFullScreen";

const initialQualData = [
  {
    "qualification_name": 'Loading....',
    "days_at_sea_reduced": 1,
    "days_at_sea": 1,
  }]

const tidalOptions = [
  {
    id: 1,
    name: 'All',
    default: true,
  }, {
    id: 2,
    name: 'Tidal',
  },
]

const APIEndPoints = [
  {
    name: 'requirements',
    APISource: 'personallog/qualification/requirements',
    setState: 'setQualData',
    loaded: 'setQualDataLoaded'
  }, {
    name: 'requirementcerts',
    APISource: 'personallog/qualification/requirementcerts',
    setState: 'setCertData',
    loaded: 'setCertDataLoaded'
  }, {
    name: 'contactqualifications',
    APISource: 'personallog/qualification/summarybyuser',
    setState: 'setContactQualData',
    loaded: 'setContactQualDataLoaded'
  },
]

const PLNextQualification = (props: any) => {
  // console.log('userData', props.userData)
  const dispatch = useAppDispatch()
  const feature_id = 28
  const useroptionsState = useAppSelector((state) => state.features[feature_id])
   const appMode = useAppSelector((state) => state.ui.appMode)
  const user_options = useroptionsState.item.data ? useroptionsState.item.data : { next_qual_goal_id: 0, next_qual_goal_reduced: 0 }
  const [qualDataLoaded, setQualDataLoaded] = useState(false)
  const [certDataLoaded, setCertDataLoaded] = useState(false)
  const [contactQualDataLoaded, setContactQualDataLoaded] = useState(false)
  const [qualData, setQualData] = useState<any>(initialQualData)
  const [certData, setCertData] = useState<any>()
  const [contactQualData, setContactQualData] = useState<any>()
  const [tidalOption, setTidalOption] = useState('All')
  const userSettings = useAppSelector((state) => state.userSettings)
  const currentGoal = user_options.next_qual_goal_id
  const reducedReqs = (currentGoal === 2 || currentGoal === 3) ? false : user_options.next_qual_goal_reduced
  // console.log('currentGoal', currentGoal)
  // console.log('qualDataLoaded', qualDataLoaded, qualData)

  useEffect(() => {
    // console.log('E1.Step 3 - UseEffect ')
    // console.log('step 3 - Get feature definition', feature)
    dispatch(GeneralAPIs({
      method: 'get',
      end_point: 'system/feature/definition/' + feature_id,
      actions: features_actions,
      feature_id: feature_id,
      type: 'definition'
    }))
    dispatch(GeneralAPIs({
      method: 'get',
      end_point: 'personallog/dashboard/useroptions',
      actions: features_actions,
      feature_id: feature_id,
      type: 'item'
    }))
  }, [])

  // Qualifcation codes
  // 2 = RYA Day Skipper
  // 3 = RYA Coastal Skipper
  // 4 = RYA Yachtmaster Coastal Skipper
  // 5 = RYA Yachtmaster Offshore
  // 9 = RYA Advanced Powerboating

  const getData = async (APISource: string, setState: string, loaded: string) => {
    // if (props.dataItem.id) {
    await BasicGetAPI(APISource)
      .then(response => {
        if (response.APIResult === 'success') {
          // console.log('response.generalAPI', response.APIData)
          eval(setState)(response.APIData)
          eval(loaded)(true)
          // console.log('qualData', qualData)
        } else {
          console.log('error', response.APIMessage.message)
          // setMessageType('error')
          // setMessage(response.APIMessage.message)
        }
      })
      .catch(error => {
        console.log('error.generalAPI', error.APIData)
        // setLoading(false)
        // setMessageType('error')
        // setMessage(error.response.data.error ? error.response.data.error : 'An unknown error has occured')
      })
    // }
  }
  useEffect(() => {
    APIEndPoints.map((endpoint: any) =>
      getData(endpoint.APISource, endpoint.setState, endpoint.loaded))
  }, [])

  const findIndex = () => {
    const index = qualData.findIndex((item: any) => item['qualification_id'] === currentGoal)
    return index
  }

  const sumUp = (array: any, attribute: string) => {
    // console.log('array', array)
    return array.reduce((accumulator: any, object: any) => {
      return accumulator + parseInt(object[attribute]);
    }, 0)
  }

  const tidalFilter = (data: any) => {
    if (tidalOption === 'Tidal') {
      return data.tidal === true
    } else if (tidalOption === 'Not Tidal') {
      return data.tidal === false
    } else return data
  }


  const calcReqDaysAtSea = () => {
    const calc = reducedReqs ? qualData[findIndex()].days_at_sea_reduced : qualData[findIndex()].days_at_sea
    return tidalOption === "Tidal" ? Math.round(calc / 2) : calc
  }

  const calcProgressDaysAtSea = () => {
    return sumUp(props.userData.filter((fields: any) => tidalFilter(fields)), 'total_no_of_days')
  }

  const calcReqDaysAsSkipper = () => {
    const calc = reducedReqs ? qualData[findIndex()].days_as_skipper_reduced : qualData[findIndex()].days_as_skipper
    return tidalOption === "Tidal" ? Math.round(calc / 2) : calc
  }

  const calcProgressDaysAsSkipper = () => {
    return sumUp(props.userData.filter((e: any) => e.pl_role_id === 10).filter((fields: any) => tidalFilter(fields)), 'total_no_of_days')
  }

  const calcReqMiles = () => {
    const calc = reducedReqs ? qualData[findIndex()].miles_reduced : qualData[findIndex()].miles
    return tidalOption === "Tidal" ? Math.round(calc / 2) : calc
  }

  const calcProgressMiles = () => {
    return sumUp(props.userData.filter((fields: any) => tidalFilter(fields)), 'total_distance_sailed')
  }

  const calReqNightHours = () => {
    const calc = qualData[findIndex()].night_hours
    return tidalOption === "Tidal" ? Math.round(calc / 2) : calc
  }

  const calcProgressNightHours = () => {
    return sumUp(props.userData.filter((fields: any) => tidalFilter(fields)), 'total_night_hours')
  }


  const calcReqPassagesSkipper = () => {
    const calc = reducedReqs ? qualData[findIndex()].qualifying_passages_skipper_reduced : qualData[findIndex()].qualifying_passages_skipper
    return tidalOption === "Tidal" ? Math.round(calc / 2) : calc
  }

  const calcProgressPassagesSkipper = () => {
    return sumUp(props.userData
      .filter((e: any) => e.pl_role_id === 10)
      .filter((e: any) => e.total_distance_sailed > 60)
      .filter((fields: any) => tidalFilter(fields)), 'total_passages')
  }

  const calcReqPassagesTotal = () => {
    const calc = reducedReqs ? qualData[findIndex()].qualifying_passages_total_reduced : qualData[findIndex()].qualifying_passages_total
    return tidalOption === "Tidal" ? Math.round(calc / 2) : calc
  }

  const calcProgressPassagesTotal = () => {
    return sumUp(props.userData
      // .filter((e: any) => e.pl_role_id === 10)
      .filter((e: any) => e.total_distance_sailed > 60)
      .filter((fields: any) => tidalFilter(fields)), 'total_passages')
  }

  const completed = (<span style={{ color: "var(--green)" }}>Completed
    {/* <MdCheck style={{
      color: "var(--green)", fontSize: "25px"
    }} /> */}
  </span>)

  const checkdate = (date: any) => {
    const calc = new Date(date).getTime() - new Date().getTime()
    // console.log('new Date()', new Date().getTime())
    // console.log('expiry date', new Date(date).getTime())
    // console.log('date calc', new Date(date).getTime() - new Date().getTime())
    return calc >= 0 ? true : false

  }

  return (<>
    {/* {sectionHeading('Next Qualification')} */}
    <h3>Next Qualification</h3>
    {
      useroptionsState.item.api_status === 'loading' ? <Spinner /> :
        <>
          <div className={appMode === 'mobile' ? '' : dashboardstyle.nextqualgridcontainer }>
            <div className={appMode === 'mobile' ? '' :  dashboardstyle.nextqualgriditem}>
              Current Goal:
            </div>
            <div className={appMode === 'mobile' ? '' : dashboardstyle.nextqualgriditem}>
              <select
                className={dashboardstyle.selectInputField}
                name="current_goal"
                id="current_goal"
                value={currentGoal}
                onChange={e => changeUserOptions(dispatch, 'next_qual_goal_id', (parseInt(e.target.value)))}
              >
                {qualDataLoaded && qualData.map((option: any) => (
                  <option
                    key={option["qualification_id"]}
                    value={option["qualification_id"]}
                  >
                    {option["qualification_name"]}
                  </option>
                ))}
              </select>
            </div>
            </div>
            <div className={dashboardstyle.nextqualgridcontainer}>

            {qualDataLoaded && (currentGoal === 4 || currentGoal === 5 || currentGoal === 9) &&
              <>
                <div className={dashboardstyle.nextqualgriditem}>
                  <div>Qualify for reduced requirements?</div>
                  <div>
                    <a className='clickableText'
                      href={qualData[findIndex()].link}>Check the RYA website to see if you qualify  </a>
                  </div>
                </div>
                <div className={dashboardstyle.nextqualgriditem}>
                  <ToggleSwitch
                    fieldName={'fieldName'}
                    isChecked={reducedReqs}
                    handleToggle={() => changeUserOptions(dispatch, 'next_qual_goal_reduced', (!reducedReqs))}
                  />
                </div>
              </>
            }


            {(currentGoal === 4 || currentGoal === 5) &&
              <>
                <div className={dashboardstyle.nextqualgriditem} style={{ display: "flex", alignItems: "center" }}>
                  At least half the qualifying sea time should be gain in tidal waters
                </div>
                <div className={dashboardstyle.nextqualgriditem}>
                  <HorizontalRadioSwitch
                    switchValues={tidalOptions}
                    selected={tidalOption}
                    onChange={setTidalOption}
                  />
                </div>
              </>}
          </div >
          <div>
            {qualDataLoaded && props.userData &&
              <table className={tablestyle.table}>
                <thead>
                  <tr>
                    <th className={tablestyle.notClickableHeader}>

                    </th>
                    <th className={tablestyle.notClickableHeader}>
                      Required
                    </th>
                    <th className={tablestyle.notClickableHeader}>
                      Your Progress
                    </th>
                    <th className={tablestyle.notClickableHeader}>
                      Remaning
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Days at Sea
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {calcReqDaysAtSea()}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {calcProgressDaysAtSea()}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {(calcReqDaysAtSea() - calcProgressDaysAtSea()) <= 0 ? completed : (calcReqDaysAtSea() - calcProgressDaysAtSea())}
                    </td>
                  </tr>
                  {(currentGoal === 4 || currentGoal === 5 || currentGoal === 9) &&
                    <tr>
                      <td>
                        Days as Skipper
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {calcReqDaysAsSkipper()}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {calcProgressDaysAsSkipper()}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {(calcReqDaysAsSkipper() - calcProgressDaysAsSkipper()) <= 0 ? completed : (calcReqDaysAsSkipper() - calcProgressDaysAsSkipper())}
                      </td>
                    </tr>
                  }
                  <tr>
                    <td>
                      Miles
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {calcReqMiles()}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {calcProgressMiles()}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {(calcReqMiles() - calcProgressMiles()) <= 0 ? completed : (calcReqMiles() - calcProgressMiles())}
                    </td>
                  </tr>
                  {currentGoal !== 5 &&
                    <tr>
                      <td>
                        Night Hours
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {calReqNightHours()}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {calcProgressNightHours()}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {(calReqNightHours() - calcProgressNightHours()) <= 0 ? completed : (calReqNightHours() - calcProgressNightHours())}
                      </td>
                    </tr>
                  }
                  {currentGoal === 5 && <>
                    <tr>
                      <td>
                        Qualifing Passages as Skipper
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {calcReqPassagesSkipper()}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {calcProgressPassagesSkipper()}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {(calcReqPassagesSkipper() - calcProgressPassagesSkipper()) <= 0 ? completed : (calcReqPassagesSkipper() - calcProgressPassagesSkipper())}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Total Qualifing Passages
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {calcReqPassagesTotal()}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {calcProgressPassagesTotal()}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {(calcReqPassagesTotal() - calcProgressPassagesTotal()) <= 0 ? completed : (calcReqPassagesTotal() - calcProgressPassagesTotal())}
                      </td>
                    </tr>
                  </>}
                </tbody>
              </table>
            }
            <br></br>
            {
              certDataLoaded && (currentGoal === 4 || currentGoal === 5 || currentGoal === 9) && <>
                <table className={tablestyle.table}>
                  <thead>
                    <tr>
                      <th className={tablestyle.notClickableHeader}>
                        Certificates Required
                      </th>
                      <th className={tablestyle.notClickableHeader} style={{ textAlign: "center" }}>
                        Completed / Valid
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log('certData', certData)} */}
                    {certData
                      .filter((item1: any) => item1.pl_qualification_req_id === currentGoal)
                      .map((item1: any) => {
                        return (
                          <tr key={item1.id} style={{ height: "30px" }}>
                            <td>
                              {item1.name}
                              {/* {item1.pl_qualification_id} */}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {contactQualDataLoaded && contactQualData
                                .filter((item2: any) => item2.id === item1.pl_qualification_id)
                                .map((item2: any) => {
                                  return (
                                    <div key={item2.id}>
                                      {item2.id === item1.pl_qualification_id ? <>


                                        <div key={item2.id}>
                                          {item2.expiry_date ? (checkdate(item2.expiry_date) ? <>
                                            <MdCheck style={{
                                              color: "var(--green)",
                                              fontSize: "25px",
                                              verticalAlign: "middle",

                                            }} />
                                            <span style={{
                                              color: "var(--green)"
                                            }}> Until: {dateValue(item2.expiry_date, userSettings)} </span>
                                          </> :
                                            <span style={{
                                              color: "var(--redButton)"
                                            }}> Expired:{dateValue(item2.expiry_date, userSettings)}  </span>
                                          ) :
                                            <MdCheck style={{
                                              color: "var(--green)",
                                              fontSize: "25px",
                                              verticalAlign: "middle",

                                            }} />
                                          }
                                        </div>
                                      </>
                                        :
                                        'No Qual'

                                      }
                                    </div>
                                  )
                                }
                                )}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>

              </>
            }
            {
              qualDataLoaded && qualData.length >= 1 &&
              <div style={{ fontSize: "12px" }}><div>These are only a summary of some of the key requirements for each qualification.</div><div> <a className='clickableText' href={qualData[findIndex()].link}>Please check the RYA's website </a> for a full list of requirments before undertaking any course or assessment.</div> </div>
            }
          </div>
        </>}
  </>
  )
}
export default PLNextQualification;