import { useLocation, useNavigate } from "react-router-dom";
import UserAuthView from "../../../components/views/UserAuthView";
import { useEffect, useState } from "react";
import QRCode from "qrcode";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import { GeneralAPIs } from "../../../api/GeneralAPIs";
import {
  userActions,
  userAuthScreensActions,
} from "../../../state/slices/UserSlice";
import { MFASetup } from "../components/MFASetup";

export default function MFAGetAuthTokens() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const feature_id = 3;

  // console.log('location.state', location.state)
  if (!location.state) {
    navigate("/user/signin");
  } else {
    var signInData = location.state.data;
  }
  // console.log('MFA signInData', signInData)

  const MFA_state = useAppSelector((state) => state.userAuthScreens[3]);
  const [mfaDeclined, setMfaDeclined] = useState(false);

  const header = (props: any) => {
    if (signInData.next_action === "setup_mfa") {
      return MFASetup({
        otpauth_url: signInData.otpauth_url,
        secret: signInData.secret,
      });
    } else if (signInData.next_action === "check_mfa_token") {
      return (
        <p>Please enter the one time passcode from your authentictor app.</p>
      );
    }
  };

  const footer = (props: any) => {
    // console.log('footer props', props)
    if (signInData.next_action === "setup_mfa") {
      return (
        <>
          <div style={{ marginTop: "10px", fontSize: "14px" }}>
            <p
              className="clickableText"
              onClick={() =>
                dispatch(
                  GeneralAPIs({
                    method: "post",
                    end_point: "user/mfa",
                    actions: userAuthScreensActions,
                    data_to_send: {
                      id: signInData.id,
                      token: signInData.token,
                      decline: true,
                    },
                    feature_id: feature_id,
                    type: "item",
                  })
                )
              }
            >
              Decline MFA
            </p>
          </div>
        </>
      );
    }
  };

  const postSubmitActions = (props: any) => {
    if (props.item_state.next_action === "user_authenticated") {
      console.log("postSubmitActions", props);
      dispatch(
        userActions.signIn({
          access_token: MFA_state.item.data.access_token,
          refresh_token: MFA_state.item.data.refresh_token,
        })
      );
      localStorage.setItem("access_token", MFA_state.item.data.access_token);
      localStorage.setItem("refresh_token", MFA_state.item.data.refresh_token);
      dispatch(userAuthScreensActions.clearSignInMFAScreenState());
      navigate("/");
    }
    return;
  };

  if (location.state) {
    return (
      <UserAuthView
        feature_id={feature_id}
        data_to_send={{
          id: signInData.id,
          decline: mfaDeclined,
          token: signInData.token,
        }}
        instantly_submit_data={
          signInData.next_action === "skip_mfa" ? true : false
        }
        header={header}
        hideItemViewDuringLoading={
          signInData.next_action === "skip_mfa" ? true : false
        }
        hideFormAfterError={
          signInData.next_action === "skip_mfa" ? true : false
        }
        footer={footer}
        postSubmitActions={postSubmitActions}
      />
    );
  }
}
