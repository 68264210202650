import { Link, useNavigate } from "react-router-dom";
import UserAuthView from "../../../components/views/UserAuthView";
import { getFieldAttributes } from "../../../components/fields/inputFields/InputFieldLogic";
import { useAppSelector } from "../../../hooks/useRedux";
import { useEffect } from "react";

export default function SignIn() {
  const navigate = useNavigate()
  const isSignedIn = useAppSelector((state) => state.user.isSignedIn)

  useEffect(() => {
    if (isSignedIn) {
      navigate('/')
    }
  }, [isSignedIn])

  const header = (props: any) => {
    // console.log('header props', props)
    if (props.next_action === 'account_on_hold') {
      return (
        <div style={{ textAlign: 'center' }}>
          <p>Your account has not yet been activated. During the private beta launch all only invited users will be granted access.</p>
          <p> When the public beta launch starts, we will activate your account and notify you.
          </p>
        </div>
      )
    }
  }

  const footer = (props: any) => {
    if (props.next_action !== 'account_on_hold') {
      return (<>
        <p
          style={{ textAlign: 'center' }}
          className="linkText">
          <Link to="/user/password/forgot">Forgotten your password? </Link>
        </p>
        <p
          style={{ textAlign: 'center' }}
          className="linkText">
          Don't have an account? <Link to="/user/signup" >Sign Up</Link></p>

      </>)
    }
  }

  const postSubmitActions = (props: any) => {
    // console.log('props.item_state.data', props.item_state.data)
    if (props.item_state.next_action === 'email_verification_resend') {
      navigate("/user/emailverificationresend", { state: { email: getFieldAttributes('email', props.field_values, 'field_value') } })
    }

    if (props.item_state.next_action === 'agreed_to_tandcs') {
      navigate("/user/tandcs", {
        state: {
          email: getFieldAttributes('email', props.field_values, 'field_value'),
          temp_token: props.item_state.data.token
        }
      })
    }

    if (props.item_state.next_action === 'check_mfa_token' ||
      props.item_state.next_action === 'setup_mfa' ||
      props.item_state.next_action === 'skip_mfa') {
      navigate("/user/mfa", { state: { data: props.item_state.data } })
    }
  }


  return (
    <UserAuthView
      feature_id={2}
      centerScreen
      header={header}
      // hideFormAfterError
      hideFormAfterSuccessfulSubmitted
      footer={footer}

      // preSubmitActions={preSubmitActions}
      postSubmitActions={postSubmitActions}
    />
  )
}