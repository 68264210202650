import { configureStore } from '@reduxjs/toolkit'

import { userModulesReducer, allModulesReducer } from './slices/ModulesSlice'
import { featuresReducer } from './slices/FeatureSlice'
import {
  userAuthScreensReducer,
  userReducer,
  userAccountReducer,
  userSettingsReducer,
  userNotificationsReducer,
} from './slices/UserSlice'

import { uiReducer } from './slices/UiSlice'

// import { coreReducer } from './slices/CoreSlice'

const store = configureStore({
  reducer: {
    // systemFeatureList: systemFeatureListReducer,
    userModules: userModulesReducer,
    allModules: allModulesReducer,
    features: featuresReducer,

    // core: coreReducer,
    userAuthScreens: userAuthScreensReducer,
    user: userReducer,
    userAccount: userAccountReducer,
    // userProfile: userProfileSlice.reducer,
    userSettings: userSettingsReducer,
    userNotifications: userNotificationsReducer,

    ui: uiReducer,
  }
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch