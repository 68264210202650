import { APIInterceptor, APIOptionsPublic, APIOptionsSecure, APIServer } from './APILogic'

interface request {
  method: 'get' | 'post' | 'patch' | 'delete';
  end_point: string
  actions: any
  item_id?: number
  data_to_send?: any
  permissions?: 'public'
  feature_id?: number
  type?: 'definition' | 'item' | 'list' | 'selectOptions'
  header?: any
}

export const GeneralAPIs = ({ method, end_point, actions, item_id, data_to_send, permissions, feature_id, type,
  header
}: request) => {
  const APIEndPoint = (item_id ? end_point + '/' + item_id : end_point)
  const APIPermissions = permissions
  const AxiosInterceptor = APIInterceptor()
  // console.log("GeneralAPIs - method:", method,    "end_point:", end_point,    "actions:", actions,    "item_id:", item_id,    "data_to_send:", data_to_send,    "permissions:", permissions,    "feature_id:", feature_id,    "type:", type)

  return async (dispatch: any) => {
    dispatch(actions.setAPIStatus({
      status: 'loading',
      feature_id: feature_id,
      type: type
    }))

    const fetchModuleData = async () => {
      if (method === 'get') {
        const response = await AxiosInterceptor.get(
          APIServer + APIEndPoint,
          (APIPermissions === 'public' ? APIOptionsPublic : APIOptionsSecure)
        )
        return await response.data
      }
      if (method === 'post') {
        const response = await AxiosInterceptor.post(
          APIServer + APIEndPoint, data_to_send,
          (APIPermissions === 'public' ? APIOptionsPublic :
            header ? header : APIOptionsSecure),
        )
        return await response.data
      }
      if (method === 'patch') {
        const response = await AxiosInterceptor.patch(
          APIServer + APIEndPoint, data_to_send, APIOptionsSecure)
        return await response.data
      }
      if (method === 'delete') {
        const response = await AxiosInterceptor.delete(
          APIServer + APIEndPoint, APIOptionsSecure
        )
        return await response.data
      }
    }

    try {
      const data = await fetchModuleData()
      // console.log('GeneralAPIs - data:', data)
      // console.log('GeneralAPIs - data:', data.message)
      dispatch(actions.setData({
        data: data,
        next_action: data.next_action,
        feature_id: feature_id,
        type: type
      }))
      dispatch(actions.setMessages({
        message: data.message ? data.message : '',
        message_type: data.message_type ? data.message_type : '',
        feature_id: feature_id,
        type: type
      }))
      // console.log('GeneralAPIs - data:', data.next_action)
      dispatch(actions.setAPIStatus({
        status: 'success',
        feature_id: feature_id,
        type: type
      }))
    }
    catch (error: any) {
      // console.log('GeneralAPIs -error', error)
      dispatch(actions.setAPIStatus({
        status: 'error',
        feature_id: feature_id,
        type: type
      }))

      // Backend Errors
      if (error.response && error.response.data) {
        const data = error.response.data
        // console.log('GeneralAPIs - Backend error:', data)
        dispatch(actions.setMessages({
          message: data ?
            data.message ? data.message :
              data : 'Error: ' + error,
          message_type: data.message_type ? data.message_type : 'error',
          feature_id: feature_id,
          type: type
        }))
      }

      // API Logic / APIInterceptor errors
      else if (error.message) {
        console.log('GeneralAPIs - API Logic error:', error)
        dispatch(actions.setMessages({
          message: 'Error: Please contact customer support with ref:' + error.message,
          message_type: 'error',
          feature_id: feature_id,
          type: type
        }))

        // Unknown Errors
      } else {
        console.log('GeneralAPIs - Unknown error:', error)
        dispatch(actions.setMessages({
          message: 'Error: An unknown error has occured, please contact customer support.',
          message_type: 'error',
          feature_id: feature_id,
          type: type
        }))
      }
    }
  }
}
