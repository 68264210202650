import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import GenericButton from './GenericButton';
import { useAppDispatch } from '../../hooks/useRedux'

export default function BackButton(props: any) {
  // console.log('BackButton -props:', props)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleClick = () => {
    // Logic for feature - item modals
    if (props.feature_state && props.feature_state.item.show_modal === true) {
      // console.log('Close item modal')
      // dispatch(props.actions.clearMessages());
      dispatch(props.features_actions.closeItemModal(props.feature_state.feature_id));
      // Logic for inline field edits (select and autocomplete fields)
    } else if (props.hideModalModal) {
      // console.log('Close module modal')
      props.hideModalModal()
    } else {
      // console.log('Navigate back')
      navigate(-1)
    }
  }

  return (<>
    <GenericButton
      name='Back'
      // onClick={() => handleClick()}
      onClick={props.module_modal === true ? props.module_modal_hide : () => handleClick()}
      icon={MdArrowBack}
      className='responsiveButton'
      responsiveButtonText
    />
  </>)
}
