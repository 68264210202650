import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BasicGetAPI from "../../../api/BasicGetAPI";
import tablestyle from "../../../components/lists/table/Table.module.css";
import HorizontalRadioSwitch from "../../../components/switches/HorizontalRadioSwitch";
import dashboardstyle from "./Dashboard.module.css";
// const initialContactMiles = [
//   {
//     "name": 'Hidden as this is the users own record',
//     "total_distance_sailed": "0",
//   }, {
//     "name": 'Loading data....',
//     "total_distance_sailed": "0",
//   },]

const APIEndPoints = [
  {
    name: "Contacts",
    attribute: "Miles",
    APIEndPoint: "personallog/dashboard/favourites/contacts:miles",
    state: "contactsMiles",
    setState: "setContactsMiles",
  },
  {
    name: "Contacts",
    attribute: "Days",
    APIEndPoint: "personallog/dashboard/favourites/contacts:days",
    state: "contactsDays",
    setState: "setContactsDays",
  },
  {
    name: "Contacts",
    attribute: "Passages",
    APIEndPoint: "personallog/dashboard/favourites/contacts:passages",
    state: "contactsPassages",
    setState: "setContactsPassages",
  },
  {
    name: "Boats",
    attribute: "Miles",
    APIEndPoint: "personallog/dashboard/favourites/boats:miles",
    state: "boatsMiles",
    setState: "setBoatsMiles",
  },
  {
    name: "Boats",
    attribute: "Days",
    APIEndPoint: "personallog/dashboard/favourites/boats:days",
    state: "boatsDays",
    setState: "setBoatsDays",
  },
  {
    name: "Boats",
    attribute: "Passages",
    APIEndPoint: "personallog/dashboard/favourites/boats:passages",
    state: "boatsPassages",
    setState: "setBoatsPassages",
  },
  {
    name: "Locations",
    attribute: "",
    APIEndPoint: "personallog/dashboard/favourites/locations",
    state: "locations",
    setState: "setLocations",
  },
];

const optionTypes = [
  {
    id: 1,
    name: "Contacts",
    default: true,
  },
  {
    id: 2,
    name: "Boats",
  },
  {
    id: 3,
    name: "Locations",
  },
];

const attributeTypes = [
  {
    id: 1,
    name: "Miles",
    default: true,
  },
  {
    id: 2,
    name: "Days",
  },
  {
    id: 3,
    name: "Passages",
  },
];

const PLFavourites = (props: any) => {
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [contactsMiles, setContactsMiles] = useState<any>();
  const [contactsDays, setContactsDays] = useState<any>();
  const [contactsPassages, setContactsPassages] = useState<any>();
  const [boatsMiles, setBoatsMiles] = useState<any>();
  const [boatsDays, setBoatsDays] = useState<any>();
  const [boatsPassages, setBoatsPassages] = useState<any>();
  const [locations, setLocations] = useState<any>();
  const [numberofRows, setNumberofRows] = useState<any>(10);
  const [optionType, setOptionType] = useState("Contacts");
  const [attributeType, setAttributeType] = useState("Miles");
  const [dataSource, setDataSource] = useState("contactsMiles");

  const getData = async (APIEndPoint: string, setState: string) => {
    // if (props.dataItem.id) {
    await BasicGetAPI(APIEndPoint)
      .then((response) => {
        if (response.APIResult === "success") {
          // console.log('response.generalAPI', response.APIData)
          // { setState } (response.APIData)
          eval(setState)(response.APIData);
          // setContactMiles(response.APIData)
          if (setState === "setContactsMiles") {
            setDataLoaded(true);
          }
        } else {
          console.log("error", response.APIMessage.message);
          // setMessageType('error')
          // setMessage(response.APIMessage.message)
        }
      })
      .catch((error) => {
        console.log("error.generalAPI", error.APIData);
        // setLoading(false)
        // setMessageType('error')
        // setMessage(error.response.data.error ? error.response.data.error : 'An unknown error has occured')
      });
    // }
  };
  useEffect(() => {
    APIEndPoints.map((endpoint: any) =>
      getData(endpoint.APIEndPoint, endpoint.setState)
    );
    // setDataSource(contactMiles)
    // console.log('dataSource', dataSource)
  }, []);

  const handleOptionSwitchChange = (value: string) => {
    console.log("value", value);
    setOptionType(value);
    if (value === "Locations") {
      setDataSource("locations");
    } else {
      // console.log('optionType', value.toLowerCase() + attributeType)
      setDataSource(value.toLowerCase() + attributeType);
    }
  };

  const handleAttributeSwitchChange = (value: string) => {
    console.log("1 Attribute", optionType + value);
    setAttributeType(value);
    setDataSource(optionType.toLowerCase() + value);
    console.log("2 Attribute", optionType.toLowerCase() + value);
  };

  const tableSource = () => {
    if (dataSource === "contactsMiles") return contactsMiles;
    if (dataSource === "contactsDays") return contactsDays;
    if (dataSource === "contactsPassages") return contactsPassages;
    if (dataSource === "boatsMiles") return boatsMiles;
    if (dataSource === "boatsDays") return boatsDays;
    if (dataSource === "boatsPassages") return boatsPassages;
    if (dataSource === "locations") return locations;
  };

  return (
    <>
      <h3>Favourites</h3>
      <HorizontalRadioSwitch
        switchValues={optionTypes}
        selected={optionType}
        onChange={handleOptionSwitchChange}
      />
      {optionType !== "Locations" && (
        <>
          <span> by </span>
          <HorizontalRadioSwitch
            switchValues={attributeTypes}
            selected={attributeType}
            onChange={handleAttributeSwitchChange}
          />
        </>
      )}
      <br></br>
      Number of results:
      <select
        className={dashboardstyle.selectInputField}
        name="showrows"
        id="showrows"
        onChange={(e) => setNumberofRows(e.target.value)}
      >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="1000">All</option>
      </select>
      {dataLoaded && (
        <table className={tablestyle.table}>
          <thead>
            <tr>
              <th className={tablestyle.notClickableHeader}>Place</th>
              <th className={tablestyle.notClickableHeader}>Name</th>
              <th className={tablestyle.notClickableHeader}>
                {attributeType === "Miles" &&
                  (optionType !== "Locations" ? (
                    <span> Total distance sailed (NM)</span>
                  ) : (
                    <span>Visits</span>
                  ))}
                {attributeType === "Days" && <span> Total days</span>}
                {attributeType === "Passages" && <span> Total passages</span>}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableSource()
              .slice(0, parseInt(numberofRows))
              .map((item: any, index: number) => (
                <tr
                  key={item.name}
                  className={tablestyle.trhover}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    optionType === "Contacts" &&
                      navigate(
                        "/personallog/contacts/item:view?id=" +
                          item.id.toString()
                      );
                    optionType === "Boats" &&
                      navigate(
                        "/personallog/boats/item:view?id=" + item.id.toString()
                      );
                    optionType === "Locations" &&
                      navigate(
                        "/personallog/locations/item:view?id=" +
                          item.id.toString()
                      );
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td style={{ textAlign: "right" }}>
                    {optionType === "Locations" && item.total_visits}
                    {attributeType === "Miles" && item.total_distance_sailed}
                    {attributeType === "Days" && item.total_days}
                    {attributeType === "Passages" && item.total_passages}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};
export default PLFavourites;
