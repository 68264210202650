export default function GenericButton(props: any) {


  const icon = props.icon ? (
    <span className='buttonIcon'>
      <props.icon
        className={props.iconClassName}
        style={{
          verticalAlign: 'middle',
          fontSize: props.iconSize ? props.iconSize : '30px',
        }} />
    </span>
  ) : ''

  const text = (
    <span className={`
      ${props.buttonTextSize ? props.buttonTextSize : 'buttonText'}
      ${props.responsiveButtonText ? 'responsiveButtonText' : ''}
      ${props.iconPosition === 'Left' ? 'buttonTextLeft' : 'buttonTextRight'}
      `}
      style={{
        verticalAlign: 'middle'
      }}>
      {props.name}
    </span>
  )

  return (
    <button
      className={`${props.className} `}
      style={props.style}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <div className='buttonDiv'>
        {props.iconPosition != 'Left' &&
          <>
            {icon}
            {text}
          </>
        }
        {props.iconPosition === 'Left' &&
          <>
            {text}
            {icon}
          </>
        }
      </div>
    </button >
  )
}